import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import {
  buisnessObjectiveSchema,
  roleSchema,
  subCategorySchema,
  tagSchema,
} from "../../../schemas";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/tables/table-strapi";
// import { useDispatch } from "react-redux";
import {
  buisness_objective_columns,
  categories_columns,
  role_columns,
  sub_categories_columns,
  tag_columns,
} from "../../../components/tables/tableheader";
import { Dialog, Transition } from "@headlessui/react";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import MasterServices from "../../../ApiServices/MasterServices";
import SearcherMasterServices from "../../../ApiServicesSearcher/MasterServices";
import { toast } from "react-toast";
import Select from "react-select";
import { getLoginUserInfo } from "../../../redux/userInfo/actions";
import {
  getAllowedActions,
  checkPermissionsAndRedirect,
} from "../../../helper/commonHelper";

function List() {
  const pages = [
    { title: "Sub Category List", href: "/sub-category", module_id: 3 },
  ];
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageSizeState, setPageSizeState] = useState(50);
  const [pagination, setPagination] = useState([]);
  const [searchedValue, setSearchedValue] = useState();
  const [productOptions, setProductOptions] = useState(null);
  console.log(productOptions);
  const [products, setProducts] = useState(null);
  const [originalProductOptions, setOriginalProductOptions] = useState(null);
  console.log(originalProductOptions);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [drawerID, setDrawerID] = useState();
  const [list, setList] = useState([]);
  const [allowedActions, setAllowedActions] = useState([]);
  let [filterStatusValue,setFilterStatusValue] = useState(null)
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(
        window.location.pathname,
        permissions,
        navigate
      );
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);

  useEffect(() => {
    document.title = "Octopus | Sub Category";
    getAllData();
    getAllProducts();
  }, []);

  const initialValues = {
    id: "",
    name: "",
    is_hr_professional: false,
    top_products: "",
  };

  const [form, setForm] = useState(initialValues);

  const getAllData = async (page, pageSize, filters) => {
    setLoading(true);
    if (filters?.name) {
      setSearchedValue(filters?.name);
    }
    if (filters?.name?.toString().length == 1) {
      setSearchedValue("");
    }
    if (pageSize) {
      setPageSizeState(pageSize);
    }
    let payload = {
      page,
      pageSize: !pageSize ? pageSizeState : pageSize,
      filters: {
        name: filters?.name,
        publishedAt: filterStatusValue?.value
      },
    };
    console.log("filters", filters);
    await SearcherMasterServices.getAllSubCategories(payload)
      .then((res) => {
        const { data, status, meta } = res;

        if (Array.isArray(data) && data.length > 0) {
          // setList(data.filter((v) => !v.attributes.deleted));
          setList(data);
        }else if(Array.isArray(data) && data?.length == 0) {
          setList([])
        }
        if (meta.pagination) {
          setPagination(meta.pagination);
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.data?.message);

        setLoading(false);
      });
  };

  const addSubCategory = async (obj) => {
    setLoading(true);
    let body = {
      data: {
        name: obj.name,
        is_hr_professional: obj.is_hr_professional,
        top_products: obj.top_products,
      },
    };
    // console.log(body);
    await SearcherMasterServices.addSubCategory({ body })
      .then((res) => {
        const { data, status } = res;

        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.data?.message);

        setLoading(false);
      });
    // setLoading(false);
  };

  const updateSubCategory = async (obj) => {
    setLoading(true);
    let body = {
      data: {
        name: obj.name,
        is_hr_professional: obj.is_hr_professional,
        top_products: obj.top_products,
      },
    };
    await SearcherMasterServices.updateSubCategory({ id: drawerID, body })
      .then((res) => {
        const { data, status } = res;

        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.data?.message);

        setLoading(false);
      });
  };

  const deleteSubCategory = async (id) => {
    setLoading(true);
    let body = {
      data: {
        // deleted: true,
        publishedAt: !id?.attributes?.publishedAt ? new Date() : null,
      },
    };
    await SearcherMasterServices.deleteSubCategory({ id: id?.id, body })
      .then((res) => {
        const { data, status } = res;

        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.data?.message);

        setLoading(false);
      });
  };

  const getAllProducts = async (e) => {
    const payload = { searchKey: e || "" };
    await SearcherMasterServices.getAllProducts(payload)
      .then((res) => {
        const { data, status, meta } = res;
        console.log(data);
        if (Array.isArray(data) && data.length > 0) {
          setProductOptions(
            data.map((v) => ({ label: v.attributes.Name, value: v.id }))
          );
          setOriginalProductOptions(
            data.map((v) => ({ label: v.attributes.Name, value: v.id }))
          );
        }
      })
      .catch((err) => {
        toast.error(err.data?.message);
        console.log(err);
      });
  };

  const getAllProductsBySearch = async (e) => {
    const payload = { searchKey: e || "" };
    await SearcherMasterServices.getAllProductsBySearch(payload)
      .then((res) => {
        const { data, status, meta } = res;
        console.log(data);
        if (Array.isArray(data) && data.length > 0) {
          setProductOptions(
            data.map((v) => ({ label: v.attributes.Name, value: v.id }))
          );
        }
      })
      .catch((err) => {
        toast.error(err.data?.message);
        console.log(err);
      });
  };

  const handleDrawer = (type, id, obj) => {
    setProductOptions(originalProductOptions);
    if ((type === "edit", id)) {
      setDrawerID(id);
      setProducts(
        obj?.attributes?.top_products?.data?.map((v) => ({
          value: v?.id,
          label: v?.attributes?.Name,
        }))
      );
      const initialValues = {
        id: obj.id,
        name: obj.attributes.name,
        is_hr_professional: obj.attributes.is_hr_professional,
        top_products: obj?.attributes?.top_products?.data.map((v) => ({
          value: v?.id,
          label: v?.attributes?.Name,
        })),
      };
      console.log(initialValues);
      setForm(initialValues);
    } else {
      setDrawerID("");
      setForm(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };

  const onDeleteOpen = (row) => {
    setConfirmationModal({ id: row, status: true });
  };

  const onDelete = async (id) => {
    await deleteSubCategory(id);
    await getAllData();
    setConfirmationModal({ id: null, status: false });
  };

  const statusFilter = [
    {
      label:"Published",
      value:"published"
    },{
      label:"Unpublished",
      value:"unpublished"
    }
  ]

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: form,
      validationSchema: subCategorySchema,
      onSubmit: async (values, action) => {
        let body = {
          name: values.name,
          is_hr_professional: values.is_hr_professional,
          top_products: Array.isArray(values.top_products)
            ? values.top_products.map((v) => v.value)
            : [],
        };
        if (drawerID === undefined || drawerID === null || drawerID === "") {
          await addSubCategory(body);
        } else {
          await updateSubCategory(body);
        }
        // dispatch(addRole(body));
        // else dispatch(updateRole(role_id, body));
        action.resetForm();
        getAllData();
        if (modalOpenFlage === true) {
          getAllData();
          setmodalOpenFlage(false);
        }
        navigate("/sub-category");
      },
    });

useEffect(()=>{
      console.log("filterStatusValue",filterStatusValue)
  
      const fetchData = async()=>{
  
        let pageno =1;
        
        await getAllData(pageno,pageSizeState,filterStatusValue);
       
      }
  
      fetchData()
},[filterStatusValue])
const clearFormHandler = async()=>{
      setFilterStatusValue(null)
   
}
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDelete(confirmationModal.id)}
        data={confirmationModal}
      />
      <Breadcrumb pages={pages} />
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-xl font-semibold text-gray-900">
            Sub Category List
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the Sub Category.
          </p>
        </div>
        <div className="flex">
          {allowedActions.filter((data) => data.permission_id == 43)?.length >
            0 ? (
            <Link
              onClick={() => handleDrawer("add", "", {})}
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#fd6e1d] to-[#ef6122] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
            >
              Add Sub Category
            </Link>
          ) : null}
        </div>
      </div>
      <div className="mt-4 w-full shadow-sm rounded-md h-auto border-2 p-4">
        <div className=" w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Status
            </label>
            <div>
              <Select
                classNamePrefix="select"
                // defaultValue={statusFilter[0]}
                placeholder="Select"
                value={filterStatusValue}
                onBlur={handleBlur}
                name="userStatus"
                className="basic-single sm:text-sm"
                options={statusFilter}
                onChange={(selectedOption) => {
                setFilterStatusValue(selectedOption)
              }}
              />
            </div>
          </div>


        </div>
        <div className=" pt-4 ">
          <button
            onClick={clearFormHandler}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#fd6e1d] to-[#ef6122] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
          >
            Clear
          </button>
        </div>
      </div>
      {/* 
      {loading ? (
        <FallingLinesLoader />
      ) : ( */}
      <Table
        columns={sub_categories_columns({
          onDeleteOpen,
          handleDrawer,
          allowedActions,
        })}
        data={list}
        pagination={pagination}
        goToThePage={getAllData}
        loading={loading}
        setPageSizeState={setPageSizeState}
        setSearchedValue={setSearchedValue}
      />
      {/* )} */}

      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();

                          handleSubmit(e);
                        }}
                        className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                      >
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-gradient-to-b from-[#872341] to-[#DB7D0B] py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {drawerID ? "Update" : "Add"} Sub Category
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md bg-gradient-to-b from-[#872341] to-[#DB7D0B] text-white hover:text-white"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-1 flex-col justify-between">
                            <div className="divide-y divide-gray-200 p-4 sm:p-3">
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="name"
                                    className="block text-sm font-medium text-gray-900 mt-2 mb-1"
                                  >
                                    Enter Sub Category Name
                                  </label>
                                </div>
                                <div className="mt-2 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Enter Sub Category Name"
                                    name="name"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.name && touched.name ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.name}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-col gap-y-3 w-full  p-4 sm:p-3">
                              <div>
                                <label className="block text-sm font-medium leading-6 text-gray-900 whitespace-nowrap">
                                  Select upto 4 products
                                </label>
                                <div className="">
                                  <Select
                                    isMulti={true}
                                    className="basic-single sm:text-sm w-full"
                                    classNamePrefix="select"
                                    defaultValue="Select"
                                    placeholder="Select"
                                    name="top_products"
                                    onInputChange={(e) => {
                                      if (e != "") {
                                        getAllProductsBySearch(e);
                                      }
                                    }}
                                    options={productOptions}
                                    value={products}
                                    onChange={(e) => {
                                      values.top_products = e;
                                      console.log("Selected Products:", e);
                                      setProducts(e);
                                    }}
                                  />
                                  {errors.top_products ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.top_products}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="flex p-4 sm:p-3">
                              <div className="flex h-6 items-center">
                                <input
                                  value={values.is_hr_professional}
                                  checked={values.is_hr_professional}
                                  id="is_hr_professional"
                                  name="is_hr_professional"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-800 focus:ring-indigo-800"
                                  onChange={(e) => {
                                    handleChange(e);
                                    console.log(e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                />
                              </div>
                              <div className="ml-3 text-sm leading-6">
                                <label
                                  htmlFor="is_hr_professional"
                                  className="font-medium text-gray-900"
                                >
                                  Is HR Professional
                                </label>
                                {/* <p id="is_hr_professional" className="text-gray-500">
                                  If checked the product will be taged as a spotlight product
                                </p> */}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#DB7D0B] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                          >
                            {drawerID ? "Update" : "Add"}
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
}
export default List;
