import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import { Link, useSearchParams } from "react-router-dom";
import { review_columns } from "../../../components/tables/tableheader";
import Table from "../../../components/tables/table";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import MasterServices from "../../../ApiServices/MasterServices";
import { Dialog, Transition } from "@headlessui/react";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { toast } from "react-toast";
import moment from "moment";
import Select from "react-select";
import { exportToExcel } from "../../../helper/commonHelper";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const Review = () => {
  const pages = [{ title: "Review", href: "/review-list" }];
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [claimedData, setClaimData] = useState([]);
  const [reviewData, setReviewData] = useState([]);
  console.log(reviewData);

  const [modalOpenFlage, setmodalOpenFlage] = useState({
    isOpen: false,
    data: undefined,
  });

  const [user_first_name, setFirstName] = useState();
  const [user_email, setEmail] = useState();
  const [rr_created_at, setCreatedAt] = useState();
  const [rr_remark, setRemark] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [rr_star_rating, setStarRating] = useState();
  const [modalDatas, setModalData] = useState();
  const [dateFilterData, setDateFilterData] = useState([]);
  const [dateclick, setDateClick] = useState(false);
  const [allReviewData,setAllReviewData] = useState([])
  const [filters, setFilters] = useState({
    ratings: 5,
    startDate: "",
    endDate: "",
  });
  console.log(filters);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rating, setRating] = useState(5);
  console.log(rating);
  const [filteredData, setfilteredData] = useState(null);
  console.log(filteredData);

  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });

  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);

    setFirstName(null);
    setEmail(null);
    setCreatedAt(null);
    setRemark(null);
    setStarRating(null);
  }

  function openModal(modalData) {
    console.log("modal data", modalData);

    setIsOpen(true);

    setModalData(modalData);
  }

  let SPNM_ACtive_Tab = 0;

  const getAllReviewData = async () => {
    const response = await MasterServices.getAllReviews();

    console.log(response, "<<<<<<<<<response");
    if (response.data.data.length > 0) {
      setReviewData(response?.data?.data);
      setAllReviewData(response?.data?.data)
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllReviewData();
  }, []);

  const data = [
    {
      email: "amaan.mithani@gmail.com",
      review: "****",
    },
  ];

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        stars.push(
          <span key={i} className="text-yellow-500">
            &#9733;
          </span>
        ); // Unicode star character
      } else {
        stars.push(
          <span key={i} className="text-gray-500">
            &#9734;
          </span>
        ); // Unicode empty star character
      }
    }
    return stars;
  };

  const formatDate = () => {
    setDateClick(true);
    console.log("reviewData", reviewData);
    if (fromDate && toDate) {
      const filteredData =
        reviewData &&
        reviewData.filter((item) => {
          console.log("item", item?.rr_created_at);
          let itemDate = new Date(item?.rr_created_at);

          let parsedDate = new Date(itemDate);
          let year = parsedDate.getFullYear();
          let month = String(parsedDate.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-indexed
          let day = String(parsedDate.getDate()).padStart(2, "0");

          let formattedDate = `${year}-${month}-${day}`;

          return formattedDate >= fromDate && formattedDate <= toDate;
        });
      setDateFilterData(filteredData);

      console.log("filteredData", filteredData);
    } else {
      console.log("select date");
    }
  };

  const handleFromChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToChange = (e) => {
    setToDate(e.target.value);
  };

  // console.log("setFromDate", fromDate, "setToDate", toDate);

  useEffect(() => {
 

    let mutableReviewData = reviewData;

    console.log(filters.ratings);

    if (filters.ratings !== undefined) {
      console.log("INLOOP");

      mutableReviewData = mutableReviewData.filter((review) => {
        if (filters.ratings === 0) {
          console.log("INLOOP");

          return review.rr_star_rating === null;
        } else {
          const numericFilterRating = Number(filters.ratings);
          const numericStarRating = Number(review.rr_star_rating);

          return numericStarRating === numericFilterRating;
        }
      });
      console.log(mutableReviewData);
    }

    if (filters.startDate || filters.endDate) {

      const startDate = filters.startDate
        ? new Date(filters.startDate)
        : new Date("1000-01-01");
      const endDate = filters.endDate ? new Date(filters.endDate) : new Date();

      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);

      mutableReviewData = mutableReviewData.filter((product) => {
        const productDate = new Date(product.rr_created_at);
        productDate.setHours(0, 0, 0, 0);

        return productDate >= startDate && productDate <= endDate;
      });
    }
   
      setfilteredData(mutableReviewData);
    
  }, [filters, reviewData]);

  const clearForm = () => {
    getAllReviewData();
    setEndDate("");
    setStartDate("");
  
    setRating(5);
    // setselectedClaimStatus(null);
    setFilters({
      ratings: 5,
      startDate: "",
      endDate: "",
    });
  };

  const downloadExcel = () => {
    const dataToExport = filteredData || reviewData;

    let columns = [];
    // NAME
    // EMAIL
    // PRODUCT
    // REVIEW
    // DATE
    let transformedData = [];

      // Default columns for other cases
      columns = [
        { Header: "Name", accessor: "Name" },
        { Header: "Email", accessor: "Email" },
        { Header: "Phone No", accessor: "PhoneNo" },
        { Header: "Designation", accessor: "Designation" },
        { Header: "Company", accessor: "Company" },
        {Header: "Role", accessor: "Role" },
        { Header: "Product", accessor: "Product" },
        {Header : "Review", accessor: "Review"},
        {Header : "Description", accessor: "Description"}
      ];

      // Transform data for default case
      transformedData = dataToExport.map((item) => ({
        Name: `${item.m_user_rr_relation?.user_first_name || ""} ${
          item.m_user_rr_relation?.user_last_name || ""
        }`,
        Email: item.m_user_rr_relation?.user_email || "",

        PhoneNo: item.m_user_rr_relation?.user_mobile || "",
        Designation: item.m_user_rr_relation?.user_designation || "",
        Company : item.m_user_rr_relation?.user_company_name || "",
        Role: item.rr_cu_relation?.m_role_relation?.role_name || "",
        
        Product: item.m_products_relation?.ser_prod_name || "",
        Review : item.rr_star_rating || "",
        Description : item.rr_remark || "",
        
      }));


    const headings = [columns.map((col) => col.Header)];

    // Use the adjusted transformedData for the export
    exportToExcel(transformedData, headings, "ReviewExport.xlsx", "Sheet 1");
  };

  const AllReviewHandler = ()=>{
    
    setFilters((prev)=>({
      ...prev,
      ratings:undefined
    }))
  }
  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        {/* <ConfirmationModal
          open={confirmationModal.status}
          setOpen={setConfirmationModal}
          onDelete={() => onDeleteRole(confirmationModal.role_id)}
        /> */}
        <ConfirmationModal
          open={confirmationModal.status}
          setOpen={setConfirmationModal}
          confirmationButtonText={"Approve"}
          data={confirmationModal}
        />
        <Breadcrumb pages={pages} />
        <div className="">
          <h1 className="text-xl font-semibold text-gray-900">Review</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the Reviews.
          </p>
        </div>
        <div className=" mt-4 w-full shadow-sm rounded-md h-auto border-2 p-4">
          <div className=" w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div>
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Filter by star rating:
              </label>
              <div>
                <div className="relative mb-6 ">
                  <input
                    id="star-range-input"
                    type="range"
                    min="0"
                    max="5"
                    value={rating}
                    className="range appearance-none bg-gray-500 rounded-md accent-[#fd6e1d] accent-gradient-to-r w-full h-2  "
                    onChange={(e) => {
                      setRating(Number(e.target.value));
                      setFilters((prevFilters) => ({
                        ...prevFilters,
                        ratings: Number(e.target.value),
                      }));
                    }}
                  />
                  {/* <input
                    id="star-range-input"
                    type="range"
                    min="0"
                    max="5"
                    value={rating}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer "
                    onChange={(e) => {
                      setRating(Number(e.target.value))
                      setFilters((prevFilters) => ({
                      ...prevFilters,
                      ratings: Number(e.target.value),
                    })); 
                    }}
                  /> */}
                  <div className="flex justify-between text-sm text-gray-500 ">
                    <span>0</span>
                    <span>1</span>
                    <span>2</span>
                    <span>3</span>
                    <span>4</span>
                    <span>5</span>
                  </div>
                </div>
                {/* {errors.enterprise && touched.enterprise ? (
                    <p className="text-red-600 text-sm">{errors.enterprise}</p>
                  ) : null} */}
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Start Date
              </label>
              <div className="flex">
                <input
                  type="date"
                  name="date"
                  value={startDate}
                  onChange={(e) => {
                    const startDate = e.target.value;
                    setStartDate(startDate);
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      startDate: startDate,
                    }));
                  }}
                  className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium leading-6 text-gray-900">
                End Date
              </label>
              <div className="flex">
                <input
                  type="date"
                  name="date"
                  value={endDate}
                  onChange={(e) => {
                    const endDate = e.target.value;
                    setEndDate(endDate);
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      endDate: endDate,
                    }));
                  }}
                  className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
          <div className=" pt-4 flex gap-x-2">
            <button
              onClick={clearForm}
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#fd6e1d] to-[#ef6122] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 "
            >
              Clear
            </button>
            <button
              onClick={()=>AllReviewHandler()}
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#fd6e1d] to-[#ef6122] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 "
            >
              All Reviews
            </button>
          </div>
        </div>
        <div className=" w-full mt-4 flex justify-end">
          <button
            type="button"
            onClick={downloadExcel}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#fd6e1d] to-[#ef6122] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
          >
            Download Excel
          </button>
        </div>
        {/* <div className="mt-4 flex justify-end gap-x-5">
          <div className="px-4 sm:px-6 lg:px-8"> */}
        {/* Other content */}

        {/* <div className="mt-4 flex justify-end gap-x-5"> */}
        {/* <div className="flex">
                <input
                  type="date"
                  value={fromDate}
                  name="fromDate" 
                  onChange={handleFromChange} 
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div> */}
        {/* <div className="flex">
                <input
                  type="date"
                  value={toDate}
                  name="toDate" 
                  onChange={handleToChange} 
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div> */}
        {/* <button className="" onClick={formatDate}>
                submit
              </button> */}
        {/* </div> */}

        {/* </div>
        </div> */}

        <Table
          columns={review_columns({
            openModal,
          })}
          data={filteredData ? filteredData : reviewData}
          isOpen={isOpen}
        />

        {
          <Transition show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black/25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                      <div className="mt-2">
                        <ul>
                          <li>
                            Name:{" "}
                            {modalDatas?.m_user_rr_relation?.user_first_name}
                          </li>
                          <li>
                            {" "}
                            Email: {modalDatas?.m_user_rr_relation?.user_email}
                          </li>
                          <li> 
                          {" "}
                          Review Description: {modalDatas?.rr_remark}</li>
                          <li>
                            {" "}
                            Rating Stars:{" "}
                            {renderStars(modalDatas?.rr_star_rating)}
                          </li>
                          <li>
                            {" "}
                            Date:{" "}
                            {moment(modalDatas?.rr_created_at).format(
                              "YYYY-MM-DD"
                            )}
                          </li>
                        </ul>
                      </div>

                      <div className="mt-4">
                        <button
                          type="button"
                          className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          onClick={closeModal}
                        >
                          Got it, thanks!
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        }
      </div>
    </>
  );
};

export default Review;
