
import MasterServices from '../ApiServices/MasterServices';
import { toast } from 'react-toast'

export const singleUploadFiles = async (image_docName, folderName) => {
  const formData = new FormData();
  formData.append('image_docName', image_docName);
  formData.append('folderName', folderName);
  return MasterServices.uploadMaster(formData)
    .then((response) => {
      const { data, status } = response;
      if (status == 200) {
        
        toast.success("File Uploaded Successfully!");
        return data;
      } else {
        toast.error("Fatal Error Please Contact Admin")
      }
    })
    .catch((error) => {
      const msg = "uploadMaster catch Error"; //error.response.data.message;
      toast.error(msg);
    });
};


export const uploadPdfFiles = async (image_docName, folderName,selectedFile) => {
  const formData = new FormData();
  formData.append('file', selectedFile, selectedFile?.name);
  formData.append('image_docName', image_docName?.name);
  formData.append('folderName', folderName);
  return MasterServices.uploadPDF(formData)
    .then((response) => {
      const { data, status } = response;
      if (status == 200) {
        
        toast.success("File Uploaded Successfully!");
        return data;
      } else {
        toast.error("Fatal Error Please Contact Admin")
      }
    })
    .catch((error) => {
      const msg = "uploadMaster catch Error"; //error.response.data.message;
      toast.error(msg);
    });
};
export const uploadPdfFilesBak = async (image_docName, folderName) => {
  const formData = new FormData();
  formData.append('image_docName', image_docName.name);
  formData.append('folderName', folderName);
  return MasterServices.uploadPDF(formData)
    .then((response) => {
      const { data, status } = response;
      if (status == 200) {
        
        toast.success("File Uploaded Successfully!");
        return data;
      } else {
        toast.error("Fatal Error Please Contact Admin")
      }
    })
    .catch((error) => {
      const msg = "uploadMaster catch Error"; //error.response.data.message;
      toast.error(msg);
    });
};

export const uploadVideoFiles = async (video_file, folderName) => {
  const formData = new FormData();
  formData.append('video_file', video_file);
  formData.append('folderName', folderName);
  return MasterServices.uploadVideo(formData)
    .then((response) => {
      const { data, status } = response;
      if (status == 200) {
        
        toast.success(" Video File Uploaded Successfully!");
        return data;
      } else {
        toast.error("Fatal Error Please Contact Admin")
      }
    })
    .catch((error) => {
      const msg = "uploadMaster catch Error"; //error.response.data.message;
      toast.error(msg);
    });
};