import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { clientSchema } from "../schemas";
import SearcherMasterServices from "../ApiServicesSearcher/MasterServices"
import { toast } from "react-toast";
import { useFormik } from "formik";
import { FidgetSpinner } from "react-loader-spinner";
import { singleUploadFiles, uploadPdfFiles } from "../helper/multiUploadHelper";
const { REACT_APP_IMG_URL } = process.env;

export default function AddClientModal(props) {

    const {
        title = "Add New Client",
        confirmationButtonColor = "grey",
        open,
        setOpen,
        onDelete,
        onSend,
        data,
        dataModal,
        refreshApis
    } = props;

    console.log(props);
    // const cancelButtonRef = useRef(null);
    const confirmationButtonTextref = useRef(null);
    const initialValues = {
        id: "",
        name: "",
        logo: ""
    };
    console.log(initialValues);
    const [loading, setLoading] = useState(false);

    const [form, setForm] = useState(initialValues);
    console.log(form);
    const [logo, setLogo] = useState("");


    const handleFileChange = async (event, setState) => {
        const file = event.currentTarget.files[0];
        if (file) {
            const response = await uploadPdfFiles(file, "product", event.target.files[0]);
            setState(response.data.path + response.data.fileName);
        } else {
            setState(null)
        }

        // const reader = new FileReader();
        // reader.readAsDataURL(file);
        // reader.onload = async (e) => {
        //   const response = await singleUploadFiles(e.target.result, "product");
        //   console.log("url", response.data.path + "/" + response.data.fileName);
        //   setState(response.data.path + "/" + response.data.fileName);
        // };
    };


    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            enableReinitialize: true,
            initialValues: form,
            validationSchema: clientSchema,
            onSubmit: async (values, action) => {

                let body = {
                    name: values.name,
                    logo: logo
                };
                // if (drawerID === undefined || drawerID === null || drawerID === "") {
                await addClient(body);
                refreshApis();
                setOpen({
                    status: false,
                });
                // } else {
                //   await updateClient(body);
                // }
                // dispatch(addRole(body));
                // else dispatch(updateRole(role_id, body));
                // action.resetForm();
                // getAllData();
                // if (modalOpenFlage === true) {
                //   getAllData();
                //   setmodalOpenFlage(false);
                // }
                // navigate("/client");
            },
        });

    async function addClient(obj) {
        setLoading(true);
        let body = {
            data: {
                name: obj.name,
                logo: obj.logo
            },
        };
        await SearcherMasterServices.addClient({ body })
            .then((res) => {
                const { data, status } = res;
                setForm({
                    id: "",
                    name: "",
                    logo: ""
                })
                values.id = ""
                values.logo = "";
                values.name = "";
                setLogo("")
                setOpen({
                    id: null,
                    status: false,
                });
                setLoading(false);
                onSend(res);

            })
            .catch((err) => {
                toast.error(err.data?.message);

                setLoading(false);
            });
    };


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                initialFocus={confirmationButtonTextref}
                onClose={() => {
                    setOpen({
                        id: null,
                        status: false,
                    });
                }}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();

                                        handleSubmit(e);
                                    }}
                                    className="flex h-full flex-col divide-y divide-gray-200"
                                >
                                    <div className="sm:flex sm:items-start">
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                            <Dialog.Title
                                                as="h3"
                                                className="text-lg font-medium leading-6 text-gray-900"
                                            >
                                                {title}
                                            </Dialog.Title>
                                            <div>
                                                <div className="flex flex-1 flex-col justify-between">
                                                    <div className="divide-y divide-gray-200 p-4 sm:p-6">
                                                        <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                                            <div>
                                                                <label
                                                                    htmlFor="name"
                                                                    className="block text-sm font-medium text-gray-900 mt-2 mb-1"
                                                                >
                                                                    Enter Client Name
                                                                </label>
                                                            </div>
                                                            <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                                                <input
                                                                    value={values.name}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    type="text"
                                                                    placeholder="Enter Client Name"
                                                                    name="name"
                                                                    autoComplete="off"
                                                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                                                />
                                                                {errors.name && touched.name ? (
                                                                    <p className="text-red-600 text-sm">
                                                                        {errors.name}
                                                                    </p>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                                            <div>
                                                                <label
                                                                    htmlFor="name"
                                                                    className="block text-sm font-medium text-gray-900 mt-2 mb-1"
                                                                >
                                                                    Select Logo
                                                                </label>
                                                                <p className="text-xs text-#231C07 opacity-50">
                                                                    Recommended Size: 150 x 100 px | PNG | Max Size: 2 MB | Clear
                                                                    Background
                                                                </p>
                                                            </div>
                                                            <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                                                <input
                                                                    onChange={(e) => handleFileChange(e, setLogo)}
                                                                    onBlur={handleBlur}
                                                                    type="file"
                                                                    name="logo"
                                                                    id="logo"
                                                                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                />
                                                                {errors.logo && touched.logo ? (
                                                                    <p className="text-red-600 text-sm">
                                                                        {errors.logo}
                                                                    </p>
                                                                ) : null}
                                                                <div className="col-span-full mb-5">
                                                                    {logo ? (
                                                                        <img
                                                                            className="shadow-sm mt-4 w-40"
                                                                            src={REACT_APP_IMG_URL + logo}
                                                                            alt="preview-banner"
                                                                        />
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                        <button
                                            type="button"
                                            className={`inline-flex justify-center w-full px-4 py-2 text-base font-medium text-gray-500 bg-${confirmationButtonColor}-600 border border-transparent rounded-md shadow-sm hover:bg-${confirmationButtonColor}-700 focus:outline-none focus:ring-2 focus:ring-${confirmationButtonColor}-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                                            onClick={() => {
                                                setForm({
                                                    id: "",
                                                    name: "",
                                                    logo: ""
                                                })
                                                values.id = ""
                                                values.logo = "";
                                                values.name = "";
                                                setLogo("")
                                                setOpen({
                                                    id: null,
                                                    status: false,
                                                });
                                            }}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            disabled={loading}
                                            type="submit"
                                            className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-red-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                                            ref={confirmationButtonTextref}
                                        >
                                            {loading ? '....' : 'Submit'}
                                            {/* {data?.id?.attributes?.publishedAt || dataModal?.ser_prod_publishedAt?confirmInActive:confirmationButtonText} */}
                                        </button>

                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
