import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./protectedRoute";
import Login from "../container/pages/login/Login";
import UsersList from "../container/pages/users/Users";
import CustomerList from "../container/pages/customer/Customer";
import ProductList from "../container/pages/product/Product";
import RoleList from "../container/pages/role/RoleListNew";
import TagList from "../container/pages/tag/TagList";
import BuisnessObjectiveList from "../container/pages/buisness-objective/BuisnessObjectiveList";
import CategoriesList from "../container/pages/categories/CategoriesList";
import SubCategoriesList from "../container/pages/sub-categories/SubCategoriesList";
import CompanyList from "../container/pages/company/CompanyList";
import ClientList from "../container/pages/client/ClientList";
import HCMList from "../container/pages/hcm/HCMList";
import SearcherProductList from "../container/pages/searcher-product/SearcherProductList";
import EditPermissions from "../container/pages/role/EditPermissions";
import AddUser from "../container/pages/users/AddUser";
import Profile from "../container/pages/profile/Profile";

import Error from "../container/error/Error";
import Home from "../container/pages/home/Home";
import Enquiry from "../container/pages/enquiry/Enquiry";
import Claim from "../container/pages/claims/Claim";
import AddProduct from "../container/pages/product/NewAddProduct";
import Review from "../container/pages/reviews/Review"
import FollowUpList from "../container/pages/followUp/FollowUpList";

const RoutesConfig = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signUp" element={<Login />} />
        <Route path="*" element={<Error />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<UsersList />} />
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/user" element={<UsersList />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/user-list" element={<UsersList />} />
          <Route path="/customer" element={<CustomerList />} />
          <Route path="/product" element={<ProductList />} />
          <Route path="/addproduct" element={<AddProduct />} />
          <Route path="/editproduct/:product_id" element={<AddProduct />} />
          <Route path="/role" element={<RoleList />} />
          <Route path="/role">
						<Route index element={<RoleList />} />
						<Route path=":role_id" element={<EditPermissions />} />
					</Route>
          <Route path="/tags" element={<TagList />} />
          <Route path="/business-objective" element={<BuisnessObjectiveList />} />
          <Route path="/category" element={<CategoriesList />} />
          <Route path="/sub-category" element={<SubCategoriesList />} />
          <Route path="/client" element={<ClientList />} />
          <Route path="/hcm" element={<HCMList />} />
          <Route path="/company" element={<CompanyList />} />
          <Route path="/searcher-product-list" element={<SearcherProductList />} />
          <Route path="/enquires" element={<Enquiry />} />
          <Route path="/claims" element={<Claim />} />
          <Route path="/review-list" element={<Review />} />
          <Route path="/followup-list"  element={<FollowUpList/>}/>
          <Route path="/add-user/">
            <Route index element={<AddUser />} />
            <Route path=":user_id" element={<AddUser />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};

export default RoutesConfig;
