import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
  usePagination,
} from "react-table";

import { Button, PageButton } from "./paginationButtons";
import { SortIcon, SortUpIcon, SortDownIcon } from "../../assets/icons/sorting";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { getAllowedActions } from "../../helper/commonHelper";
import { FallingLinesLoader } from "../spinners/Spinner";
import debounce from "lodash/debounce";

// Define a default UI for filtering

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-gray-700">{render("Header")}: </span>
      <select
        className="rounded-md border-gray-300 shadow-sm focus:border-cyan-300 focus:ring focus:ring-cyan-200 focus:ring-opacity-50"
        name={id}
        id={id}
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </label>
  );
}

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  debounceFetcher,
}) {
  const count = preGlobalFilteredRows.length;
  //
  const [value, setValue] = React.useState(globalFilter);
  const onFilterChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  //
  return (
    <label className="flex gap-x-2 items-baseline">
      <input
        type="text"
        className="rounded-md border-gray-300 shadow-sm focus:border-cyan-300 focus:ring focus:ring-cyan-200 focus:ring-opacity-50"
        value={value || ""}
        onChange={(e) => {
        
          setValue(e.target.value);
          debounceFetcher(e.target.value);
        }}
        placeholder={`${count} records...`}
      />
    </label>
  );
}

function Table({
  columns,
  data,
  table_id,
  printRef,
  zoom,
  pagination,
  goToThePage,
  loading,
  setPageSizeState,
}) {
  console.log(pagination);
  const {
    state,
    pageOptions,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    preGlobalFilteredRows,
    setGlobalFilter,
    setPageSize,
  } = useTable(
    {
      columns,
      data,

      // initialState: { pageIndex: 1 },
    },
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    usePagination // new
  );
  console.log(pageOptions);
  const debounceTimeout = 800;

  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      console.log("value", value);
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      goToThePage(1, null, { name: value }).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
      // setTimeout(() => {
      //   if (fetchId !== fetchRef.current) {
      //     // for fetch callback order
      //     return;
      //   }
      //   setOptions([]);
      //   setFetching(false);
      // }, 200)
    };
    return debounce(loadOptions, debounceTimeout);
  }, [goToThePage, debounceTimeout]);

  // useEffect(() => {
  //   debounceFetcher();
  // }, [])

  useEffect(() => {
    setPageSize(50);
  }, []);

  // Render the UI for your table
  return (
    <>
      {/* SEARCH UI */}
      <div className="sm:flex sm:gap-x-2 mt-5">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
          debounceFetcher={debounceFetcher}
        />
        {headerGroups.map((headerGroup) =>
          headerGroup.headers.map((column) =>
            column.Filter ? (
              <div className="mt-2 sm:mt-0" key={column.id}>
                {column.render("Filter")}
              </div>
            ) : null
          )
        )}
      </div>
      {/* TABLE UI */}
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <div
          id={table_id}
          ref={printRef}
          className={`mt-4 flex flex-col ${zoom ? zoom : ""}`}
        >
          <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table
                  {...getTableProps()}
                  className="min-w-full divide-y divide-gray-200"
                >
                  <thead className="bg-gray-50">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          // Add the sorting props to control sorting. For this example
                          // we can add them into the header props
                          <th
                            scope="col"
                            className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            {...column.getHeaderProps({
                              style: { width: column.width },
                            })}
                          >
                            <div className="flex items-center justify-between">
                              {column.render("Header")}
                              {/* Add a sort direction indicator */}
                              <span>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <SortDownIcon className="w-4 h-4 text-gray-400" />
                                  ) : (
                                    <SortUpIcon className="w-4 h-4 text-gray-400" />
                                  )
                                ) : (
                                  <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                                )}
                              </span>
                            </div>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody
                    {...getTableBodyProps()}
                    className="bg-white divide-y divide-gray-200"
                  >
                    {page?.length > 0 ? (
                      page.map((row, i) => {
                        // new

                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  className={`px-6 py-4 whitespace-nowrap text-left `}
                                  role="cell"
                                >
                                  {cell.column.Cell.name ===
                                  "defaultRenderer" ? (
                                    <div className="text-sm text-gray-500">
                                      {cell.render("Cell")}
                                    </div>
                                  ) : (
                                    cell.render("Cell")
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })
                    ) : (
                      <div className="p-5 text-center whitespace-nowrap w-full text-gray-500">
                        No Data Found
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Pagination */}
      {/* <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0 mt-5">
        <div className="-mt-px flex w-0 flex-1">
          <button
            onClick={() => goToThePage(pagination.page - 1)} disabled={pagination.page == 1}
            className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          >
            <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
            Previous
          </button>
        </div>
        <div className="hidden md:-mt-px md:flex">
          {[...Array(parseInt(pagination?.pageCount) || 0)]?.map((page, key) => (
            <button
                key={key}
                onClick={(e) => {
                  goToThePage(key + 1);
                }}
                // className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                className={`${key+1 == pagination.page ? "inline-flex items-center border-t-2 border-cyan-500 px-4 pt-4 text-sm font-medium text-cyan-600" : "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}`}
              >
                {key + 1}
              </button>
            ))}

        </div>
        
        <div className="-mt-px flex w-0 flex-1 justify-end">
          <button
            onClick={() => goToThePage(pagination.page + 1)} disabled={pagination.page == pagination.pageCount}
            className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          >
            Next
            <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
          </button>
        </div>
      </nav> */}

      <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0 mt-5">
        <div className="-mt-px flex w-0 flex-1">
          <button
            onClick={() => goToThePage(pagination.page - 1)}
            disabled={pagination.page === 1}
            className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          >
            <ArrowLongLeftIcon
              className="mr-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Previous
          </button>
        </div>
        <div className="hidden md:-mt-px md:flex">
          {pagination &&
            Array.from({ length: pagination.pageCount }, (_, i) => i + 1)
              .filter((page) => {
                return (
                  page === 1 ||
                  page === pagination.pageCount ||
                  (page >= pagination.page - 1 && page <= pagination.page + 1)
                );
              })
              .reduce((acc, page, i, array) => {
                if (i > 0 && page !== array[i - 1] + 1) {
                  acc.push("...");
                }
                acc.push(page);
                return acc;
              }, [])
              .map((page, index) =>
                typeof page === "number" ? (
                  <button
                    key={index}
                    onClick={() => goToThePage(page)}
                    className={`${
                      page === pagination.page
                        ? "inline-flex items-center border-t-2 border-cyan-500 px-4 pt-4 text-sm font-medium text-cyan-600"
                        : "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    }`}
                  >
                    {page}
                  </button>
                ) : (
                  <span
                    key={index}
                    className="px-4 pt-4 text-sm font-medium text-gray-500"
                  >
                    {page}
                  </span>
                )
              )}
        </div>
        <div className="-mt-px flex w-0 flex-1 justify-end">
          <button
            onClick={() => goToThePage(pagination.page + 1)}
            disabled={pagination.page === pagination.pageCount}
            className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          >
            Next
            <ArrowLongRightIcon
              className="ml-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
        </div>
      </nav>

      <div className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 pl-2 font-medium">
        <span>
          Data{" "}
          <strong>
            {pagination.pageSize} of {pagination.total}
          </strong>{" "}
        </span>
        <div className="ml-5">
          <select
            className="rounded"
            value={pagination.pageSize}
            onChange={(e) => {
              goToThePage(
                pagination.page >= pagination.pageCount ? 1 : pagination.page,
                e.target.value
              );
              setPageSizeState(e.target.value);
            }}
          >
            <option>10</option>
            <option>20</option>
            <option>30</option>
            <option>40</option>
            <option>50</option>
          </select>
        </div>
      </div>
    </>
  );
}

export default Table;
