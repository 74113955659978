import React, { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Table from "../../../components/tables/table";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Select from "react-select";
import { XMarkIcon } from "@heroicons/react/20/solid";
import Step1 from "./addProduct/step1";
import Step2 from "./addProduct/step2";
import Step3 from "./addProduct/step3";
import Step4 from "./addProduct/step4";
import Step5 from "./addProduct/step5";
import SearcherMasterServices from "../../../ApiServicesSearcher/MasterServices";
import { toast } from "react-toast";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const AddProduct = () => {
  const [allStepsData, setAllStepsData] = useState({});
  const [tabId, setTabId] = useState(0);
  const [stepperIndex, setStepperIndex] = useState();
  const [editProductData, setEditProductData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { product_id: product_id } = useParams();

  const getProduct = async (product_id) => {
    setLoading(true);
    await SearcherMasterServices.getProduct(product_id)
      .then((res) => {
        const { data, status, meta } = res;
        console.log(data);
        if (data.id) {
          setEditProductData(data.attributes);
          // setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err.data?.message);
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (product_id) {
      getProduct(product_id);
      console.log("product_id", product_id);
    }
  }, [product_id]);

  const [addFeatureItem, setFeatureItem] = useState([
    {
      id: 1,
      feature: "",
    },
    {
      id: 2,
      feature: "",
    },
    {
      id: 3,
      feature: "",
    },
  ]);

  const [addHightlight, setAddHightlight] = useState([
    {
      id: 1,
      header: "",
      description: "",
    },
  ]);
  const [addTrustedClient, setTrustedClient] = useState([
    {
      id: 1,
      company_name: "",
    },
  ]);
  const [addBroucher, setAddBroucher] = useState([
    { id: 1, brocuher_image: "", brocuher_name: "", broucher_date: "" },
  ]);

  const [addProductDemo, setAddProductDemo] = useState([
    {
      id: 1,
      demo_about: "",
      demo_url: "",
    },
  ]);

  const [addteamInfo, setAddTeamInfo] = useState([
    {
      id: 1,
      team_image: "",
      team_name: "",
      team_designation: "",
      team_about: "",
    },
  ]);

  const colourOptions = [
    {
      label: "IT",
      value: "it",
    },
    {
      label: "VECHILE",
      value: "it",
    },
  ];
  const countryOptions = [
    {
      label: "India",
      value: "it",
    },
    {
      label: "Us",
      value: "it",
    },
  ];
  const stateOptions = [
    {
      label: "Maharashtra",
      value: "it",
    },
    {
      label: "Delhi",
      value: "it",
    },
  ];
  const categoryOptions = [
    {
      label: "category 1",
      value: "category 2",
    },
    {
      label: "category 2",
      value: "category 2",
    },
  ];

  const subCategoryOptions = [
    {
      label: "subcategory 1",
      value: "subcategory 2",
    },
    {
      label: "subcategory 2",
      value: "subcategory 2",
    },
  ];

  const problemStatement = [
    {
      label: "problemStatement 1",
      value: "problemStatement 2",
    },
    {
      label: "problemStatement 2",
      value: "problemStatement 2",
    },
  ];

  const pricingPlan = [
    {
      label: "pricing plan1",
      value: "pricing plan1",
    },
    {
      label: "pricing plan2",
      value: "pricing plan2",
    },
  ];
  const stepperOptions = [
    {
      id: 0,
      title: "Product Overview",
    },
    {
      id: 1,
      title: "Images & Media",
    },
    {
      id: 2,
      title: "Product Demo",
    },
    {
      id: 3,
      title: "Makers & Team",
    },
    {
      id: 4,
      title: "Review & Submit",
    },
  ];

  const handleNextButton = () => {
    setTabId((prev) => {
      const maxIndex = stepperOptions.length - 1;
      return prev < maxIndex ? prev + 1 : prev;
    });
  };

  const handlePrevButton = () => {
    setTabId((prev) => {
      const maxIndex = stepperOptions.length - 1;
      return prev <= maxIndex ? prev - 1 : prev;
    });
  };

  const addFeatureBtn = () => {
    setFeatureItem([
      ...addFeatureItem,
      {
        id: addFeatureItem.length + 1,
        feature: "",
      },
    ]);
  };
  const removeFeatureBtn = (item) => {
    setFeatureItem((prev) => prev.filter((items) => items.id !== item.id));
  };

  const addHightlightBtn = () => {
    setAddHightlight([
      ...addHightlight,
      {
        id: addHightlight.length + 1,
        header: "",
        description: "",
      },
    ]);
  };

  const removeHightlightBtn = (item) => {
    setAddHightlight((prev) => prev.filter((items) => items.id !== item.id));
  };

  const addTrustedClientBtn = () => {
    setTrustedClient([
      ...addTrustedClient,
      {
        id: addTrustedClient.length + 1,
        company_name: "",
      },
    ]);
  };
  const removeTrustedClientBtn = (item) => {
    setTrustedClient((prev) => prev.filter((items) => items.id !== item.id));
  };

  const addBroucherBtn = () => {
    setAddBroucher([
      ...addBroucher,
      {
        id: addBroucher.length + 1,
        brocuher_image: "",
        brocuher_name: "",
        broucher_date: "",
      },
    ]);
  };
  const removeBroucherBtn = (item) => {
    setAddBroucher((prev) => prev.filter((items) => items.id !== item.id));
  };

  const addProductDemoBtn = () => {
    setAddProductDemo([
      ...addProductDemo,
      {
        id: addProductDemo.length + 1,
        demo_about: "",
        demo_url: "",
      },
    ]);
  };
  const removeProductDemoBtn = (item) => {
    setAddProductDemo((prev) => prev.filter((items) => items.id !== item.id));
  };

  const addTeamInfoBtn = () => {
    setAddTeamInfo([
      ...addteamInfo,
      {
        id: addteamInfo.length + 1,
        team_image: "",
        team_name: "",
        team_designation: "",
        team_about: "",
      },
    ]);
  };
  const removeTeamInfoBtn = (item) => {
    setAddTeamInfo((prev) => prev.filter((items) => items.id !== item.id));
  };

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="">
          <h1 className="text-xl font-semibold text-gray-900">{product_id?"Edit Product":"Add Product"}</h1>
        </div>
        <div className="py-5">
          <ol className="flex flex-wrap items-start w-full max-w-full p-3 gap-x-2 text-sm font-medium text-center  bg-white border border-gray-200 rounded-lg shadow-sm  sm:text-base   sm:p-4 sm:space-x-4 rtl:space-x-reverse">
            {stepperOptions.map((ele, index) => {
              return (
                <>
                  <li
                    className={
                      tabId === index
                        ? "flex items-center text-blue-600 dark:text-blue-500"
                        : "flex items-center text-gray-600 dark:text-gray-500"
                    }
                  >
                    <span
                      className={
                        tabId === index
                          ? "flex items-center justify-center w-5 h-5 me-2 text-xs border border-gray-600 rounded-full shrink-0 dark:border-gray-500"
                          : "flex items-center justify-center w-5 h-5 me-2 text-xs border border-gray-600 rounded-full shrink-0 dark:border-gray-500"
                      }
                    >
                      {ele.id + 1}
                    </span>

                    <span
                      className={` sm:ms-2 ${
                        index < tabId ? "text-gray-300" : ""
                      }`}
                    >
                      {ele.title}
                    </span>
                    {index <= 3 ? (
                      <svg
                        className="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 12 10"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m7 9 4-4-4-4M1 9l4-4-4-4"
                        />
                      </svg>
                    ) : null}
                  </li>
                </>
              );
            })}
          </ol>
        </div>
        <div className="py-5">
          <div>
            {loading ? (<FallingLinesLoader />) : (
              <>
            {/* page 0 form */}
            <Step1
              tabId={tabId}
              setTabId={setTabId}
              setAllStepsData={setAllStepsData}
              allStepsData={allStepsData}
              editProductData={editProductData}
              setLoading={setLoading}
            />
            {/* page 1 form */}
            <Step2
              tabId={tabId}
              setTabId={setTabId}
              setAllStepsData={setAllStepsData}
              allStepsData={allStepsData}
              editProductData={editProductData}
            />
            {/* page 2 form */}
            <Step3
              tabId={tabId}
              setTabId={setTabId}
              setAllStepsData={setAllStepsData}
              allStepsData={allStepsData}
              editProductData={editProductData}
            />
            {/* page 3 form */}
            <Step4
              tabId={tabId}
              setTabId={setTabId}
              setAllStepsData={setAllStepsData}
              allStepsData={allStepsData}
              editProductData={editProductData}
            />
            {/* page 4 form */}
            <Step5
              tabId={tabId}
              setTabId={setTabId}
              setAllStepsData={setAllStepsData}
              allStepsData={allStepsData}
              editProductData={editProductData}
            />
            </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProduct;
