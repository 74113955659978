import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import {
  buisnessObjectiveSchema,
  roleSchema,
  tagSchema,
} from "../../../schemas";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/tables/table-strapi";
import Select from "react-select";
// import { useDispatch } from "react-redux";
import {
  buisness_objective_columns,
  role_columns,
  tag_columns,
} from "../../../components/tables/tableheader";
import { Dialog, Transition } from "@headlessui/react";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import MasterServices from "../../../ApiServices/MasterServices";
import SearcherMasterServices from "../../../ApiServicesSearcher/MasterServices";
import { toast } from "react-toast";
import { getLoginUserInfo } from "../../../redux/userInfo/actions";
import {
  getAllowedActions,
  checkPermissionsAndRedirect,
} from "../../../helper/commonHelper";

function List() {
  const pages = [
    {
      title: "Buisness Objective List",
      href: "/business-objective",
      module_id: 3,
    },
  ];
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageSizeState, setPageSizeState] = useState(50);
  const [pagination, setPagination] = useState([]);
  const [searchedValue, setSearchedValue] = useState();
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [drawerID, setDrawerID] = useState();
  const [list, setList] = useState([]);
  const [allowedActions, setAllowedActions] = useState([]);
  const [filterStatusValue,setFilterStatusValue] = useState()
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(
        window.location.pathname,
        permissions,
        navigate
      );
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);

  useEffect(() => {
    document.title = "Octopus | Buisness Objective";
    getAllData();
  }, []);

  const initialValues = {
    id: "",
    name: "",
    business_objective_id: "",
  };

  const [form, setForm] = useState(initialValues);

  const getAllData = async (page, pageSize, filters) => {
    setLoading(true);
    if (filters?.name) {
      setSearchedValue(filters?.name)
    }
    if (filters?.name?.toString().length == 1) {
      setSearchedValue('')
    }
    if (pageSize) {
      setPageSizeState(pageSize)
    }
    let payload = {
      page,
      pageSize: !pageSize ? pageSizeState : pageSize,
      filters: {
        name: filters?.name,
        publishedAt: filterStatusValue?.value
      }
    }
    console.log("filters", filters)
    await SearcherMasterServices.getAllBuisnessObjective(payload)
      .then((res) => {
        const { data, status, meta } = res;

        if (Array.isArray(data) && data.length > 0) {
          // setList(data.filter((v) => !v.attributes.deleted));
          setList(data);
        }else if(Array.isArray(data) && data?.length == 0) {
          setList([])
        }
        if (meta.pagination) {
          setPagination(meta.pagination);
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.data?.message);

        setLoading(false);
      });
  };

  const addBO = async (obj) => {
    setLoading(true);
    let body = {
      data: {
        name: obj.name,
        business_objective_id: obj.business_objective_id || undefined,
      },
    };
    console.log(body);
    await SearcherMasterServices.addBuisnessObjective({ body })
      .then((res) => {
        const { data, status } = res;

        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.data?.message);

        setLoading(false);
      });
    setLoading(false);
  };

  const updateBO = async (obj) => {
    setLoading(true);
    let body = {
      data: {
        name: obj.name,
        business_objective_id: obj.business_objective_id,
      },
    };
    await SearcherMasterServices.updateBuisnessObjective({ id: drawerID, body })
      .then((res) => {
        const { data, status } = res;

        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.data?.message);

        setLoading(false);
      });
  };

  const deleteBO = async (id) => {
    setLoading(true);
    let body = {
      data: {
        // deleted: true,
        publishedAt: !id?.attributes?.publishedAt ? new Date() : null,
      },
    };
    await SearcherMasterServices.deleteBuisnessObjective({ id: id?.id, body })
      .then((res) => {
        const { data, status } = res;

        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.data?.message);

        setLoading(false);
      });
  };

  const handleDrawer = (type, id, obj) => {
    if ((type === "edit", id)) {
      setDrawerID(id);
      const initialValues = {
        id: obj.id,
        name: obj.attributes.name,
        business_objective_id: obj.attributes.business_objective_id,
      };
      setForm(initialValues);
    } else {
      setDrawerID("");
      setForm(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };

  const onDeleteOpen = (row) => {
    setConfirmationModal({ id: row, status: true });
  };

  const onDelete = async (id) => {
    await deleteBO(id);
    await getAllData();
    setConfirmationModal({ id: null, status: false });
  };
  const statusFilter = [
  
    {
      label:"Published",
      value:"published"
    },{
      label:"Unpublished",
      value:"unpublished"
    }
  ]


  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: form,
      validationSchema: buisnessObjectiveSchema,
      onSubmit: async (values, action) => {
        let body = {
          name: values.name,
          business_objective_id: values.business_objective_id,
        };
        if (drawerID === undefined || drawerID === null || drawerID === "") {
          await addBO(body);
        } else {
          await updateBO(body);
        }
        // dispatch(addRole(body));
        // else dispatch(updateRole(role_id, body));
        action.resetForm();
        getAllData();
        if (modalOpenFlage === true) {
          getAllData();
          setmodalOpenFlage(false);
        }
        navigate("/business-objective");
      },
    });


  useEffect(()=>{
    console.log("filterStatusValue",filterStatusValue,list)
    const fetchData = async()=>{

      let pageno =1;
      
      await getAllData(pageno,pageSizeState,filterStatusValue);
    }
    fetchData()
    
  },[filterStatusValue])

  const clearFormHandler = async()=>{
    setFilterStatusValue(null)
 
   }
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDelete(confirmationModal.id)}
        data={confirmationModal}
      />
      <Breadcrumb pages={pages} />
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-xl font-semibold text-gray-900">
            Buisness Objective List
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the Buisness Objective.
          </p>
        </div>
      <div className="flex">
        {allowedActions.filter((data) => data.permission_id == 33)?.length >
          0 ? (
          <Link
            onClick={() => handleDrawer("add", "", {})}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#fd6e1d] to-[#ef6122] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
          >
            Add Buisness Objective
          </Link>
        ) : null}
      </div>
      </div>
      <div className="mt-4 w-full shadow-sm rounded-md h-auto border-2 p-4">
        <div className=" w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Status
            </label>
            <div>
              <Select
                classNamePrefix="select"
                // defaultValue={statusFilter[0]}
                placeholder="Select"
                value={filterStatusValue}
                onBlur={handleBlur}
                name="userStatus"
                className="basic-single sm:text-sm"
                options={statusFilter}
                onChange={(selectedOption) => {
                setFilterStatusValue(selectedOption)
              }}
              />
            </div>
          </div>


        </div>
        <div className=" pt-4 ">
          <button
            
            onClick={clearFormHandler}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#fd6e1d] to-[#ef6122] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
          >
            Clear
          </button>
        </div>
      </div>

      {/* {loading ? (
				<FallingLinesLoader />
			) : ( */}
      <Table
        columns={buisness_objective_columns({
          onDeleteOpen,
          handleDrawer,
          allowedActions,
        })}
        data={list}
        pagination={pagination}
        goToThePage={getAllData}
        loading={loading}
        setPageSizeState={setPageSizeState}
        setSearchedValue={setSearchedValue}

      />
      {/* )} */}

      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();

                          handleSubmit(e);
                        }}
                        className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                      >
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-gradient-to-b from-[#872341] to-[#DB7D0B] py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {drawerID ? "Update" : "Add"} Objective
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md bg-gradient-to-b from-[#872341] to-[#DB7D0B] text-white hover:text-white"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-1 flex-col justify-between">
                            <div className="divide-y divide-gray-200 p-4 sm:p-6">
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="name"
                                    className="block text-sm font-medium text-gray-900 mt-2 mb-1"
                                  >
                                    Enter Objective Name
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Enter Objective Name"
                                    name="name"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.name && touched.name ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.name}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              {/* <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="objective-id"
                                    className="block text-sm font-medium text-gray-900 mt-2 mb-1"
                                  >
                                    Enter Objective ID
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.business_objective_id}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Enter Objective ID"
                                    name="business_objective_id"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.business_objective_id &&
                                  touched.business_objective_id ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.business_objective_id}
                                    </p>
                                  ) : null}
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#DB7D0B] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                          >
                            {drawerID ? "Update" : "Add"}
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
}
export default List;
