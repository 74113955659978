import { Provider } from "react-redux";
import RoutesConfig from "./routes/Routes";
import store from "./redux/store";
import "leaflet/dist/leaflet.css";
import { toast, ToastContainer } from "react-toast";
import 'react-image-crop/dist/ReactCrop.css'
const App = () => {
  return (
    <Provider store={store}>
      <RoutesConfig />
      <ToastContainer delay={3000} position="top-right" />
    </Provider>
  );
};

export default App;
