/* eslint-disable import/no-anonymous-default-export */
import Api from "./Api";
import AuthToken from "../ApiServices/AuthTokenServices";

export default {
  /* tags */
  async getAllTags(payload) {
    await AuthToken.getCurrentAuth();
    const page = payload.page || 1;
    const pageSize = payload.pageSize || 50;
    const name = payload?.filters?.name || "";
    const publishedAt = payload?.filters?.publishedAt || "";
    
    let URL = `${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/tags/?pagination[page]=${page}&pagination[pageSize]=${pageSize}&publicationState=preview&sort=updatedAt:asc`;
    if(publishedAt==="published"){
       URL = `${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/tags/?pagination[page]=${page}&pagination[pageSize]=${pageSize}&publicationState=preview&filters[publishedAt][$notNull]=true&sort=updatedAt:asc`;
    }
    if(publishedAt==="unpublished"){
       URL = `${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/tags/?pagination[page]=${page}&pagination[pageSize]=${pageSize}&publicationState=preview&filters[publishedAt][$null]=true&sort=updatedAt:asc`;
    }
    if(name){
       URL = `${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/tags/?pagination[page]=${page}&pagination[pageSize]=${pageSize}&publicationState=preview&filters[name][$containsi]=${name}&sort=updatedAt:asc`;
    }
    // const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/tags/?pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[deleted][$eq]=false`, {
    const response = await fetch(`${URL}`, {
      // const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/tags/?pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[deleted][$eq]=false&filters[name][$containsi]=${name}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
   
    return response;
  },

  async getTagsData(payload){
    await AuthToken.getCurrentAuth();
    const searchKey = payload?.searchKey || ''
    const response =await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/tags/?pagination[page]=1&pagination[pageSize]=50&filters[name][$containsi]=${searchKey}`,{
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
    return response;
  },

  async updateTag(payload) {
    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/tags/${payload.id}?populate=*`, {
      method: 'PUT',
      body: JSON.stringify(payload.body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
   
    return response;
  },

  async deleteTag(payload) {
    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/tags/${payload.id}?populate=*`, {
      method: 'PUT',
      body: JSON.stringify(payload.body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
 
    return response;
  },

  async addTag(payload) {
    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/tags/?populate=*`, {
      method: 'POST',
      body: JSON.stringify(payload.body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
   
    return response;
  },
  /* ends here */

  /* buisness object */
  async getBuisnessObjectives(payload) {
    await AuthToken.getCurrentAuth();
    const searchKey = payload?.searchKey || ''
    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/business-objectives/?pagination[page]=1&pagination[pageSize]=50&filters[name][$containsi]=${searchKey}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
    // console.log(response)
    return response;
  },
  async getAllBuisnessObjective(payload) {
    await AuthToken.getCurrentAuth();
    const page = payload.page || 1;
    const pageSize = payload.pageSize || 50;
    const name = payload?.filters?.name || "";
    const publishedAt = payload?.filters?.publishedAt || "";
    let URL = `${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/business-objectives/?pagination[page]=${page}&pagination[pageSize]=${pageSize}&publicationState=preview&sort=updatedAt:asc`

    if(name){
      URL = `${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/business-objectives/?pagination[page]=${page}&pagination[pageSize]=${pageSize}&publicationState=preview&filters[name][$containsi]=${name}&sort=updatedAt:asc`
    }
    if(publishedAt==="published"){
      URL = `${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/business-objectives/?pagination[page]=${page}&pagination[pageSize]=${pageSize}&publicationState=preview&filters[publishedAt][$notNull]=true&sort=updatedAt:asc`;
   }
    if(publishedAt==="unpublished"){
      URL = `${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/business-objectives/?pagination[page]=${page}&pagination[pageSize]=${pageSize}&publicationState=preview&filters[publishedAt][$null]=true&sort=updatedAt:asc`;
   }
    const response = await fetch(`${URL}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
   
    return response;
  },

  async updateBuisnessObjective(payload) {
    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/business-objectives/${payload.id}?populate=*`, {
      method: 'PUT',
      body: JSON.stringify(payload.body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
    
    return response;
  },

  async deleteBuisnessObjective(payload) {
    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/business-objectives/${payload.id}?populate=*`, {
      method: 'PUT',
      body: JSON.stringify(payload.body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
   
    return response;
  },

  async addBuisnessObjective(payload) {
    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/business-objectives/?populate=*`, {
      method: 'POST',
      body: JSON.stringify(payload.body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
   
    return response;
  },
  /* ends here */


  /* category */
  async getCategories(payload) {
    await AuthToken.getCurrentAuth();
    const searchKey = payload?.searchKey || ''

    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/categories/?pagination[page]=1&pagination[pageSize]=50&filters[name][$containsi]=${searchKey}&populate=*`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
    // console.log(response)
    return response;
  },
 
  async getAllCategories(payload) {
    await AuthToken.getCurrentAuth();
    const page = payload.page || 1;
    const pageSize = payload.pageSize || 50;
    const name = payload?.filters?.name || "";
    const publishedAt = payload?.filters?.publishedAt || "";
    let URL = `${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/categories/?populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}&publicationState=preview&sort=updatedAt:asc`


    if(name){
      URL = `${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/categories/?populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}&publicationState=preview&filters[name][$containsi]=${name}&sort=updatedAt:asc`
    }
    if(publishedAt==="published"){
      URL = `${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/categories/?populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}&publicationState=preview&filters[publishedAt][$notNull]=true&sort=updatedAt:asc`;
   }
   if(publishedAt==="unpublished"){
    URL = `${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/categories/?populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}&publicationState=preview&filters[publishedAt][$null]=true&sort=updatedAt:asc`;
 }

    const response = await fetch(`${URL}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
    
    return response;
  },

  async updateCategory(payload) {
    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/categories/${payload.id}?populate=*`, {
      method: 'PUT',
      body: JSON.stringify(payload.body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
    
    return response;
  },

  async deleteCategory(payload) {
    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/categories/${payload.id}?populate=*`, {
      method: 'PUT',
      body: JSON.stringify(payload.body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
    
    return response;
  },

  async addCategory(payload) {
    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/categories/?populate=*`, {
      method: 'POST',
      body: JSON.stringify(payload.body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
    
    return response;
  },
  /* ends here */



  /* sub categories */
  async getSubCategories(payload) {
    await AuthToken.getCurrentAuth();
    const searchKey = payload?.searchKey || ''
    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/sub-categories/?pagination[page]=1&pagination[pageSize]=50&filters[name][$containsi]=${searchKey}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
    // console.log(response)
    return response;
  },
  

  async getAllSubCategories(payload) {
    await AuthToken.getCurrentAuth();
    const page = payload.page || 1;
    const pageSize = payload.pageSize || 50;
    const name = payload?.filters?.name || "";
    const publishedAt = payload?.filters?.publishedAt || "";
    let URL = `${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/sub-categories/?populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}&publicationState=preview&sort=updatedAt:asc`

    if(name){
      URL =  `${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/sub-categories/?populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}&publicationState=preview&filters[name][$containsi]=${name}&sort=updatedAt:asc`
    }
    if(publishedAt==="published"){
      URL = `${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/sub-categories/?populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}&publicationState=preview&filters[publishedAt][$notNull]=true&sort=updatedAt:asc`;
   }
   if(publishedAt==="unpublished"){
    URL = `${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/sub-categories/?populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}&publicationState=preview&filters[publishedAt][$null]=true&sort=updatedAt:asc`;
 }

    const response = await fetch(`${URL}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
    
    return response;
  },

  async updateSubCategory(payload) {
    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/sub-categories/${payload.id}?populate=*`, {
      method: 'PUT',
      body: JSON.stringify(payload.body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
    
    return response;
  },

  async deleteSubCategory(payload) {
    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/sub-categories/${payload.id}?populate=*`, {
      method: 'PUT',
      body: JSON.stringify(payload.body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
    
    return response;
  },

  async addSubCategory(payload) {
    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/sub-categories/?populate=*`, {
      method: 'POST',
      body: JSON.stringify(payload.body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
    
    return response;
  },
  /* ends here */


  /* Client */

  async getClients(payload) {
    await AuthToken.getCurrentAuth();
    const searchKey = payload?.searchKey || ''
    const page = 1;
    const pageSize = 200;
    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/clients/?pagination[page]=1&pagination[pageSize]=50&filters[name][$containsi]=${searchKey}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
    
    return response;
  },

  async getAllClients(payload) {
    await AuthToken.getCurrentAuth();
    const page = payload.page || 1;
    const pageSize = payload.pageSize || 50;
    const name = payload?.filters?.name || "";
    const publishedAt = payload?.filters?.publishedAt || "";

    let URL = `${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/clients/?pagination[page]=${page}&pagination[pageSize]=${pageSize}&publicationState=preview&sort=updatedAt:asc`

    if(name){
      URL = `${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/clients/?pagination[page]=${page}&pagination[pageSize]=${pageSize}&publicationState=preview&filters[name][$containsi]=${name}&sort=updatedAt:asc`
    }
    if(publishedAt==="published"){
      URL = `${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/clients/?pagination[page]=${page}&pagination[pageSize]=${pageSize}&publicationState=preview&filters[publishedAt][$notNull]=true&sort=updatedAt:asc`;
   }
   if(publishedAt==="unpublished"){
    URL = `${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/clients/?pagination[page]=${page}&pagination[pageSize]=${pageSize}&publicationState=preview&filters[publishedAt][$null]=true&sort=updatedAt:asc`;
 }
    const response = await fetch(`${URL}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
    
    return response;
  },

  async getAllStrapiProducts(payload) {
    await AuthToken.getCurrentAuth();
    const page = payload.page || 1;
    const pageSize = payload.pageSize || 50;
    const name = payload?.filters?.name || "";
    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/products/?pagination[page]=${27}&pagination[pageSize]=${pageSize}&sort=id:asc&publicationState=preview`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
    
    return response;
  },
  async updateClient(payload) {
    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/clients/${payload.id}?populate=*`, {
      method: 'PUT',
      body: JSON.stringify(payload.body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
    
    return response;
  },

  async deleteClient(payload) {
    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/clients/${payload.id}?populate=*`, {
      method: 'PUT',
      body: JSON.stringify(payload.body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
    
    return response;
  },

  async addClient(payload) {
    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/clients/?populate=*`, {
      method: 'POST',
      body: JSON.stringify(payload.body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
    
    return response;
  },
  /* ends here */

    /* Client */

    async getHCMS(payload) {
      await AuthToken.getCurrentAuth();
      const searchKey = payload?.searchKey || ''
      const page = 1;
      const pageSize = 200;
      const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/hcm-integrations/?pagination[page]=1&pagination[pageSize]=50&filters[name][$containsi]=${searchKey}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
        }
      }).then(v => v.json());
      
      return response;
    },
  
    async getAllHCM(payload) {
      await AuthToken.getCurrentAuth();
      const page = payload.page || 1;
      const pageSize = payload.pageSize || 50;
      const name = payload?.filters?.name || "";
      const publishedAt = payload?.filters?.publishedAt || "";

      let URL =  `${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/hcm-integrations/?pagination[page]=${page}&pagination[pageSize]=${pageSize}&publicationState=preview&sort=updatedAt:asc`;

      if(name){
        URL = `${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/hcm-integrations/?pagination[page]=${page}&pagination[pageSize]=${pageSize}&publicationState=preview&filters[name][$containsi]=${name}&sort=updatedAt:asc`
      }
      if(publishedAt==="published"){
        URL = `${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/hcm-integrations/?pagination[page]=${page}&pagination[pageSize]=${pageSize}&publicationState=preview&filters[publishedAt][$notNull]=true&sort=updatedAt:asc`;
     }
     if(publishedAt==="unpublished"){
      URL = `${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/hcm-integrations/?pagination[page]=${page}&pagination[pageSize]=${pageSize}&publicationState=preview&filters[publishedAt][$null]=true&sort=updatedAt:asc`;
   }
      const response = await fetch(`${URL}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
        }
      }).then(v => v.json());
      
      return response;
    },
    async updateHCM(payload) {
      const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/hcm-integrations/${payload.id}?populate=*`, {
        method: 'PUT',
        body: JSON.stringify(payload.body),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
        }
      }).then(v => v.json());
      
      return response;
    },
  
    async deleteHCM(payload) {
      const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/hcm-integrations/${payload.id}?populate=*`, {
        method: 'PUT',
        body: JSON.stringify(payload.body),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
        }
      }).then(v => v.json());
      
      return response;
    },
  
    async addHCM(payload) {
      const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/hcm-integrations/?populate=*`, {
        method: 'POST',
        body: JSON.stringify(payload.body),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
        }
      }).then(v => v.json());
      
      return response;
    },
    /* ends here */

  /* company */
  async getAllCompanies(payload) {
    await AuthToken.getCurrentAuth();
    const searchKey = payload?.searchKey || ''
    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/companies/?pagination[page]=1&pagination[pageSize]=50&filters[name][$containsi]=${searchKey}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
    console.log(response)
    return response;
  },
  
  async getAllCompany(payload) {
    await AuthToken.getCurrentAuth();
    const page = payload.page || 1;
    const pageSize = payload.pageSize || 50;
    const name = payload?.filters?.name || "";
    const publishedAt = payload?.filters?.publishedAt || "";

    let URL = `${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/companies/?pagination[page]=${page}&pagination[pageSize]=${pageSize}&publicationState=preview&sort=updatedAt:asc`

    if(name){
      URL = `${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/companies/?pagination[page]=${page}&pagination[pageSize]=${pageSize}&publicationState=preview&filters[name][$containsi]=${name}&sort=updatedAt:asc`
    }
    if(publishedAt==="published"){
      URL = `${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/companies/?pagination[page]=${page}&pagination[pageSize]=${pageSize}&publicationState=preview&filters[publishedAt][$notNull]=true&sort=updatedAt:asc`;
   }
   if(publishedAt==="unpublished"){
    URL = `${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/companies/?pagination[page]=${page}&pagination[pageSize]=${pageSize}&publicationState=preview&filters[publishedAt][$null]=true&sort=updatedAt:asc`;
    }
    const response = await fetch(`${URL}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
    
    return response;
  },

  async updateCompany(payload) {
    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/companies/${payload.id}?populate=*`, {
      method: 'PUT',
      body: JSON.stringify(payload.body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
    
    return response;
  },

  async deleteCompany(payload) {
    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/companies/${payload.id}?populate=*`, {
      method: 'PUT',
      body: JSON.stringify(payload.body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
    
    return response;
  },

  async addCompany(payload) {
    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/companies/?populate=*`, {
      method: 'POST',
      body: JSON.stringify(payload.body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
    
    return response;
  },
  /* ends here */

  /* searcher product */
  async getProduct(id) {
    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/products/${id}?populate=*`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
    console.log(response)
    return response;
  },

  async getAllProducts() {
    await AuthToken.getCurrentAuth();
    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/products/?populate=*&pagination[page]=1&pagination[pageSize]=50`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
    
    return response;
  },


  async getAllProductsBySearch(payload) {
    await AuthToken.getCurrentAuth();
    const searchKey = payload?.searchKey || ''
    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/products/?pagination[page]=1&pagination[pageSize]=50&filters[Name][$containsi]=${searchKey}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
    
    return response;
  },


  async addProduct(payload) {
    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/products/?populate=*`, {
      method: 'POST',
      body: JSON.stringify(payload.body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
    console.log(response)
    return response;
  },

  async updateProduct(payload) {
    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/products/${payload.id}?populate=*`, {
      method: 'PUT',
      body: JSON.stringify(payload.body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
    console.log(response)
    return response;
  },
  /* ends here */

};
