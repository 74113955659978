import React, { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import { Link, useSearchParams } from "react-router-dom";
import { useFormik, FieldArray, Field } from "formik";
import Table from "../../../../components/tables/table";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Select from "react-select";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { searcherProductStepOneSchema } from "../../../../schemas";
import { Country, State, City } from "country-state-city";
import SearcherMasterServices from "../../../../ApiServicesSearcher/MasterServices";
import { toast } from "react-toast";
import MasterServices from "../../../../ApiServicesSearcher/MasterServices";

const Step1 = ({
  tabId,
  setTabId,
  setAllStepsData,
  allStepsData,
  editProductData,
  setLoading,
}) => {
  const [initialValues, setInitialValues] = useState({
    product_name: "",
    product_company_type: "",
    product_tagline: "",
    product_parent_company: "",
    product_country: "",
    product_headquarter: "",
    product_state: "",
    product_launch_date: "",
    product_youtube_link: "",
    product_problem_statement: "",
    product_tags: "",
    product_categories: "",
    product_sub_categories: "",
    product_headline: "",
    product_description: "",
    addFeatureItem: [{ feature_name: "" }],
    addHightlightItem: [{ highlight_header: "", highlight_description: "" }],
    enterprise: [],
  });
  const [productCompanyType, setProductCompanyType] = useState(null);
  const [catFlag, setCatFlag] = useState(false);
  const [productCountry, setProductCountry] = useState(null);
  const [productState, setProductState] = useState(null);
  const [problemStatement, setProblemStatement] = useState(null);
  const [tagValue, setTagValue] = useState(null);
  const [tagData, setTagData] = useState([]);
  const [productProblemStatement, setProductProblemStatement] = useState(null);
  const [productSegmentList, setProductSegmentList] = useState([]);
  const [productCategory, setProductCategory] = useState([]);
  let [productSubCategory, setProductSubCategory] = useState([]);
  const [companyOptions, setCompanyOptions] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState(null);
  let [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [fakeCategory, setFakeCategory] = useState([])

  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);


  const [productSegmentOptions, setproductSegmentOptions] = useState([{
    value: 'mid_enterprise', label: 'Mid Enterprise',
  },
  { value: 'large_enterprise', label: 'Large Enterprise' },
  { value: 'small_enterprise', label: 'Small Enterprise' }
  ])

  useEffect(() => {
    setTimeout(() => {
      if (editProductData) {
        console.log("editProductData", editProductData?.categories?.data);
        setProductCompanyType({
          value: editProductData?.company?.data?.id,
          label: editProductData?.company?.data?.attributes?.name,
        });
        // setProductCategory(
        //   editProductData?.categories?.data?.map((v) => (
        //     {
        //     value: v?.id,
        //     label: v?.attributes?.name,
        //   }))
        // );

        setFakeCategory(
          editProductData?.categories?.data?.map((v) => ({
            value: v?.id,
            label: v?.attributes?.name,
          })));
        console.log("editProductData", editProductData?.sub_categories?.data)
        setProductSubCategory(
          editProductData?.sub_categories?.data?.map((v) => ({
            value: v?.id,
            label: v?.attributes?.name,
          }))
        );
        setProductProblemStatement(
          editProductData?.business_objectives?.data.map((v) => ({
            value: v.id,
            label: v?.attributes?.name,
          }))
        );
        setTagValue(
          editProductData?.tags?.data.map((v) => ({
            value: v.id,
            label: v?.attributes?.name,
          }))
        );

        if (editProductData?.is_global_enterprise) {
          setProductSegmentList(v => {
            return [...v, { value: 'mid_enterprise', label: 'Mid Enterprise' }]
          })
        }
        if (editProductData?.is_large_enterprise) {
          setProductSegmentList(v => {
            return [...v, { value: 'large_enterprise', label: 'Large Enterprise' }]
          })
        }
        if (editProductData?.is_small_enterprise) {
          setProductSegmentList(v => {
            return [...v, { value: 'small_enterprise', label: 'Small Enterprise' }]
          })
        }
        console.log(
          editProductData?.headquarter?.split("/")[0],
          "headqauter....."
        );
        console.log(
          countryOptions,
          "counthd..................",
          editProductData?.headquarter?.split("/")[0]
        );
        console.log(
          countryOptions.filter(
            (v) => v.label == editProductData?.headquarter?.split("/")[0]
          ),
          "skdffsldfjdfl"
        );
        let country = countryOptions.filter(
          (v) => v.label == editProductData?.headquarter?.split("/")[0]
        );
        let state = stateOptions.filter(
          (v) => v.label == editProductData?.headquarter?.split("/")[1]
        );
        setProductCountry(country.length > 0 ? country[0] : null);
        setProductState(state.length > 0 ? state[0] : null);
        setInitialValues({
          product_name: editProductData?.Name,
          product_company_type: editProductData?.company?.data?.id,
          product_tagline: editProductData?.Short_description,
          product_parent_company: editProductData?.headquarter,
          product_country: country.length > 0 ? country[0].label : "",
          product_state: state.length > 0 ? state[0].label : "",
          product_headquarter: editProductData?.headquarter,
          product_launch_date: editProductData?.launch_date,
          product_youtube_link:
            Array.isArray(editProductData?.thumbnail_videos) &&
              editProductData?.thumbnail_videos.length > 0 &&
              editProductData?.thumbnail_videos[0].thumbnail_url
              ? editProductData?.thumbnail_videos[0].thumbnail_url
              : "",
          product_problem_statement:
            editProductData?.business_objectives?.data.map((v) => ({
              value: v.id,
              label: v?.attributes?.name,
            })),
          product_tags: editProductData?.tags?.data.map((v) => ({
            value: v?.id,
            label: v?.attributes?.name,
          })),
          product_categories: editProductData?.categories?.data.map((v) => ({
            value: v?.id,
            label: v?.attributes?.name,
          })),
          product_sub_categories: editProductData?.sub_categories?.data.map(
            (v) => ({ value: v?.id, label: v?.attributes?.name })
          ),
          product_headline: editProductData?.Headline,
          product_description: editProductData?.description,
          addFeatureItem: (Array.isArray(editProductData?.features) &&
            editProductData?.features.map((v) => ({
              feature_name: v.description,
            }))) || [{ feature_name: "" }],
          addHightlightItem: Array.isArray(editProductData?.highlights)
            ? editProductData?.highlights.map((v) => ({
              highlight_header: v.name,
              highlight_description: v.description,
            }))
            : [],
          // enterprise: editProductData?.is_global_enterprise
          //   ? "mid_enterprise"
          //   : editProductData?.is_large_enterprise
          //   ? "large_enterprise"
          //   : editProductData?.is_small_enterprise
          //   ? "small_enterprise"
          //   : "",
          enterprise: productSegmentList,
          product_is_spotlight: editProductData.is_spotlight,
          is_weightage: editProductData.is_weightage,
        });
      }
    }, 1000);
  }, [editProductData]);



  const getAllCompanies = async (e) => {
    const payload = { searchKey: e || "" };
    await SearcherMasterServices.getAllCompanies(payload)
      .then((res) => {
        let { data, status, meta } = res;
        console.log(data);
        if (Array.isArray(data) && data.length > 0) {
          setCompanyOptions(() => {
            data = data.map((v) => ({ label: v.attributes.name, value: v.id }));
            data?.unshift({ label: "Select", value: null });
            return data;
          });
        }
      })
      .catch((err) => {
        toast.error(err.data?.message);
        console.log(err);
      });
  };

  const getAllCategories = async (e) => {
    const payload = { searchKey: e || "" };
    await SearcherMasterServices.getCategories(payload)
      .then((res) => {
        const { data, status, meta } = res;
        console.log(data);
        if (Array.isArray(data) && data.length > 0) {
          setCategoryOptions(
            data.map((v) => ({ label: v.attributes.name, value: v.id, ...v }))
          );
        }
      })
      .catch((err) => {
        toast.error(err.data?.message);
        console.log(err);
      });
  };



  const getAllSubCategories = async (e) => {
    const payload = { searchKey: e || "" };
    await SearcherMasterServices.getSubCategories(payload)
      .then((res) => {
        const { data, status, meta } = res;
        console.log(data);
        // if (Array.isArray(data) && data.length > 0) {
        //   setFakeSubCategoryOptions(
        //     data.map((v) => ({ label: v.attributes.name, value: v.id }))
        //   );
        // }
      })
      .catch((err) => {
        toast.error(err.data?.message);
        console.log(err);
      });
  };

  const getBuisnessObjective = async (e) => {
    const payload = { searchKey: e || "" };
    await SearcherMasterServices.getBuisnessObjectives(payload)
      .then((res) => {
        const { data, status, meta } = res;
        console.log(data);
        if (Array.isArray(data) && data.length > 0) {
          setProblemStatement(
            data.map((v) => ({ label: v.attributes.name, value: v.id }))
          );
        }
      })
      .catch((err) => {
        toast.error(err.data?.message);
        console.log(err);
      });
  };

  const [addFeatureItem, setFeatureItem] = useState([
    {
      id: 1,
      feature: "",
    },
    {
      id: 2,
      feature: "",
    },
    {
      id: 3,
      feature: "",
    },
  ]);

  const [addHightlight, setAddHightlight] = useState([
    {
      id: 1,
      header: "",
      description: "",
    },
  ]);

  const getAllTagData = async (e) => {
    const payload = { searchKey: e || "" };
    const response = await MasterServices.getTagsData(payload);
    console.log("response", response);
    if (response?.data?.length > 0) {
      const data = response?.data?.map((ele, index) => {
        return {
          label: ele?.attributes?.name,
          value: ele.id,
        };
      });
      setTagData(data);
    }
  };

  const stepperOptions = [
    {
      id: 0,
      title: "Product Overview",
    },
    {
      id: 1,
      title: "Images & Media",
    },
    {
      id: 2,
      title: "Product Demo",
    },
    {
      id: 3,
      title: "Makers & Team",
    },
    {
      id: 4,
      title: "Review & Submit",
    },
  ];

  const addFeatureBtn = () => {
    setFeatureItem([
      ...addFeatureItem,
      {
        id: addFeatureItem.length + 1,
        feature: "",
      },
    ]);
  };
  const removeFeatureBtn = (item) => {
    setFeatureItem((prev) => prev.filter((items) => items.id !== item.id));
  };
  const addHightlightBtn = () => {
    setAddHightlight([
      ...addHightlight,
      {
        id: addHightlight.length + 1,
        header: "",
        description: "",
      },
    ]);
  };

  const removeHightlightBtn = (item) => {
    setAddHightlight((prev) => prev.filter((items) => items.id !== item.id));
  };

  const handleNextButton = () => {
    setTabId((prev) => {
      const maxIndex = stepperOptions.length - 1;
      return prev < maxIndex ? prev + 1 : prev;
    });
  };

  const handlePrevButton = () => {
    setTabId((prev) => {
      const maxIndex = stepperOptions.length - 1;
      return prev <= maxIndex ? prev - 1 : prev;
    });
  };

  // Generate a range of years (from 1900 to 2100)
  const years = [];
  for (let year = 1900; year <= 2100; year++) {
    years.push(year);
  }

  useEffect(() => {
    const fetchData = async () => {
      await getAllCompanies();
      await getAllCategories();
      await getAllSubCategories();
      await getBuisnessObjective();
      await getAllTagData();

      setLoading(false);
      await setCountryOptions(
        Country.getAllCountries().map((v) => ({
          label: v.name,
          value: v.isoCode,
        }))
      );
    };

    fetchData();
  }, []);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    // validationSchema: searcherProductStepOneSchema,
    onSubmit: async (values, action) => {
      setAllStepsData({ ...values, enterprise: productSegmentList });
      handleNextButton();
    },
  });

  useEffect(() => {
    setProductCountry(
      countryOptions.filter(
        (v) => v.label == editProductData?.headquarter?.split("/")[0]
      ).length > 0
        ? countryOptions.filter(
          (v) => v.label == editProductData?.headquarter?.split("/")[0]
        )[0]
        : null
    );
  }, [countryOptions]);

  useEffect(() => {
    setStateOptions(
      State.getStatesOfCountry(productCountry?.value).map((v) => ({
        label: v.name,
        value: v.isoCode,
      }))
    );
  }, [productCountry]);
  useEffect(() => {
    setProductState(
      stateOptions.filter(
        (v) => v.label == editProductData?.headquarter?.split("/")[1]
      ).length > 0
        ? stateOptions.filter(
          (v) => v.label == editProductData?.headquarter?.split("/")[1]
        )[0]
        : null
    );
    let state =
      stateOptions.filter(
        (v) => v.label == editProductData?.headquarter?.split("/")[1]
      ).length > 0
        ? stateOptions.filter(
          (v) => v.label == editProductData?.headquarter?.split("/")[1]
        )[0]
        : "";
    setFieldValue("product_state", state?.label);
  }, [stateOptions]);


  //new one
  // useEffect(() => {

  //   if (fakeCategory?.length > 0) {

  //     const filter = fakeCategory && fakeCategory?.flatMap((ele, index) => {
  //       // console.log("fakeCategory",ele,categoryOptions)
  //       return categoryOptions?.length > 0 && categoryOptions?.filter((item) => item?.value === ele?.value)
  //     })

  //     setProductCategory(filter)
  //   }

  // }, [categoryOptions])

  // useEffect(() => {
  //   setSubCategoryOptions([])

  //   console.log("prod", productCategory, "fakeCategory", fakeCategory)
  //   if (productCategory?.length > 0) {
  //     let subCategoryOptionList = [];
  //     productCategory?.forEach(item => {
  //       const dd = (item?.attributes?.sub_categories?.data || []).flatMap(v => ({ label: v?.attributes?.name, value: v?.id }));
  //       subCategoryOptionList.push(...dd);
  //     });
  //     console.log("subCategoryOptionList=>", subCategoryOptionList)
  //     setSubCategoryOptions(subCategoryOptionList)

  //   }
  // }, [productCategory])



  // useEffect(() => {
  //   if (!productSubCategory) {
  //     setProductSubCategory([]);
  //     return;
  //   }

  //   let subCatList = editProductData?.sub_categories?.data?.map((v) => ({
  //     value: v?.id,
  //     label: v?.attributes?.name,
  //   }));
  //   if (productCategory?.length <= 0 || !catFlag) {
  //     subCatList = [];
  //   }
  //   console.log("catFlag=>",catFlag)

  //   const updatedSubCategoryValues = !catFlag ? [] : subCatList?.length > 0 ? subCatList : productSubCategory?.filter(obj1 =>
  //     subCategoryOptions?.some(obj2 => obj1?.value === obj2?.value)
  //   );

  //   setProductSubCategory(updatedSubCategoryValues);
  // }, [productCategory, subCategoryOptions]);

  // useEffect(() => {
  //   console.log("productSubCategory=>", productSubCategory)
  // }, [productSubCategory]);

  useEffect(() => {

    if (fakeCategory.length > 0) {
      const filter = fakeCategory && fakeCategory?.flatMap((ele, index) => {
        // console.log("fakeCategory",ele,categoryOptions)
        return categoryOptions?.length > 0 && categoryOptions?.filter((item) => item?.value === ele?.value)
      })

      setProductCategory(filter)
    }
  }, [fakeCategory.length > 0, categoryOptions])

  useEffect(() => {
    setSubCategoryOptions([])

    if (productCategory?.length > 0) {
      let subCategoryOptionList = [];
      productCategory?.forEach(item => {
        const dd = (item?.attributes?.sub_categories?.data || []).flatMap(v => ({ label: v?.attributes?.name, value: v?.id, categoryID: item?.id }));
        subCategoryOptionList.push(...dd);
      });
      setSubCategoryOptions(subCategoryOptionList)

      let filteredSubCategoryOptionList = subCategoryOptionList.filter(item => {
        return productSubCategory.some(ele => ele?.value === item?.value);
      });

      if (filteredSubCategoryOptionList?.length > 0) {
        setProductSubCategory(filteredSubCategoryOptionList)
      } else {
        setProductSubCategory(productSubCategory)
      }

    }

    if (productCategory?.length <= 0) {

      setProductSubCategory([])
      setSubCategoryOptions([])
    }

  }, [productCategory])


  


  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();

          handleSubmit(e);
        }}
        className=""
      >
        <div className={`flex ${tabId === 0 ? "flex-col gap-y-5" : "hidden"}`}>
          <div className="flex flex-col gap-y-3">
            <div className=" space-y-2 pb-4">
              <h1 className="sm:text-lg font-medium">
                Tell us more about this product
              </h1>
              <p className="sm:text-sm text-gray-500 ">
                we'll need the Name, Taglines, Links, Categories & Description
              </p>
            </div>
            <div className="grid sm:grid-cols-1 md:grid-cols-2  lg:grid-cols-4 gap-x-5 gap-y-5">
              <div>
                <label className="flex  text-sm font-medium leading-6 text-gray-900">
                  Product Name{" "}
                  <div className="ml-auto ">
                    <span className={errors.product_name ? "text-red-500" : ""}>
                      {values.product_name?.length}
                    </span>
                    /50
                  </div>
                </label>
                <div className="">
                  <input
                    value={values.product_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    name="product_name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Eg: People Matters"
                  />
                  {errors.product_name && touched.product_name ? (
                    <p className="text-red-600 text-sm">
                      {errors.product_name}
                    </p>
                  ) : null}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Company Type
                </label>
                <div className="">
                  <Select
                    className="basic-single sm:text-sm "
                    classNamePrefix="select"
                    defaultValue={{ label: "Select", value: "Select" }}
                    placeholder="Select"
                    name="product_company_type"
                    options={companyOptions}
                    value={productCompanyType}
                    onInputChange={(e) => {
                      if (e != "") {
                        getAllCompanies(e);
                      }
                    }}
                    onChange={(e) => {
                      values.product_company_type = e.value;
                      setProductCompanyType(e);
                    }}
                  />
                  {!values.product_company_type ? (
                    <p className="text-red-600 text-sm">
                      {errors.product_company_type}
                    </p>
                  ) : null}
                </div>
              </div>
              <div>
                <label className="flex block text-sm font-medium leading-6 text-gray-900">
                  Product Tagline{" "}
                  <div className="ml-auto ">
                    <span
                      className={errors.product_tagline ? "text-red-500" : ""}
                    >
                      {values.product_tagline?.length}
                    </span>
                    /100
                  </div>
                </label>
                <div className="">
                  <input
                    value={values.product_tagline}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    name="product_tagline"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="product_tagline"
                  />
                  {errors.product_tagline && touched.product_tagline ? (
                    <p className="text-red-600 text-sm">
                      {errors.product_tagline}
                    </p>
                  ) : null}
                </div>
              </div>
              <div>
                <label className="flex block text-sm font-medium leading-6 text-gray-900">
                  Headquater{" "}
                  <div className="ml-auto ">
                    <span
                      className={
                        errors.product_headquarter ? "text-red-500" : ""
                      }
                    >
                      {values.product_headquarter?.length}
                    </span>
                    /50
                  </div>
                </label>
                <div className="">
                  <input
                    value={values.product_headquarter}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    name="product_headquarter"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="product_headquarter"
                  />
                  {errors.product_headquarter && touched.product_headquarter ? (
                    <p className="text-red-600 text-sm">
                      {errors.product_headquarter}
                    </p>
                  ) : null}
                </div>
              </div>
              {/* <div>
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Country
                </label>
                <div className="flex flex-col  gap-x-2">
                  <Select
                    className="basic-single w-full sm:text-sm"
                    classNamePrefix="select"
                    defaultValue="Select"
                    placeholder="Country"
                    name="product_country"
                    value={productCountry}
                    options={countryOptions}
                    onChange={(e) => {
                      values.product_country = e.label;
                      setProductCountry(e);
                      setStateOptions(
                        State.getStatesOfCountry(e.value).map((v) => ({
                          label: v.name,
                          value: v.isoCode,
                        }))
                      );
                    }}
                  />
                  {!values.product_country ? (
                    <p className="text-red-600 text-sm">
                      {errors.product_country}
                    </p>
                  ) : null}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  State
                </label>
                <div className="flex flex-col gap-x-2">
                  <Select
                    isDisabled={!productCountry?.value}
                    className="basic-single w-full sm:text-sm"
                    classNamePrefix="select"
                    defaultValue="Select"
                    placeholder="State"
                    name="product_state"
                    value={productState}
                    options={stateOptions}
                    onChange={(e) => {
                      values.product_state = e.label;
                      setProductState(e);
                    }}
                  />
                  {!values.product_state ? (
                    <p className="text-red-600 text-sm">
                      {errors.product_state}
                    </p>
                  ) : null}
                </div>
              </div> */}
              <div>
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Launch Date
                </label>
                <div className="">
                  <select
                    value={values.product_launch_date}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="product_launch_date"
                    className="block w-full rounded-md border-0 py-1.5 pl-3 pr-8 sm:text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                  >
                    <option value="">Select Year</option>
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                    {/* Add more options as needed */}
                  </select>
                  {errors.product_launch_date && touched.product_launch_date ? (
                    <p className="text-red-600 text-sm">
                      {errors.product_launch_date}
                    </p>
                  ) : null}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Segments catered to
                </label>
                <div>


                  <div className="">
                    <Select
                      isMulti={true}
                      className="basic-single sm:text-sm"
                      classNamePrefix="select"
                      defaultValue="Select"
                      placeholder="Select"
                      name="enterprise"
                      options={productSegmentOptions}
                      value={productSegmentList}
                      onChange={(e) => {
                        // values.enterprise = e;
                        setProductSegmentList(e);
                        // console.log("pjsdfsh", e)
                      }}
                    />
                    {/* {!values.enterprise ? (
                      <p className="text-red-600 text-sm">
                        {errors.enterprise}
                      </p>
                    ) : null} */}
                  </div>

                </div>
              </div>
            </div>
          </div>
          <hr class="h-px my-3 bg-gray-300 border-0 dark:bg-gray-700"></hr>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-5 items-start">
            <div className="flex flex-col gap-y-3 w-full">
              <h1 className="sm:text-lg font-medium">Links</h1>
              <div>
                <label className="flex block text-sm font-medium leading-6 text-gray-900">
                  Link to youtube <div className="ml-auto">Optional</div>
                </label>
                <div className="">
                  <input
                    value={values.product_youtube_link}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    name="product_youtube_link"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="https://"
                  />
                  {/* {errors.product_youtube_link &&
                  touched.product_youtube_link ? (
                    <p className="text-red-600 text-sm">
                      {errors.product_youtube_link}
                    </p>
                  ) : null} */}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-3 w-full">
              <h1 className="sm:text-lg font-medium">Problem Statement </h1>
              <div>
                <label className="flex block text-sm font-medium leading-6 text-gray-900">
                  Select <div className="ml-auto">Optional</div>
                </label>
                <div className="">
                  <Select
                    isMulti={true}
                    className="basic-single sm:text-sm"
                    classNamePrefix="select"
                    defaultValue="Select"
                    placeholder="Select"
                    name="product_problem_statement"
                    options={problemStatement}
                    value={productProblemStatement}
                    onInputChange={(e) => {
                      if (e != "") {
                        getBuisnessObjective(e);
                      }
                    }}
                    onChange={(e) => {
                      values.product_problem_statement = e;
                      setProductProblemStatement(e);
                    }}
                  />
                  {!values.product_problem_statement ? (
                    <p className="text-red-600 text-sm">
                      {errors.product_problem_statement}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-3 w-full">
              <h1 className="sm:text-lg font-medium">Tags</h1>
              <div>
                <label className="flex block text-sm font-medium leading-6 text-gray-900">
                  Select <div className="ml-auto">Optional</div>
                </label>
                <div className="">
                  <Select
                    isMulti={true}
                    className="basic-single sm:text-sm"
                    classNamePrefix="select"
                    defaultValue="Select"
                    placeholder="Select"
                    name="product_tags"
                    onInputChange={(e) => {
                      if (e != "") {
                        getAllTagData(e);
                      }
                    }}
                    options={tagData}
                    value={tagValue}
                    onChange={(e) => {
                      values.product_tags = e;
                      setTagValue(e);
                    }}
                  />
                  {!values.product_tags ? (
                    <p className="text-red-600 text-sm">
                      {errors.product_tags}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <hr class="h-px my-3 bg-gray-300 border-0 dark:bg-gray-700"></hr>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 items-start gap-x-5 gap-y-5">
            <div className="flex flex-col gap-y-3 w-full">
              <h1 className="sm:text-lg font-medium">Categories</h1>
              <div>
                <label className="block text-sm font-medium leading-6 text-gray-900 whitespace-nowrap">
                  Select upto 3 categories
                </label>
                <div className="">
                  <Select
                    isMulti={true}
                    className="basic-single sm:text-sm w-full"
                    classNamePrefix="select"
                    defaultValue="Select"
                    placeholder="Select"
                    name="product_categories"
                    onInputChange={(e) => {
                      if (e != "") {
                        getAllCategories(e);
                      }
                    }}
                    options={categoryOptions ? categoryOptions : []}
                    value={productCategory}
                    onChange={(e) => {
                      values.product_categories = e;
                      setSubCategoryOptions([])
                      setProductCategory(e);

                      // let catflag = false;
                      // catflag = values?.product_categories?.length <= 0 ? false : true;
                      // console.log("kdjjjjjjje catflag", catflag);
                      // setCatFlag(catflag)
                      // // setSubCategoryOptions([])
                      // setProductCategory(e);

                    }}
                  />
                  {!values.product_categories ||
                    values.product_categories.length == 0 ? (
                    <p className="text-red-600 text-sm">
                      {errors.product_categories}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-3 w-full">
              <h1 className="sm:text-lg font-medium">Subcategories</h1>
              <div>
                <label className="block text-sm font-medium leading-6 text-gray-900 whitespace-nowrap">
                  Select upto 3 subcategories
                </label>
                <div className="">
                  <Select
                    isMulti={true}
                    className="basic-single sm:text-sm w-full"
                    classNamePrefix="select"
                    defaultValue="Select"
                    placeholder="Select"
                    name="product_sub_categories"
                    onInputChange={(e) => {
                      if (e != "") {
                        getAllSubCategories(e);
                      }
                    }}
                    //options={subCategoryOptions?.length > 0 ? subCategoryOptions : []}
                    options={[
                      { value: "all", label: "Select All", isDisabled: false },
                      ...subCategoryOptions,
                    ]}
                    value={productSubCategory}
                    onChange={(e) => {
                      // values.product_sub_categories = e;
                      // setFieldValue("product_sub_categories", e);
                      // setProductSubCategory(e);
                      // console.log({ e, subCategoryOptions });
                      
                      if (e && e.length > 0 && e.some(option => option.value === "all")) {
                        values.product_sub_categories = subCategoryOptions;
                        setFieldValue("product_sub_categories", subCategoryOptions);
                        setProductSubCategory(subCategoryOptions);
                      } else {
                        values.product_sub_categories = e;
                        setFieldValue("product_sub_categories", e);
                        setProductSubCategory(e);
                      }

                    }}
                    isDisabled={!productCategory || productCategory?.length === 0}
                  />
                  {!values.product_sub_categories ||
                    values.product_sub_categories.length == 0 ? (
                    <p className="text-red-600 text-sm">
                      {errors.product_sub_categories}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <hr class="h-px my-3 bg-gray-300 border-0 dark:bg-gray-700"></hr>
          <div className="flex flex-col gap-y-3">
            <h1 className="sm:text-lg font-medium">Description</h1>

            <div>
              <label className="flex block  max-w-[500px]  text-sm font-medium leading-6 text-gray-900">
                Product Headline{" "}
                <div className="ml-auto ">
                  <span
                    className={errors.product_headline ? "text-red-500" : ""}
                  >
                    {values.product_headline?.length}
                  </span>
                  /100
                </div>
              </label>
              <div className="">
                <input
                  value={values.product_headline}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  name="product_headline"
                  className="block w-full max-w-[500px] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="what is the value proposition"
                />
                {errors.product_headline && touched.product_headline ? (
                  <p className="text-red-600 text-sm">
                    {errors.product_headline}
                  </p>
                ) : null}
              </div>
            </div>
            <div>
              <label className="flex block  max-w-[500px]  text-sm font-medium leading-6 text-gray-900">
                Breif Description of the product{" "}
                <div className="ml-auto ">
                  <span
                    className={errors.product_description ? "text-red-500" : ""}
                  >
                    {values.product_description?.length}
                  </span>
                  /500
                </div>
              </label>
              <div className="">
                <textarea
                  value={values.product_description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  name="product_description"
                  className="block w-full max-w-[500px] h-[100px] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="short description of product"
                />
                {errors.product_description && touched.product_description ? (
                  <p className="text-red-600 text-sm">
                    {errors.product_description}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          <hr class="h-px my-3 bg-gray-300 border-0 dark:bg-gray-700"></hr>
          <div className="flex flex-col gap-y-3">
            <div className="flex justify-between">
              <h1 className="sm:text-lg font-medium">Key Features</h1>
              {/* <button
                type="button"
                className="rounded-md bg-gradient-to-b from-[#DB7D0B] to-[#872341] px-5 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                onClick={() => {
                  setFieldValue("addFeatureItem", [
                    ...values.addFeatureItem,
                    { feature_name: "" },
                  ]);
                }}
              >
                Add
              </button> */}
            </div>
            <div className=" w-full flex gap-4  justify-between">
              <div className="grid sm:grid-cols-1 w-[90%] md:grid-cols-2 lg:grid-cols-3 gap-x-5 gap-y-5">
                {Array.isArray(values.addFeatureItem) &&
                  values.addFeatureItem.map((item, index) => {
                    return (
                      <>
                        <div
                          key={index}
                          className="flex items-center justify-center border-2 p-2 rounded-md"
                        >
                          <div className="w-full">
                            <div className=" flex justify-between">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Feature{" "}
                                <div className="ml-auto ">
                                  <span
                                    className={
                                      errors &&
                                        Array.isArray(errors?.addFeatureItem) &&
                                        errors?.addFeatureItem.length > 0 &&
                                        errors?.addFeatureItem[index]
                                          ?.feature_name
                                        ? "text-red-500"
                                        : ""
                                    }
                                  >
                                    {values &&
                                      values?.addFeatureItem.length > 0 &&
                                      values?.addFeatureItem[index]
                                        ?.feature_name?.length}
                                  </span>
                                  /100
                                </div>
                              </label>
                              <div>
                                <button
                                  type="button"
                                  // className="rounded-md bg-gradient-to-b from-[#DB7D0B] to-[#872341] px-2 py-1 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                  onClick={() =>
                                    setFieldValue(
                                      "addFeatureItem",
                                      values.addFeatureItem.filter(
                                        (_, i) => i !== index
                                      )
                                    )
                                  }
                                >
                                  <XMarkIcon className="w-5 h-5" />
                                </button>
                              </div>
                            </div>
                            <div className="flex justify-between items-center w-full gap-x-5">
                              <div className="w-full">
                                <textarea
                                  type="text"
                                  name={`addFeatureItem[${index}].feature_name`}
                                  {...getFieldProps(
                                    `addFeatureItem[${index}].feature_name`
                                  )}
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  placeholder={`feature ${index + 1}`}
                                />
                                {errors.addFeatureItem &&
                                  touched.addFeatureItem &&
                                  errors.addFeatureItem[index]
                                    ?.feature_name && (
                                    <div className="text-red-600 text-sm">
                                      {
                                        errors.addFeatureItem[index]
                                          .feature_name
                                      }
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
              <div className=" w-[10%] flex items-center border-l">
                <button
                  type="button"
                  className={`
                    rounded-md px-5 py-2 text-xl font-semibold 
                    focus:outline-none focus:ring-2 focus:ring-offset-2
                    ${values.addFeatureItem.length >= 4 ? 'text-red-500' : 'text-black'} 
                    ${values.addFeatureItem.length >= 4 ? 'cursor-not-allowed' : ''}
                  `}
                  disabled={values.addFeatureItem.length >= 4}
                  onClick={() => {
                    if (values.addFeatureItem.length < 4) {
                      setFieldValue("addFeatureItem", [
                        ...values.addFeatureItem,
                        { feature_name: "" },
                      ]);
                    }
                  }}
                >
                  {/* Add */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <hr class="h-px my-3 bg-gray-300 border-0 dark:bg-gray-700"></hr>
          <div className="flex flex-col gap-y-3">
            <div className="flex justify-between">
              <h1 className="sm:text-lg font-medium">Highlight</h1>
              {/* <button
                type="button"
                className="rounded-md bg-gradient-to-b from-[#DB7D0B] to-[#872341] px-5 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                onClick={() => {
                  setFieldValue("addHightlightItem", [
                    ...values.addHightlightItem,
                    { highlight_header: "", highlight_description: "" },
                  ]);
                }}
              >
                Add
              </button> */}
            </div>
            <div className=" w-full flex gap-4  justify-between">
              <div className=" w-[90%] flex flex-col  gap-x-5 gap-y-5">
                {Array.isArray(values.addHightlightItem) &&
                  values.addHightlightItem.map((item, index) => (
                    <div key={index} className=" w-2/3">
                      <div className="   border-2 rounded-md p-2   ">
                        <div className=" justify-end flex  md:col-span-2 lg:col-span-1">
                          <button
                            type="button"
                            // className="rounded-md bg-gradient-to-b  from-[#DB7D0B] to-[#872341] px-2 py-1 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                            onClick={() =>
                              setFieldValue(
                                "addHightlightItem",
                                values.addHightlightItem.filter(
                                  (_, i) => i !== index
                                )
                              )
                            }
                          >
                            <XMarkIcon className="w-5 h-5" />
                          </button>
                        </div>
                        <div className=" w-full flex flex-col gap-4 md:flex-row">
                          <div className="w-1/2 ">
                            <label className="flex block text-sm font-medium leading-6 text-gray-900 whitespace-nowrap">
                              Highlight Header{" "}
                              <div className="ml-auto ">
                                <span
                                  className={
                                    Array.isArray(errors?.addHightlightItem) &&
                                      errors?.addHightlightItem[index]
                                        ?.highlight_header
                                      ? "text-red-500"
                                      : ""
                                  }
                                >
                                  {
                                    values?.addHightlightItem[index]
                                      ?.highlight_header?.length
                                  }
                                </span>
                                /100
                              </div>
                            </label>
                            <div>
                              <input
                                type="text"
                                name={`addHightlightItem[${index}].highlight_header`}
                                {...getFieldProps(
                                  `addHightlightItem[${index}].highlight_header`
                                )}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder={`Header ${index + 1}`}
                              />
                              {/* {errors.addHightlightItem &&
                          touched.addHightlightItem &&
                          errors.addHightlightItem[index]?.highlight_header && (
                            <div className="text-red-600 text-sm">
                              {errors.addHightlightItem[index].highlight_header}
                            </div>
                          )} */}
                            </div>
                          </div>
                          <div className="w-1/2">
                            <label className="flex block text-sm font-medium leading-6 text-gray-900 whitespace-nowrap">
                              Highlight Description{" "}
                              <div className="ml-auto ">
                                <span
                                  className={
                                    errors[
                                      `addHightlightItem[${index}].highlight_description`
                                    ]
                                      ? "text-red-500"
                                      : ""
                                  }
                                >
                                  {
                                    values[
                                      `addHightlightItem[${index}].highlight_description`
                                    ]?.length
                                  }
                                </span>
                                /500
                              </div>
                            </label>
                            <div>
                              <input
                                type="text"
                                name={`addHightlightItem[${index}].highlight_description`}
                                {...getFieldProps(
                                  `addHightlightItem[${index}].highlight_description`
                                )}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder={`Description ${index + 1}`}
                              />
                              {/* {errors.addHightlightItem &&
                          touched.addHightlightItem &&
                          errors.addHightlightItem[index]
                            ?.highlight_description && (
                            <div className="text-red-600 text-sm">
                              {
                                errors.addHightlightItem[index]
                                  .highlight_description
                              }
                            </div>
                          )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="w-[10%] flex items-center border-l">
                <button
                  type="button"
                  className={`
                    rounded-md px-5 py-2 text-xl font-semibold 
                    focus:outline-none focus:ring-2 focus:ring-offset-2
                    ${values.addHightlightItem.length >= 4 ? 'text-red-500' : 'text-black'} 
                    ${values.addHightlightItem.length >= 4 ? 'cursor-not-allowed' : ''}
                  `}
                  disabled={values.addHightlightItem.length >= 4}
                  onClick={() => {
                    if (values.addHightlightItem.length < 4) {
                      setFieldValue("addHightlightItem", [
                        ...values.addHightlightItem,
                        { highlight_header: "", highlight_description: "" },
                      ]);
                    }
                  }}
                >
                  {/* Add */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <hr class="h-px my-3 bg-gray-300 border-0 dark:bg-gray-700"></hr>
          <div class="relative flex items-start">
            <div class="flex h-6 items-center">
              <input
                checked={values.product_is_spotlight}
                id="product_is_spotlight"
                // aria-describedby="product_is_spotlight_description"
                name="product_is_spotlight"
                type="checkbox"
                class="h-4 w-4 rounded border-gray-300 text-indigo-800 focus:ring-indigo-800"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div class="ml-3 text-sm leading-6">
              <label
                for="product_is_spotlight"
                class="font-medium text-gray-900"
              >
                In the Spotlight
              </label>
              {/* <p id="product_is_spotlight_description" class="text-gray-500">
                If checked the product will be taged as a spotlight product
              </p> */}
            </div>
          </div>
          <div class="relative flex items-start">
            <div class="flex h-6 items-center">
              <input
                checked={values.is_weightage}
                id="is_weightage"
                // aria-describedby="is_weightage_description"
                name="is_weightage"
                type="checkbox"
                class="h-4 w-4 rounded border-gray-300 text-indigo-800 focus:ring-indigo-800"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div class="ml-3 text-sm leading-6">
              <label
                for="is_weightage"
                class="font-medium text-gray-900"
              >
                IS Weightage
              </label>
              {/* <p id="is_weightage_description" class="text-gray-500">
                If checked the product will be taged as a spotlight product
              </p> */}
            </div>
          </div>
          <div className="flex justify-end mt-5">
            <>
              <button
                type="submit"
                className="rounded-md bg-gradient-to-b from-[#DB7D0B] to-[#872341] px-5 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
              >
                Next
              </button>
            </>
          </div>
        </div>
      </form>
    </>
  );
};

export default Step1;
