/* eslint-disable import/no-anonymous-default-export */
import Api from "./Api";
import { toast } from "react-toast";
import AuthToken from "./AuthTokenServices";
export default {

  async getAllModules(id) {
    await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/masters/module/?search=${id}`);
    return response;
  },

  async getAllModulesWithPermissions(role_id) {
    await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/masters/module/with-permissons?role_id=${role_id}`);
    return response;
  },

  //with-permissons
  
};
