import React, { useEffect, useState, Fragment } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import { Formik } from "formik";
import { XMarkIcon } from "@heroicons/react/24/outline";
// import ConfirmationModal from "../../../components/ConfirmationModal";
import { Link, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useFormik } from "formik";
import { productSchema } from "../../../schemas";
import SearcherMasterServices from "../../../ApiServicesSearcher/MasterServices";

import {
  prod_Modal_columns,
  product_columns,
} from "../../../components/tables/tableheader";
import Table from "../../../components/tables/table";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import { Switch } from "@headlessui/react";
import makeAnimated from "react-select/animated";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast, ToastContainer } from "react-toast";
import Select from "react-select";
import { utils, writeFile } from "xlsx";
import ConfirmationModal from "../../../components/ConfirmationModal";
import {
  getAllowedActions,
  checkPermissionsAndRedirect,
  exportToExcel,
} from "../../../helper/commonHelper";
import moment from "moment";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Product = () => {
  const pages = [{ title: "Product", href: "/product" }];
  const [modalOpenFlage, setmodalOpenFlage] = useState({
    isOpen: false,
    data: undefined,
  });
  const [loading, setLoading] = useState(false);
  const [poster, setPoster] = useState(null);
  const [tempPoster, setTempPoster] = useState(null);
  const [base64poster, setBase64poster] = useState(null);
  const [enabled, setEnabled] = useState(true);
  const [productId, setproductId] = useState("");
  const [allproduct, setAllProduct] = useState([]);
  // //console.log(allproduct);
  const [allTempProduct, setAllTempProduct] = useState([]);
  console.log(allTempProduct);
  const [imageBaseURL, setImageBaseURL] = useState(null);
  const [pageSizeState, setPageSizeState] = useState(10);
  const [pagination, setPagination] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [dateFilter, setDateFilter] = useState();

  const [selectedOption, setSelectedOption] = useState({
    value: "PRODUCT",
    label: "PRODUCT",
  });
  const animatedComponents = makeAnimated();
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    prod_id: null,
    rawData: {},
    rowIndex: 0,
  });

  const [allowedActions, setAllowedActions] = useState([]);
  const [productModal, setProductModal] = useState(false);
  const [prodModalData, setProdModalData] = useState([]);
  const [ovalSpinner, setOvalSpinner] = useState(false);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);

  const handleSelectAllChange = () => {
    if (isSelectAll) {
      setSelectedProductIds([]);
    } else {
      // setSelectedProductIds(allproduct.map((product) => product.prod_id));
      setSelectedProductIds(
        allproduct.map((product) => ({
          ser_id: product.ser_id,
          prod_id: product.prod_id,
        }))
      );
    }
    setIsSelectAll(!isSelectAll);
  };
  const handleCheckboxChange = (product) => {
    // setSelectedProductIds((prevSelected) =>
    //   prevSelected.includes(prodId)
    //     ? prevSelected.filter((id) => id !== prodId)
    //     : [...prevSelected, prodId]
    // );
    console.log("product=>", product)
    setSelectedProductIds((prevSelected) =>
      prevSelected.some((p) => p.prod_id === product.prod_id)
        ? prevSelected.filter((p) => p.prod_id !== product.prod_id)
        : [...prevSelected, { ser_id: product.ser_id, prod_id: product.prod_id }]
    );
  };
  const handleDeleteSelected = async () => {
    console.log("selectedProductIds=>", selectedProductIds)
    // Call API to delete selected products
    // For example:
    if (selectedProductIds?.length > 0) {
      for (const SM of selectedProductIds) {
        await MasterServices.removeProduct({ id: SM?.ser_id })
          .then((response) => {
            const { data, status } = response;

            if (data?.id) {
              toast.success("Successfully Deleted");
              let payload = {
                table_name: "m_products",
                table_field: "prod_id",
                table_field_val: SM?.prod_id,
                table_status_field: "prod_is_deleted",
                table_status_val: true,
                table_text: "Product",
                deleted_by_key: "prod_updated_by",
                delete_active_txt: "Removed"
              };
              MasterServices.removeMaster(payload).then((res) => {
                const { data, status, message } = res.data;
                if (status) {

                  toast.success(message);
                } else {
                  toast.error(message);
                }
              });

            } else {
              toast.error("Error");
            }
          })
          .catch((error) => {
            toast.error("Sorry Fatal Error!");
            setLoading(false);
          });
      }
      setConfirmationModal({ prod_id: null, status: false });
      setActiveTab(0);
      setLoading(false);
    }else{
      toast.error("Please Select Product");
    }
  };

  function closeModal() {
    setProductModal(false);
  }

  const openHandlerModal = (data) => {
    setProductModal(true);

    setProdModalData(data?.product_user_claim_map);
  };

  useEffect(() => {
    document.title = "Octopus | Product";
    clearForm();
    getAllProducts(3);
  }, []);

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(
        window.location.pathname,
        permissions,
        navigate
      );
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }

    const fetchData = async () => {
      // await getAllCompanies();
      await getAllCategories();
      await getAllSubCategories();
      await getBuisnessObjective();
      await getAllTagData();
      await getAllProducts(3);

      // setLoading(false);
      // await setCountryOptions(
      //   Country.getAllCountries().map((v) => ({
      //     label: v.name,
      //     value: v.isoCode,
      //   }))
      // );
    };

    fetchData();
  }, []);

  const initialValues = {
    prod_id: "",
    product_name: "",
    product_type: {},
    product_price: "",
    product_discount: "",
    product_no_of_issue: "",
    product_image: "",
    product_status: "",
  };

  const [formProduct, setformProduct] = useState(initialValues);
  const [activeTab, setActiveTab] = useState(0);
  // const [activePositionTab, setActivePositionTab] = useState(0)
  const navigate = useNavigate();
  const fileTypes = ["JPEG", "PNG", "GIF"];
  const productType = ["MAGAZINE", "PRODUCT"];
  let SPNM_ACtive_Tab = 0;
  let SPNM_ACtive_Position_Tab = 0;
  const tabs = [
    { name: "All", href: "#", current: activeTab === 0 },
    { name: "Normal", href: "#", current: activeTab === 1 },
    { name: "Sponsored", href: "#", current: activeTab === 2 },
  ];
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [activePositionTab, setActivePositionTab] = useState(0);
  // //console.log(activePositionTab);
  const statusOptions = [
    { label: "All", value: "All", index: 0 },
    { label: "Assigned", value: "Assigned", index: 1 },
    { label: "Unassigned", value: "Unassigned", index: 2 },
  ];

  const filterOptions = [
    { label: "Category", value: "Category" },
    { label: "SubCategory", value: "SubCategory" },
    { label: "Problem", value: "Problem" },
    { label: "Tags", value: "Tags" },
    { label: "Segment", value: "Segment" },
  ];

  const publishmentStatus = [
    { label: "New", value: "New", index: 2 },
    { label: "Published", value: "Published", index: 0 },
    { label: "Unpublished", value: "Unpublished", index: 1 },
  ];

  const segmentTypes = [
    { label: "Small Enterprise", value: "is_small_enterprise", index: 0 },
    { label: "Large Enterprise", value: "is_large_enterprise", index: 1 },
    { label: "Global Enterprise", value: "is_global_enterprise", index: 2 },
  ];

  const sortOptions = [
    { value: "ascending", label: "Ascending" },
    { value: "descending", label: "Descending" },
  ];

  const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedPublishmentStatus, setSelectedPublishmentStatus] =
    useState(null);
  const [productCategory, setProductCategory] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState(null);
  const [subCategoryOptions, setSubCategoryOptions] = useState(null);
  const [problemStatement, setProblemStatement] = useState(null);
  const [productProblemStatement, setProductProblemStatement] = useState(null);
  const [tagValue, setTagValue] = useState(null);
  const [tagData, setTagData] = useState([]);
  const [productSubCategory, setProductSubCategory] = useState(null);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [selectedSOrtingOrder, setselectedSOrtingOrder] = useState(null);

  const [filters, setFilters] = useState({
    // status: null,
    publishedStatus: null,
    startDate: null,
    endDate: null,
    tags: [],
    selectedCategories: [],
    selectedSubCategories: [],
    setProductProblemStatement: [],
    selectedSegment: null,
    selectedSOrtingOrder: null,
  });
  // //console.log(filters);
  // //console.log(productSubCategory,productProblemStatement,tagValue );

  // const positionTabs = [
  //   { name: "All", href: "#", current: activePositionTab === 0 },
  //   { name: "Filled", href: "#", current: activePositionTab === 1 },
  //   { name: "Empty", href: "#", current: activePositionTab === 2 },
  // ];

  const getAllProducts = async (payload, page, pageSize) => {
    setLoading(true);

    setAllProduct([]);
    if (pageSize) {
      setPageSizeState(pageSize);
    }
    let myPayload = {
      prod_is_sponsored: SPNM_ACtive_Tab === 2 ? 1 : 2,
      prod_is_normal: SPNM_ACtive_Tab === 1 ? 1 : 2,
      prod_is_all: SPNM_ACtive_Tab === 0 ? 1 : 2,
      prod_is_active: payload,
      page: page ? page : 1,
      pageLimit: !pageSize ? pageSizeState : pageSize,
      prod_position_filter: SPNM_ACtive_Position_Tab,
    };
    // const response = await SearcherMasterServices.getAllStrapiProducts(payload)
    // //console.log("response PS", response);
    // if (response.data.length > 0) {
    //   for (const SM of response.data) {
    //     const replacedData = replaceURLs(SM.attributes);
    //     //console.log("replacedData logo=>", replacedData?.logo)
    //     //console.log("replacedData=>", replacedData)
    //     //console.log("SM=>", SM?.attributes?.logo)
    // if (SM?.id == 36) {
    // if (replacedData?.logo) {
    // const response = await searcherApiHelper(`api/products/${SM?.id}`, {
    // data: {
    //   logo: replacedData?.logo?replacedData?.logo:SM?.attributes?.logo,
    //   brochure: replacedData?.brochure?.length>0?replacedData?.brochure:SM?.attributes?.brochure,
    //   brochure_asset_old: replacedData?.brochure?.length>0?replacedData?.brochure:SM?.attributes?.brochure,
    //   brochure_new: replacedData?.brochure_new?.length>0?replacedData?.brochure_new:SM?.attributes?.brochure_new,
    //   thumbnail_images: replacedData?.thumbnail_images,
    //   team: replacedData?.team?.headline? replacedData?.team:SM?.attributes?.team,
    //   demo_details: replacedData?.demo_details?.videos?.length>0?replacedData?.demo_details:SM?.attributes?.demo_details,
    // }
    // });
    // }
    // }
    //   }
    // }
    const response = await MasterServices.getAllProduct(myPayload);
    // //console.log("response", response);
    if (response.data.status == true) {
      if (response.data.data.data.length > 0) {
        setAllProduct(response.data.data.data);
        setAllTempProduct(response.data.data.data);
        setImageBaseURL(response.data.data.image_base_url);
        setProductModal(false);
        setLoading(false);
      }
      if (response?.data?.data?.pagination) {
        setPagination(response.data.data.pagination);
      }

      // setLoading(false);
    }
  };

  const onStatusUpdate = (prod_id, prod_is_active) => {
    let payload = {
      table_name: "m_products",
      table_field: "prod_id",
      table_field_val: prod_id,
      table_status_field: "prod_is_active",
      table_status_val: prod_is_active ? false : true,
      table_text: "Product",
      deleted_by_key: "prod_updated_by",
    };
    MasterServices.removeMaster(payload).then((res) => {
      const { data, status, message } = res.data;
      if (status) {
        getAllProducts(3);
        setActiveTab(0);
        toast.success(message);
      } else {
        toast.error(message);
      }
    });
  };

  const handleDrawer = (type, id, obj) => {
    if ((type === "edit", id)) {
      setproductId(id);
      setSelectedOption({
        value: obj.product_type,
        label: obj.product_type,
      });
      setTempPoster(imageBaseURL ? imageBaseURL + obj?.product_image : null);
      const initialValues = {
        prod_id: obj.prod_id,
        product_name: obj.product_name,
        // product_type: obj.product_type,
        product_price: obj.product_price,
        product_discount: obj.product_discount,
        product_no_of_issue: obj.product_no_of_issue,
        product_image: obj.product_image,
        product_status: obj.product_status,
      };
      setformProduct(initialValues);
    } else {
      setproductId("");
      setPoster(null);
      setSelectedOption({
        value: "PRODUCT",
        label: "PRODUCT",
      });
      setformProduct(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    const fileName = file.name;

    const reader = new FileReader();
    reader.onload = (e) => {
      setPoster(file);
      setTempPoster(e.target.result);
      setBase64poster(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formProduct,
      validationSchema: productSchema,
      onSubmit: async (values, action) => {
        let body = {
          prod_id: productId ? productId : 0,
          product_name: values.product_name,
          product_type: selectedOption,
          product_price: values.product_price,
          product_discount: values.product_discount,
          product_no_of_issue: values.product_no_of_issue,
          product_image: base64poster,
          product_status: enabled,
        };

        const response = await MasterServices.saveProductData(body);

        if (response.data.status) {
          action.resetForm();
          if (modalOpenFlage === true) {
            setTempPoster(null);
            getAllProducts(3);
            setSelectedOption({
              value: "PRODUCT",
              label: "PRODUCT",
            });
            toast.success(response.data.message);
            setproductId("");
            setActiveTab(0);
            setmodalOpenFlage(false);
          }
        } else {
          toast.error(response.data.message);
        }
      },
    });

  const onDeleteOpen = (row, rowIndex) => {
    setConfirmationModal({
      prod_id: row.prod_id,
      status: true,
      rawData: row,
      rowIndex: rowIndex,
    });
  };

  const onDeleteProduct = async (prod_id, rawData) => {
    let body = {
      data: {
        // publishedAt:prod_id?.ser_prod_publishedAt? new Date():null,
        publishedAt: !rawData?.ser_prod_publishedAt ? new Date() : null,
      },
    };
    firstProductPublish(rawData, !rawData?.ser_prod_publishedAt ? true : false);
    await MasterServices.deleteProduct({ id: rawData?.ser_id, body })
      .then((response) => {
        const { data, status } = response;

        if (data?.id) {
          toast.success("Successfully Updated");
          //getAllProducts(3);
          setConfirmationModal({ prod_id: null, status: false });
          setActiveTab(0);
          setLoading(false);
          //navigate("/product");
          //allproduct[rowIndex].ser_prod_publishedAt = !rawData?.ser_prod_publishedAt ? new Date() : null;
          updateProductAtIndex(confirmationModal.rowIndex);
        } else {
          toast.error("Error");
        }
        //  setLoading(false)
      })
      .catch((error) => {
        // const msg = error.response.data.message;
        // toast.error(msg)
        updateProductAtIndex(confirmationModal.rowIndex);
        toast.error("Sorry Fatal Error!");
        setLoading(false);
      });
  };
  const firstProductPublish = async (rawData, flag) => {
    if (
      flag == true &&
      (!rawData?.ser_prod_first_publishedAt ||
        rawData?.ser_prod_first_publishedAt != undefined ||
        rawData?.ser_prod_first_publishedAt != null)
    ) {
      let body = {
        prod_id: rawData?.prod_id,
      };

      await MasterServices.updateProductFirstPublish(body)
        .then((response) => {
          const { data, status } = response;
          //console.log("jaydata",data)
          if (data?.data?.ser_id) {
          } else {
            toast.error("Error");
          }
          //  setLoading(false)
        })
        .catch((error) => {
          toast.error("Sorry Fatal Error!");
          setLoading(false);
        });
    }
  };

  const updateProductAtIndex = (rowIndex) => {
    setAllProduct((prevProducts) => {
      const updatedProducts = [...prevProducts];

      // Update the specified item at the given index
      updatedProducts[rowIndex].ser_prod_publishedAt = confirmationModal.rawData
        ?.ser_prod_publishedAt
        ? null
        : new Date();

      return updatedProducts;
    });
  };

  // const handleTabClick = (index) => {
  //   SPNM_ACtive_Tab = index;
  //   setActiveTab(index);
  //   getAllProducts(3);
  // };

  const ApprovedHandler = async (item) => {
    setOvalSpinner(true);
    const body = {
      prod_id: item.fk_product_id,
      prod_is_claimed: item?.pucm_is_claimed,
      prod_is_approved: true,
      is_approved: true,
      fk_user_id: item.fk_user_id,
      id: item?.m_products_relation.ser_id,
    };

    const res = await MasterServices.approveModalProduct(body);
    if (res?.data?.status) {
      //console.log("res", res);
      toast.success(res?.data?.message);
      setOvalSpinner(false);
      closeModal();
      getAllProducts(3);
    } else {
      toast.error(res?.data?.message);
    }
  };

  const getAllCategories = async (e) => {
    const payload = { searchKey: e || "" };
    await SearcherMasterServices.getCategories(payload)
      .then((res) => {
        const { data, status, meta } = res;

        if (Array.isArray(data) && data.length > 0) {
          setCategoryOptions(
            data.map((v) => ({ label: v.attributes.name, value: v.id }))
          );
          // //console.log(categoryOptions);
        }
      })
      .catch((err) => {
        toast.error(err.data?.message);
        //console.log(err);
      });
  };

  const getAllSubCategories = async (e) => {
    const payload = { searchKey: e || "" };
    await SearcherMasterServices.getSubCategories(payload)
      .then((res) => {
        const { data, status, meta } = res;

        if (Array.isArray(data) && data.length > 0) {
          setSubCategoryOptions(
            data.map((v) => ({ label: v.attributes.name, value: v.id }))
          );
        }
      })
      .catch((err) => {
        toast.error(err.data?.message);
        //console.log(err);
      });
  };



  const getBuisnessObjective = async (e) => {
    const payload = { searchKey: e || "" };
    await SearcherMasterServices.getBuisnessObjectives(payload)
      .then((res) => {
        const { data, status, meta } = res;
        // //console.log(data);
        if (Array.isArray(data) && data.length > 0) {
          setProblemStatement(
            data.map((v) => ({ label: v.attributes.name, value: v.id }))
          );
        }
      })
      .catch((err) => {
        toast.error(err.data?.message);
        //console.log(err);
      });
  };

  const getAllTagData = async (e) => {
    const payload = { searchKey: e || "" };
    const response = await SearcherMasterServices.getTagsData(payload);
    // //console.log("response", response);
    if (response?.data?.length > 0) {
      const data = response?.data?.map((ele, index) => {
        return {
          label: ele?.attributes?.name,
          value: ele.id,
        };
      });
      setTagData(data);
    }
  };

  const handleSaveProductPosition = async (
    originalCell,
    newPositionValue,
    ser_id,
    prod_id,
    errorCallBack
  ) => {
    let checkPos = await MasterServices.changeProductPosition({ product_position: newPositionValue, ser_id: ser_id, prod_id: prod_id });
    console.log("checkPos=>", checkPos)
    if (checkPos?.data?.data?.prod_id) {
      toast.error(checkPos?.data?.message);
      setActiveTab(0);
      getAllProducts(3)
      return false;
    }
    newPositionValue = newPositionValue && !isNaN(newPositionValue) ? parseInt(newPositionValue) : 0;
    let body = {
      data: {
        product_position: newPositionValue,
      },
    };

    await MasterServices.deleteProduct({ id: ser_id, body })
      .then(async (response) => {
        const { data, status } = response;

        if (data?.id) {
          let checkPos = await MasterServices.updateProductPosition({ product_position: newPositionValue, ser_id: ser_id, prod_id: prod_id });
          toast.success("Successfully Updated");
          setActiveTab(0);
          getAllProducts(3)
          setLoading(false);
        } else {
          errorCallBack();
          // toast.error(
          //   "Position already taken. Please try with a different position."
          // );
        }
        //  setLoading(false)
      })
      .catch((error) => {
        toast.error("Sorry Fatal Error!");
        setLoading(false);
      });
  };

  // FILTER FUNCTIONS START

  const handlePositionTabClick = (index) => {
    SPNM_ACtive_Position_Tab = index;
    setActivePositionTab(index);
    getAllProducts(3);
  };

  useEffect(() => {
    let filteredProducts = allproduct;
    const applyFilters = () => {
      // //console.log(" loop");
      //Status Filter
      // if (typeof filters.statusIndex !== 'undefined') {
      //   const index = filters.statusIndex;
      //   setActiveTab(index); // Or setActivePositionTab if that's the correct function
      //   getAllProducts(index); // You mentioned index 3 before, ensure this logic is correct
      // }

      // Published status filter
      if (filters.publishedStatus !== null) {
        if (filters.publishedStatus === "New") {
          filteredProducts = filteredProducts.filter(
            (product) => product.ser_prod_first_publishedAt == null
          );
        } else if (filters.publishedStatus === "Published") {
          filteredProducts = filteredProducts.filter(
            (product) => product.ser_prod_publishedAt != null
          );
        } else if (filters.publishedStatus === "Unpublished") {
          filteredProducts = filteredProducts.filter(
            (product) => product.ser_prod_publishedAt == null
          );
        }
      }

      // Date range filter
      if (filters.startDate || filters.endDate) {
        const startDate = filters.startDate
          ? new Date(filters.startDate)
          : new Date("1000-01-01");
        const endDate = filters.endDate
          ? new Date(filters.endDate)
          : new Date();

        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        filteredProducts = filteredProducts.filter((product) => {
          const productDate = new Date(product.ser_prod_publishedAt);
          return productDate >= startDate && productDate <= endDate;
        });
      }

      // Category filter
      if (filters.selectedCategories.length > 0) {
        filteredProducts = filteredProducts.filter(
          (product) =>
            product.ser_prod_categories_json &&
            product.ser_prod_categories_json.data &&
            product.ser_prod_categories_json.data.some((cat) =>
              filters.selectedCategories.includes(cat.id)
            )
        );
      }

      // SubCategory filter
      if (filters.selectedSubCategories.length > 0) {
        filteredProducts = filteredProducts.filter(
          (product) =>
            product.ser_prod_sub_categories_json &&
            product.ser_prod_sub_categories_json.data &&
            product.ser_prod_sub_categories_json.data.some((subCat) =>
              filters.selectedSubCategories.includes(subCat.id)
            )
        );
      }

      // Business Objective filter
      if (filters.setProductProblemStatement.length > 0) {
        filteredProducts = filteredProducts.filter(
          (product) =>
            product.ser_prod_business_objective_id_json &&
            product.ser_prod_business_objective_id_json.data &&
            product.ser_prod_business_objective_id_json.data.some((obj) =>
              filters.setProductProblemStatement.includes(obj.id)
            )
        );
      }

      // Tags filter
      if (filters.tags) {
        filteredProducts = filteredProducts.filter(
          (product) =>
            product.ser_prod_tag_id_json &&
            product.ser_prod_tag_id_json.data &&
            product.ser_prod_tag_id_json.data.some((tagId) =>
              filters.tags.includes(tagId.id)
            )
        );
      }

      // Segment filter
      if (filters.selectedSegment) {
        //console.log("loop");
        filteredProducts = filteredProducts.filter(
          (product) => product[filters.selectedSegment]
        );
      }

      //Sorting Order filter

      setAllTempProduct(filteredProducts);
    };

    applyFilters();
  }, [filters]);



  const sortByHandler = (selectedOption) => {

    let filteredProducts = allproduct;
    const newOrder = selectedOption ? selectedOption.value : null;
    setselectedSOrtingOrder(selectedOption);
    // setFilters((prevFilters) => ({
    //   ...prevFilters,
    //   selectedSOrtingOrder: newOrder,
    // }));

    filteredProducts = filteredProducts.sort((a, b) => {

      const posA = a.product_position;
      const posB = b.product_position;

      if (String(newOrder) === "ascending") {

        if (posA === 0 && posB !== 0) {
          return 1; // Place 0 last
        }
        if (posA !== 0 && posB === 0) {
          return -1; // Place 0 last
        }

        return posA - posB; // For ascending sort
      }

      // For descending sort, start from 0
      if (posA === 0 && posB !== 0) {
        return 1; // Place 0 first
      }
      if (posA !== 0 && posB === 0) {
        return -1; // Place 0 first
      }

      return posB - posA; // For descending sort
    });

    setAllTempProduct(filteredProducts);

  }

  const clearForm = () => {
    getAllProducts(3);
    setSelectedStatus(statusOptions[0]);
    setSelectedFilter(null);
    setSelectedPublishmentStatus(null);
    setProductCategory([]);
    setProductProblemStatement([]);
    setTagValue(null);
    setProductSubCategory(null);
    setSelectedSegment(null);
    setselectedSOrtingOrder(null);
    setStartDate("");
    setEndDate("");
    setFilters({
      publishedStatus: null,
      startDate: null,
      endDate: null,
      selectedCategories: [],
      selectedSubCategories: [],
      setProductProblemStatement: [],
      selectedSegment: null,
      selectedSOrtingOrder: null,
    });
  };

  const handleExport = (excel_type, data) => {
    if (Array.isArray(data) && data?.length > 0) {
      // let newSheetdata = data.map((obj, index) => {
      //   const publishedAt = obj?.ser_prod_publishedAt;
      //   const prod_is_normal = obj?.prod_is_normal;
      //   const prod_is_sponsored = obj?.prod_is_sponsored;
      //   return {
      //     Product_Id: obj?.ser_id || "-",
      //     Product_Name: obj?.ser_prod_name || "-",

      //     //Type: prod_is_sponsored ? "Sponsored" : prod_is_normal ? "Normal" : "",
      //     createdBy: obj?.product_cu_relation?.user_username || "-",
      //     CreatedBy_Email: obj?.product_cu_relation?.user_email || "-",
      //     CreatedBy_Phone: obj?.product_cu_relation?.user_mobile || "-",
      //     CreatedBy_Designation:
      //       obj?.product_cu_relation?.user_designation || "-",
      //     CreatedBy_Company: obj?.product_cu_relation?.user_company_name || "-",
      //     updatedBy: obj?.product_uu_relation?.user_username || "-",
      //     Status: publishedAt ? "Published" : "Draft",
      //     Launch_Date: obj?.ser_prod_launch_date || "-",
      //     Published_At:
      //       moment(obj?.ser_prod_publishedAt).format("DD-MM-YYYY hh:mm:ss A") ||
      //       "-",
      //     Logo: obj?.ser_prod_logo || "-",
      //   };
      // });

      console.log("dddd", data)
      let newSheetdata = data.map((obj, index) => {

        const publishedAt = obj?.ser_prod_publishedAt;

        let contactEmails = Array.isArray(obj?.ser_prod_contact)
          ? obj.ser_prod_contact.map((contact) => contact.email).filter(email => email !== '').join("; ")
          : "-";
        let contactPhones = Array.isArray(obj?.ser_prod_contact)
          ? obj.ser_prod_contact.map((contact) => contact.phone).filter(phone => phone !== '').join("; ")
          : "-";
        let contactWebsites = Array.isArray(obj?.ser_prod_contact)
          ? obj.ser_prod_contact.map((contact) => contact.website_link).filter(website => website !== '').join("; ")
          : "-";

        return {

          Product_Id: obj?.ser_id || "-",
          Product_Name: obj?.ser_prod_name || "-",

          // Created By
          // CreatedBy: obj?.product_uu_relation?.user_username || "-",
          CreatedBy: obj?.product_uu_relation?.user_first_name || "-",
          CreatedBy_Email: obj?.product_uu_relation?.user_email || "-",
          CreatedBy_Phone: obj?.product_uu_relation?.user_mobile || "-",
          CreatedBy_Designation: obj?.product_uu_relation?.user_designation || "-",
          CreatedBy_Company: obj?.product_uu_relation?.user_company_name || "-",
          CreatedBy_Role: obj?.product_uu_relation?.m_role_relation?.role_name || "-",
          // Updated By
          UpdatedBy: obj?.product_uu_relation?.user_first_name || "-",

          // Comp. Type
          Company_Type: obj?.ser_prod_company_id_json?.data?.attributes?.name || "-",

          //organisation size
          Organisation_size: obj?.product_uu_relation?.user_organisation_size || "-",


          //Status
          Status: publishedAt ? "Published" : "Draft",

          // Launch Date
          Launch_Date: obj?.ser_prod_launch_date || "-",

          // Published Date
          Published_At: moment(publishedAt).format("DD-MM-YYYY hh:mm:ss A") || "-",

          //Scale of Company
          Segments: obj?.is_large_enterprise ? "large" : obj?.is_small_enterprise ? "small" : obj?.is_global_enterprise ? "global" : "-",

          // HeadQ
          Headquarters: obj?.ser_prod_headquarters || "-",


          // Short Description
          Short_Description: obj?.ser_prod_short_description || "-",

          // Links
          Links: Array.isArray(obj?.ser_prod_thumbnail_videos) ? obj?.ser_prod_thumbnail_videos?.map((thumbnail) => `${thumbnail.thumbnail_url}`).join("; ") : "-",

          //Tags 
          Tags: obj?.ser_prod_tag_id_json?.data?.map((tags) => `${tags?.attributes?.name}`).join(", ") || "-",

          // BO 
          Problem_Statement: obj?.ser_prod_business_objective_id_json?.data?.map((businessObj) => `${businessObj?.attributes?.name}`).join(", ") || "-",


          // Cat
          Categories: obj?.ser_prod_categories_json?.data?.map((cat) => `${cat?.attributes?.name}`).join(", ") || "-",

          // Sub Cat 
          Sub_Categories: obj?.ser_prod_sub_categories_json?.data?.map((subCat) => `${subCat?.attributes?.name}`).join(", ") || "-",

          // Headings
          Headline: obj?.ser_prod_headline || "-",


          //Description
          Description: obj?.ser_prod_description || "-",


          // Features
          Features: Array.isArray(obj?.ser_prod_features) ? obj.ser_prod_features.map(feature => `${feature.description}`).join(", ") : "-",

          // Highlights
          Highlights: obj?.ser_prod_highlights?.map((hl) => `${hl.name}`).join("; ") || "-",
          Highlights_description: obj?.ser_prod_highlights?.map((hl) => `${hl.description}`).join("; ") || "-",


          Weightage: obj?.is_weightage || "-",

          Spotlight: obj?.is_spotlight || "-",
          // Step 2 Details

          // Logo
          Logo: obj?.ser_prod_logo || "-",

          // ScreenShots
          Screenshots: Array.isArray(obj?.ser_prod_thumbnail_images) && obj.ser_prod_thumbnail_images.length > 0 ? obj.ser_prod_thumbnail_images.map((screen_shot) => screen_shot.thumbnail_url).join("; ") : "-",



          // Clients
          Clients: obj?.ser_prod_client_id_json?.data?.map((client) => `${client?.attributes?.name}`).join("; ") || "-",
          Client_Image: obj?.ser_prod_client_id_json?.data?.map((client) => `${client?.attributes?.logo}`).join("; ") || "-",

          // // HCM
          // HCM : Array.isArray(obj?.hcm_integrations?.data) && obj?.hcm_integrations.length > 0 ? obj.hcm_integrations.map(hcm => hcm?.attributes?.name).filter(name => name).join("; ") : "-",
          HCM: Array.isArray(obj?.ser_prod_hcm) && obj.ser_prod_hcm.length > 0 ? obj.ser_prod_hcm.map(hcm => hcm?.name).join("; ") : "-",
          HCM_Image: Array.isArray(obj?.ser_prod_hcm) && obj.ser_prod_hcm.length > 0 ? obj.ser_prod_hcm.map(hcm => hcm?.logo).join("; ") : "-",


          // Content Gallery
          Content: obj?.ser_prod_brochure_new?.map((brochure) => `${brochure.name}`).join("; ") || "-",
          Content_fileURL: obj?.ser_prod_brochure_new?.map((brochure) => `${brochure.file_url}`).join("; ") || "-",
          Content_thumbnail: obj?.ser_prod_brochure_new?.map((brochure) => `${brochure.thumbnail}`).join("; ") || "-",
          Content_description: obj?.ser_prod_brochure_new?.map(brochure => `${brochure.description}`).join('; ') || "-",


          // Brochure
          Brochure: obj?.ser_prod_brochure?.map((brochure) => `${brochure.name}`).join("; ") || "-",
          Brochure_fileURL: obj?.ser_prod_brochure?.map((brochure) => `${brochure.file_url}`).join("; ") || "-",
          Brochure_thumbnail: obj?.ser_prod_brochure?.map((brochure) => `${brochure.thumbnail}`).join("; ") || "-",
          // Brochure_description: obj?.ser_prod_brochure?.map(brochure => `${brochure.description}`).join('; ') || "-",



          // Step 3 Details
          // Demo Details
          // Demo_Title: obj?.ser_prod_demo_details?.title || "-",
          Demo_Header: obj?.ser_prod_demo_details?.videos?.map((video) => video.header).join(", ") || "-",
          Demo_Descriptions: obj?.ser_prod_demo_details?.videos?.map((video) => video.description).join(", "),
          Demo_VideosURL: obj?.ser_prod_demo_details?.videos?.map((video) => video.videourl).join(", ") || "-",
          //Demo_Times: Array.isArray(obj?.ser_prod_demo_details?.videos) ? obj?.ser_prod_demo_details.videos.flatMap(video =>Array.isArray(video?.times) ? video?.times.map(time => `${time?.minute}:${time?.second} `) : "-").join(", ") : "-",
          Demo_Times: Array.isArray(obj?.ser_prod_demo_details?.videos)
            ? obj.ser_prod_demo_details.videos.flatMap(video =>
              Array.isArray(video?.times)
                ? video.times.map(time =>
                  `${time?.minute ?? "-"}:${time?.second ?? "-"}`
                )
                : ["-"]
            ).join(", ")
            : "-",
          //Demo_Chapters: Array.isArray(obj?.ser_prod_demo_details?.videos) ? obj?.ser_prod_demo_details.videos.flatMap(video =>Array.isArray(video.times)? video?.times.map(time => `${time?.description} `) : "-").join(", ") : "-",
          Demo_Chapters: Array.isArray(obj?.ser_prod_demo_details?.videos)
            ? obj.ser_prod_demo_details.videos.flatMap(video =>
              Array.isArray(video?.times)
                ? video.times.map(time => time?.description ?? "-")
                : "-"
            ).join(", ")
            : "-",
          // Price
          Price_Name: obj?.ser_prod_price?.map((price) => `${price.name}`).join("; ") || "-",
          Price_Type: obj?.ser_prod_price?.map((price) => `${price.type}`).join("; ") || "-",
          Price_Price: obj?.ser_prod_price?.map(price => `${price.price}`).join('; ') || "-",
          Price_Description: obj?.ser_prod_price?.map((price) => `${price.description}`).join("; ") || "-",

          // step 4 Deatails

          Mission: obj?.ser_prod_team?.description || "-",
          Vision: obj?.ser_prod_team?.headline || "-",

          // Teams details
          Teams_Name: obj?.ser_prod_team?.teams?.map((team) => `${team.name}`).join("; ") || "-",
          Teams_imageurl: obj?.ser_prod_team?.teams?.map((team) => `${team.imageurl}`).join("; ") || "-",
          Teams_description: obj?.ser_prod_team?.teams?.map((team) => `${team.description}`).join("; ") || "-",
          Teams_designation: obj?.ser_prod_team?.teams?.map((team) => `${team.designation}`).join("; ") || "-",
          Teams_About: obj?.ser_prod_team?.description || "-",



          // COntacts 

          "Contact Emails": contactEmails,
          "Contact Phones": contactPhones,
          "Contact Websites": contactWebsites,

        };
      });

      let fileName =
        "Product Report " +
        `${moment().format("DD-MM-YYYY hh:mm:ss A")}` +
        "." +
        excel_type;


      const headings = [
        [
          "Product ID",
          "Product Name",

          "Created By - Firstname",
          "Created By - Email",
          "Created By - Phone",
          "Created By - Designation",
          "Created By - Company",
          "Created By - Role",
          "Updated By",
          "Company Type",
          "Organisation Size",

          "Status",

          "Launch Date",

          "Published At",

          "Segments",

          "Headquarters",

          "Short Description",

          "Links",

          "Tags",

          "Problem Statement",

          "Categories",

          "Sub Categories",

          "Description",

          "Headline",

          "Features",

          "Highlights",

          "Highlight_Description",

          "Weightage",

          "spotlight",

          "Logo",

          "ScreenShots",

          "Clients",

          "Clients_Image",

          "HCM",

          "HCM Images",

          "Content Name",

          "Content File",

          "Content Image",

          "Content Description",

          "Brochure Name",

          "Brochure File",

          "Brochure Image",

          // "Brochure Description",

          "Demo Header",

          "Demo Description",

          "Demo Video_URL",

          "Demo Times",

          "Demo Chapters",

          "Price Name",

          "Price Type",

          "Price",

          "Price Description",

          "Mission",

          "Vision",

          "Teams Name",

          "Teams Image URL",

          "Teams Description",

          "Team Designations",

          "Team About",

          "Contact Email",

          "Contact Phone",

          "Contact Website",
        ],
      ];

      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, newSheetdata, {
        origin: "A2",
        skipHeader: true,
      });
      utils.book_append_sheet(wb, ws, "Report");
      writeFile(wb, fileName);
    } else {
      toast.error("Data Not Found!");
    }
  };

  // FILTER FUNCTIONS END

  const onOpenCreatedByModal = (userData) => {
    setmodalOpenFlage({
      isOpen: true,
      data: userData,
    });
    //console.log(userData);
  };

  // const downloadProductDataExcel = () => {
  //   const dataToExport = filteredProducts;

  //   const formatDate = (dateString) => {
  //     if (!dateString) {
  //       return "----";
  //     }
  //     return new Date(dateString).toLocaleDateString("en-US", {
  //       timeZone: "Asia/Kolkata",
  //     });
  //   };

  //   const formatCategories = (categoryJson) => {
  //     // Check if categoryJson is an object and has a data property that is an array
  //     if (!categoryJson || !Array.isArray(categoryJson.data)) {
  //       return "No Categories";
  //     }
  //     // Extract and join category names
  //     const categories = categoryJson.data;
  //     if (!categories.length) {
  //       return "No Categories";
  //     }
  //     return categories.map((cat) => cat.attributes.name).join(', ');
  //   };

  //   const columns = [
  //     {
  //       Header: "Id",
  //       accessor: "prod_id",
  //     },
  //     {
  //       Header: "Name",
  //       accessor: "ser_prod_name",
  //     },
  //     {
  //       Header: "Launch Date",
  //       accessor: "ser_prod_launch_date",
  //     },
  //     {
  //       Header: "Published At",
  //       accessor: "ser_prod_publishedAt",
  //       // Transform the date format directly here if needed
  //       format: (data) => formatDate(data),
  //     },
  //     {
  //       Header: "Categories",
  //       accessor: (item) => formatCategories(item.ser_prod_categories_json),
  //     },

  //     {
  //       Header: "Type",
  //       accessor: (item) => item.prod_is_sponsored ? "Sponsored" : item.prod_is_normal ? "Normal" : "",
  //     },
  //     {
  //       Header: "Status",
  //       accessor: (item) => item.ser_prod_publishedAt ? "Published" : "Draft",
  //     },
  //     {
  //       Header: "Updated At / Created At",
  //       accessor: (item) => `Updated At: ${formatDate(item.ser_prod_updatedAt)} / Created At: ${formatDate(item.ser_prod_createdAt)}`,
  //     },
  //   ];

  //   const headings = [columns.map(col => col.Header)];

  //   const transformedData = dataToExport.map(item => {
  //     const row = {};
  //     columns.forEach(col => {
  //       const value = typeof col.accessor === "function" ? col.accessor(item) : item[col.accessor];
  //       row[col.Header] = value || '';
  //     });
  //     return row;
  //   });

  //   exportToExcel(transformedData, headings, "ProductsExport.xlsx", "Products");
  // };

  const onRemoveProduct = async (ser_id, rawData, index) => {

    await MasterServices.removeProduct({ id: ser_id })
      .then((response) => {
        const { data, status } = response;

        if (data?.id) {
          toast.success("Successfully Deleted");
          let payload = {
            table_name: "m_products",
            table_field: "prod_id",
            table_field_val: rawData?.prod_id,
            table_status_field: "prod_is_deleted",
            table_status_val: true,
            table_text: "Product",
            deleted_by_key: "prod_updated_by",
            delete_active_txt: "Removed"
          };
          MasterServices.removeMaster(payload).then((res) => {
            const { data, status, message } = res.data;
            if (status) {
              getAllProducts(3);
              setActiveTab(0);
              toast.success(message);
            } else {
              toast.error(message);
            }
          });
          setConfirmationModal({ prod_id: null, status: false });
          setActiveTab(0);
          setLoading(false);
          updateProductAtIndex(index);
        } else {
          toast.error("Error");
        }
        setLoading(false)
      })
      .catch((error) => {
        updateProductAtIndex(index);
        toast.error("Sorry Fatal Error!");
        setLoading(false);
      });
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() =>
          onDeleteProduct(confirmationModal.prod_id, confirmationModal.rawData)
        }
        dataModal={confirmationModal}
      />
      <Breadcrumb pages={pages} />
      <div className="  flex items-center justify-between my-2">
        <div className="">
          <h1 className="text-xl font-semibold text-gray-900">Product List</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the Product.
          </p>
        </div>

        <div>
          {allowedActions.filter((data) => data.permission_id == 23)?.length >
            0 ? (
            <Link
              // onClick={() => handleDrawer("add", "", {})}
              to="/addproduct"
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent  bg-gradient-to-b from-[#fd6e1d] to-[#ef6122] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
            >
              Add Product
            </Link>
          ) : null}
        </div>
      </div>
      <div className=" w-full shadow-sm rounded-md h-auto border-2 p-4">
        <div className=" w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Status
            </label>
            <div>
              <Select
                className="basic-single sm:text-sm"
                classNamePrefix="select"
                defaultValue={statusOptions[0]}
                placeholder="Select"
                name="status"
                options={statusOptions}
                // value={activePositionTab}
                value={selectedStatus}
                // value={selectedStatus}
                // onChange={setSelectedStatus}
                onChange={(selectedOption) => {
                  setSelectedStatus(statusOptions[selectedOption]);
                  setActivePositionTab(selectedOption);

                  handlePositionTabClick(selectedOption.index); //Previously defined function
                }}
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Publishment Status
            </label>
            <div>
              <Select
                className="basic-single sm:text-sm"
                classNamePrefix="select"
                defaultValue={publishmentStatus[0]}
                placeholder="Select"
                name="status1"
                options={publishmentStatus}
                value={selectedPublishmentStatus}
                onChange={(selectedOption) => {
                  setSelectedPublishmentStatus(selectedOption);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    publishedStatus: selectedOption
                      ? selectedOption.value
                      : null,
                  }));
                }}
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900 whitespace-nowrap">
              Categories
            </label>
            <div className="">
              <Select
                isMulti={true}
                className="basic-single sm:text-sm w-full"
                classNamePrefix="select"
                defaultValue="Select"
                placeholder="Select"
                name="product_categories"
                onInputChange={(e) => {
                  if (e != "") {
                    getAllCategories(e);
                  }
                }}
                options={categoryOptions}
                value={productCategory}
                // value={selectedCategories}
                onChange={(e) => {
                  const categoryIds = e.map((option) => option.value);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    selectedCategories: categoryIds,
                  }));
                  setProductCategory(
                    categoryIds.map((id) => ({
                      value: id,
                      label: categoryOptions.find(
                        (option) => option.value === id
                      ).label,
                    }))
                  );
                }}
              />
              {!values.product_categories ||
                values.product_categories.length == 0 ? (
                <p className="text-red-600 text-sm">
                  {errors.product_categories}
                </p>
              ) : null}
            </div>
          </div>
          <div className="flex flex-col w-full">
            <label className="block text-sm font-medium leading-6 text-gray-900 whitespace-nowrap">
              Subcategories
            </label>
            <div className="">
              <Select
                isMulti={true}
                className="basic-single sm:text-sm w-full"
                classNamePrefix="select"
                defaultValue="Select"
                placeholder="Select"
                name="product_sub_categories"
                onInputChange={(e) => {
                  if (e != "") {
                    getAllSubCategories(e);
                  }
                }}
                options={subCategoryOptions?.length > 0 ? subCategoryOptions : []}

                value={productSubCategory}
                onChange={(e) => {
                  const subCategoryIds = e.map((option) => option.value);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    selectedSubCategories: subCategoryIds,
                  }));
                  setProductSubCategory(
                    subCategoryIds.map((id) => ({
                      value: id,
                      label: subCategoryOptions.find(
                        (option) => option.value === id
                      ).label,
                    }))
                  ); // If you maintain local state
                }}
              />
              {!values.product_sub_categories ||
                values.product_sub_categories.length == 0 ? (
                <p className="text-red-600 text-sm">
                  {errors.product_sub_categories}
                </p>
              ) : null}
            </div>
          </div>
          <div>
            <label className="flex block text-sm font-medium leading-6 text-gray-900">
              Problem Statements
            </label>
            <div className="">
              <Select
                isMulti={true}
                className="basic-single sm:text-sm"
                classNamePrefix="select"
                defaultValue="Select"
                placeholder="Select"
                name="product_problem_statement"
                options={problemStatement}
                value={productProblemStatement}
                onInputChange={(e) => {
                  if (e != "") {
                    getBuisnessObjective(e);
                  }
                }}
                onChange={(e) => {
                  const problemStatementIds = e.map((option) => option.value);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    setProductProblemStatement: problemStatementIds,
                  }));
                  setProductProblemStatement(
                    problemStatementIds.map((id) => ({
                      value: id,
                      label: problemStatement.find(
                        (option) => option.value === id
                      ).label,
                    }))
                  );
                }}
              />
              {!values.product_problem_statement ? (
                <p className="text-red-600 text-sm">
                  {errors.product_problem_statement}
                </p>
              ) : null}
            </div>
          </div>
          <div>
            <label className="flex block text-sm font-medium leading-6 text-gray-900">
              Tags
            </label>
            <div className="">
              <Select
                isMulti={true}
                className="basic-single sm:text-sm"
                classNamePrefix="select"
                defaultValue="Select"
                placeholder="Select"
                name="product_tags"
                onInputChange={(e) => {
                  if (e != "") {
                    getAllTagData(e);
                  }
                }}
                options={tagData}
                value={tagValue}
                onChange={(e) => {
                  const tagIds = e.map((option) => option.value);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    tags: tagIds,
                  }));
                  setTagValue(
                    tagIds.map((id) => ({
                      value: id,
                      label: tagData.find((option) => option.value === id)
                        .label,
                    }))
                  ); // If you maintain local state
                }}
              />
              {!values.product_tags ? (
                <p className="text-red-600 text-sm">{errors.product_tags}</p>
              ) : null}
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Segments catered to
            </label>
            <div>
              <Select
                classNamePrefix="select"
                defaultValue="Select"
                placeholder="Select"
                value={selectedSegment}
                onBlur={handleBlur}
                name="enterprise"
                className="basic-single sm:text-sm"
                options={segmentTypes}
                onChange={(selectedOption) => {
                  setSelectedSegment(selectedOption);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    selectedSegment: selectedOption
                      ? selectedOption.value
                      : null,
                  }));
                }}
              />{" "}
              {/* {errors.enterprise && touched.enterprise ? (
                    <p className="text-red-600 text-sm">{errors.enterprise}</p>
                  ) : null} */}
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Start Date
            </label>
            <div className="flex">
              <input
                type="date"
                name="date"
                value={startDate}
                onChange={(e) => {
                  const startDate = e.target.value;
                  setStartDate(startDate);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    startDate: startDate,
                  }));
                }}
                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              End Date
            </label>
            <div className="flex">
              <input
                type="date"
                name="date"
                value={endDate}
                onChange={(e) => {
                  const endDate = e.target.value;
                  setEndDate(endDate);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    endDate: endDate,
                  }));
                }}
                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label className="flex block text-sm font-medium leading-6 text-gray-900">
              Sort By Order
            </label>
            <div className="">
              <Select
                className="basic-single sm:text-sm"
                classNamePrefix="select"
                defaultValue="Select"
                placeholder="Select"
                name="sorting_order"
                options={sortOptions}
                value={selectedSOrtingOrder}
                onChange={(selectedOption) => {
                  sortByHandler(selectedOption);
                }}
              />

              {/* {!values.product_tags ? (
                <p className="text-red-600 text-sm">{errors.product_tags}</p>
              ) : null} */}
            </div>
          </div>
        </div>
        <div className=" pt-4 ">
          <button
            onClick={clearForm}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#fd6e1d] to-[#ef6122] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
          >
            Clear
          </button>
        </div>
      </div>
      <div className=" w-full mt-4 flex justify-end">
        <button
          onClick={() => {
            handleExport("xlsx", allTempProduct ? allTempProduct : allproduct);
          }}
          type="button"
          // onClick={downloadProductDataExcel}
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#fd6e1d] to-[#ef6122] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
        >
          Download Excel
        </button>
        {selectedProductIds?.length > 0?(
          <button
            onClick={handleDeleteSelected}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#fd6e1d] to-[#ef2244] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-2"
          >
            Delete All
          </button>
        ):(<></>)}
      </div>
      <div className="mt-4 mb-4 flex items-center gap-x-2">
        {/* <div className="flex">
          <input
            type="date"
            name="date"
            value={dateFilter}
            onChange={(e) => dateFilterHandler(e)}
            className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div> */}
      </div>

      {!loading ? (
        <Table
          columns={product_columns({
            onDeleteOpen,
            handleDrawer,
            imageBaseURL,
            onStatusUpdate,
            allowedActions,
            openHandlerModal,
            handleSaveProductPosition,
            onOpenCreatedByModal,
            onRemoveProduct,
            handleCheckboxChange,
            selectedProductIds,
            isSelectAll,
            handleSelectAllChange,
          })}
          data={allTempProduct ? allTempProduct : allproduct}
          pagination={pagination}
          goToThePage={getAllProducts}
          loading={loading}
          pageSizeState={pageSizeState}
          setPageSizeState={setPageSizeState}
        />
      ) : (
        <FallingLinesLoader />
      )}

      <div>
        <Transition appear show={productModal} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Table
                      columns={prod_Modal_columns({
                        onStatusUpdate,
                        ovalSpinner,
                        ApprovedHandler,
                      })}
                      data={prodModalData}
                    />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>

        <Transition.Root show={modalOpenFlage.isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              setmodalOpenFlage({
                isOpen: false,
                data: [],
              });
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-[500px]">
                    {modalOpenFlage.isOpen && modalOpenFlage.data && (
                      <div className="fixed inset-0 z-20 bg-black bg-opacity-50 flex justify-center items-center ">
                        <div className="flex rounded-md text-start pl-10 pr-4 flex-col w-[500px] divide-gray-200 bg-white shadow-xl pt-12">
                          <div className="h-0 flex-1 overflow-y-auto">
                            Name :{" "}
                            {(modalOpenFlage.data?.product_cu_relation
                              ?.user_first_name || "") +
                              " " +
                              (modalOpenFlage.data?.product_cu_relation
                                ?.user_last_name || "")}
                          </div>
                          <div className="h-0 flex-1 overflow-y-auto">
                            Email :{" "}
                            {modalOpenFlage.data?.product_cu_relation
                              ?.user_email || ""}
                          </div>
                          <div className="h-0 flex-1 overflow-y-auto">
                            Phone No :{" "}
                            {modalOpenFlage.data?.product_cu_relation
                              ?.user_mobile || ""}
                          </div>

                          <div className="h-0 flex-1 overflow-y-auto">
                            Designation :{" "}
                            {
                              modalOpenFlage.data?.product_cu_relation
                                ?.user_designation
                            }
                          </div>

                          <div className="h-0 flex-1 overflow-y-auto">
                            Company :{" "}
                            {
                              modalOpenFlage.data?.product_cu_relation
                                ?.user_company_name
                            }
                          </div>

                          <div className="flex flex-shrink-0 justify-end px-4 py-4">
                            {/* <button
              type="button"
              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
              onClick={() => {
                setmodalOpenFlage({
                  isOpen: false,
                  data: undefined,
                });
              }}
            >
              Cancel
            </button> */}
                            <div className="">
                              <button
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={() => {
                                  setmodalOpenFlage({
                                    isOpen: false,
                                    data: undefined,
                                  });
                                }}
                              >
                                Got it, thanks!
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
};

export default Product;
