import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { roleSchema } from "../../../schemas";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/tables/table";
// import { useDispatch } from "react-redux";
import { role_columns } from "../../../components/tables/tableheader";
import {
  deleteRole,
  getAllRoles,
  addRole,
  updateRole,
} from "../../../redux/role/actions";
import { Dialog, Transition } from "@headlessui/react";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import { getLoginUserInfo } from "../../../redux/userInfo/actions";
import {
  getAllowedActions,
  checkPermissionsAndRedirect,
} from "../../../helper/commonHelper";

function RoleList() {
  const pages = [{ title: "Role List", href: "/role", module_id: 3 }];
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [role_id, setRoleid] = useState("");
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    role_id: null,
  });
  const [allowedActions, setAllowedActions] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, list: roleList } = useSelector((state) => state.role);

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    // 
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(
        window.location.pathname,
        permissions,
        navigate
      );
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);

  useEffect(() => {
    document.title = "Octopus | Roles";
    dispatch(getAllRoles(3));
  }, []);

  const initialValues = {
    role_id: "",
    role_name: "",
    role_status: "",
  };

  const [formRole, setFormRole] = useState(initialValues);

  const handleDrawer = (type, id, obj) => {
    if ((type === "edit", id)) {
      setRoleid(id);
      const initialValues = {
        role_id: obj.role_id,
        role_name: obj.role_name,
        role_is_active: obj.role_is_active,
      };
      setFormRole(initialValues);
    } else {
      setRoleid("");
      setFormRole(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };
  const onDeleteOpen = (role_id) => {
    setConfirmationModal({ role_id, status: true });
  };

  const onDeleteRole = (role_id) => {
    dispatch(deleteRole(role_id));
    dispatch(getAllRoles(3));
    setConfirmationModal({ role_id: null, status: false });
  };

  const saveMaxDiscountValue = async (body) => {
    
    await MasterServices.saveRoleMaxDiscount(body)
      .then((res) => {
        const { data, status } = res;
        
        if (status === 200 && data.data) {
          dispatch(getAllRoles(3));
          dispatch(getLoginUserInfo("All"));
          toast.success("Max Discount Updated");
        }
      })
      .catch((err) => {
        toast.error(err.data.message);
        
      });
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formRole,
      validationSchema: roleSchema,
      onSubmit: (values, action) => {
        
        let body = {
          role_id: role_id,
          role_is_active: true,
          role_name: values.role_name,
        };
        if (role_id === undefined || role_id === null || role_id === "")
          dispatch(addRole(body));
        else dispatch(updateRole(role_id, body));
        action.resetForm();
        dispatch(getAllRoles(3));
        if (modalOpenFlage === true) {
          dispatch(getAllRoles(3));
          setmodalOpenFlage(false);
        }
        navigate("/role");
      },
    });
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteRole(confirmationModal.role_id)}
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Role List</h1>
        <p className="mt-2 text-sm text-gray-700">A list of all the Roles.</p>
      </div>
      <div className="mt-4 flex">
        {allowedActions.filter((data) => data.permission_id == 7)?.length >
        0 ? (
          <Link
            onClick={() => handleDrawer("add", "", {})}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#fd6e1d] to-[#ef6122] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
            >
            Add Role
          </Link>
        ) : null}
      </div>

      {loading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={role_columns({
            onDeleteOpen,
            handleDrawer,
            saveMaxDiscountValue,
            allowedActions,
          })}
          data={roleList}
        />
      )}

      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          
                          handleSubmit(e);
                        }}
                        className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                      >
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-gradient-to-b from-[#872341] to-[#DB7D0B] py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {role_id ? "Update" : "Add"} Role
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md bg-gradient-to-b from-[#872341] to-[#DB7D0B] text-white hover:text-white"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-1 flex-col justify-between">
                            <div className="divide-y divide-gray-200 p-4 sm:p-6">
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="role_name"
                                    className="block text-sm font-medium text-gray-900 mt-2 mb-1"
                                  >
                                    Enter Role Name
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.role_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Enter Role Name"
                                    name="role_name"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.role_name && touched.role_name ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.role_name}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#DB7D0B] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                          >
                            {role_id ? "Update" : "Add"}
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
}
export default RoleList;
