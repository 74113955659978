import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { buisnessObjectiveSchema, roleSchema, searcherProductSchema, tagSchema } from "../../../schemas";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/tables/table";
// import { useDispatch } from "react-redux";
import { buisness_objective_columns, categories_columns, role_columns, searcher_product_columns, sub_categories_columns, tag_columns } from "../../../components/tables/tableheader";
import { Dialog, Transition } from "@headlessui/react";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import MasterServices from "../../../ApiServices/MasterServices";
import SearcherMasterServices from "../../../ApiServicesSearcher/MasterServices";
import { toast } from "react-toast";
import { getLoginUserInfo } from "../../../redux/userInfo/actions";

function List() {
	const pages = [{ title: "Searcher Product List", href: "/Searcher Product", module_id: 3 }];
	const [modalOpenFlage, setmodalOpenFlage] = useState(false);
	const [loading, setLoading] = useState(false);
	const [confirmationModal, setConfirmationModal] = useState({
		status: false,
		business_objective_id: null,
	});
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [drawerID, setDrawerID] = useState();
	const [list, setList] = useState([ ])
	useEffect(() => {
		document.title = "Searcher | Searcher Product";
		getAllData()
	}, []);

	const initialValues = {
		business_objective_id: "",
		name: "",
	};

	const [form, setForm] = useState(initialValues);

	const getAllData = async() => {
		setLoading(true)
		await SearcherMasterServices.getAllProducts()
		.then((res) => {
		  const { data, status } = res;
		  
		  setList(data)
		  setLoading(false)
		})
		.catch((err) => {
		  toast.error(err.data?.message);
		  
		  setLoading(false)

		});
	}
	
	const handleDrawer = (type, id, obj) => {
		if ((type === "edit", id)) {
			setDrawerID(id);
			const initialValues = {
				business_objective_id: obj.business_objective_id,
				name: obj.name,
			};
			setForm(initialValues);
		} else {
			setDrawerID("");
			setForm(initialValues);
		}
		if (modalOpenFlage === false) {
			setmodalOpenFlage(true);
		}
	};
	const onDeleteOpen = (business_objective_id) => {
		setConfirmationModal({ business_objective_id, status: true });
	};

	const onDelete = (business_objective_id) => {
		setConfirmationModal({ business_objective_id: null, status: false });
	};


	const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
		useFormik({
			enableReinitialize: true,
			initialValues: form,
			validationSchema: searcherProductSchema,
			onSubmit: (values, action) => {
				
				let body = {
					business_objective_id: drawerID,
					name: values.name
				};
				if (drawerID === undefined || drawerID === null || drawerID === "")
					// dispatch(addRole(body));
				// else dispatch(updateRole(role_id, body));
				action.resetForm();
				getAllData();
				if (modalOpenFlage === true) {
					getAllData()
					setmodalOpenFlage(false);
				}
				navigate("/business-objective");
			},
		});
	return (
		<div className="px-4 sm:px-6 lg:px-8">
			<ConfirmationModal
				open={confirmationModal.status}
				setOpen={setConfirmationModal}
				onDelete={() => onDelete(confirmationModal.business_objective_id)}
			/>
			<Breadcrumb pages={pages} />
			<div className="">
				<h1 className="text-xl font-semibold text-gray-900">Searcher Product List</h1>
				<p className="mt-2 text-sm text-gray-700">A list of all the Searcher Product.</p>
			</div>
			<div className="mt-4 flex">
				<Link
					onClick={() => handleDrawer("add", "", {})}
					type="button"
					className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#DB7D0B] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
				>
					Add Searcher Product
				</Link>
			</div>

			{loading ? (
				<FallingLinesLoader />
			) : (
				<Table
					columns={searcher_product_columns({ onDeleteOpen, handleDrawer })}
					data={list}
				/>
			)}

			<div>
				<Transition.Root show={modalOpenFlage} as={Fragment}>
					<Dialog
						as="div"
						className="relative z-10"
						onClose={setmodalOpenFlage}
					>
						<div className="fixed inset-0" />
						<div className="fixed inset-0 overflow-hidden">
							<div className="absolute inset-0 overflow-hidden">
								<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
									<Transition.Child
										as={Fragment}
										enter="transform transition ease-in-out duration-500 sm:duration-700"
										enterFrom="translate-x-full"
										enterTo="translate-x-0"
										leave="transform transition ease-in-out duration-500 sm:duration-700"
										leaveFrom="translate-x-0"
										leaveTo="translate-x-full"
									>
										<Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
											<form
												onSubmit={(e) => {
													e.preventDefault();
													
													handleSubmit(e);
												}}
												className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
											>
												<div className="h-0 flex-1 overflow-y-auto">
													<div className="bg-gradient-to-b from-[#872341] to-[#DB7D0B] py-6 px-4 sm:px-6">
														<div className="flex items-center justify-between">
															<Dialog.Title className="text-lg font-medium text-white">
																{drawerID ? "Update" : "Add"} Objective
															</Dialog.Title>
															<div className="ml-3 flex h-7 items-center">
																<button
																	type="button"
																	className="rounded-md bg-gradient-to-b from-[#872341] to-[#DB7D0B] text-white hover:text-white"
																	onClick={() => setmodalOpenFlage(false)}
																>
																	<span className="sr-only">Close panel</span>
																	<XMarkIcon
																		className="h-6 w-6"
																		aria-hidden="true"
																	/>
																</button>
															</div>
														</div>
													</div>
													<div className="flex flex-1 flex-col justify-between">
														<div className="divide-y divide-gray-200 p-4 sm:p-6">
															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="name"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Objective Name
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<input
																		value={values.name}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="text"
																		placeholder="Enter Objective Name"
																		name="name"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.name &&
																		touched.name ? (
																		<p className="text-red-600 text-sm">
																			{errors.name}
																		</p>
																	) : null}
																</div>
															</div>

														</div>
													</div>
												</div>
												<div className="flex flex-shrink-0 justify-end px-4 py-4">
													<button
														type="button"
														className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
														onClick={() => {
															setmodalOpenFlage(false);
														}}
													>
														Cancel
													</button>
													<button
														type="submit"
														className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#DB7D0B] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
													>
														{drawerID ? "Update" : "Add"}
													</button>
												</div>
											</form>
										</Dialog.Panel>
									</Transition.Child>
								</div>
							</div>
						</div>
					</Dialog>
				</Transition.Root>
			</div>
		</div>
	);
}
export default List;
