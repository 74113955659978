import { toast } from "react-toast";
import {
  GET_USER_INFO_BEGIN,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_ERROR,
} from "./constants";
import Api from "../../ApiServices/Api";
import AuthToken from "../../ApiServices/AuthTokenServices";

export const getLoginUserInfo = (payload) => async (dispatch) => {

  try {
    await AuthToken.getCurrentAuth();
    dispatch({
      type: GET_USER_INFO_BEGIN,
    });
    const { data } = await Api().get(`/api/dashboard/user/${payload}`);

    dispatch({
      type: GET_USER_INFO_SUCCESS,
      data: data ? data?.data : {},
    });
  } catch (error) {
    // 
    // 
    const msg = error?.response?.data?.message;
    toast.error(msg);
    dispatch({
      type: GET_USER_INFO_ERROR,
      error: msg,
    });
  }
};
