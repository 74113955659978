import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/tables/table";
import { user_columns } from "../../../components/tables/tableheader";
import { Switch } from "@headlessui/react";
import {
  deleteUser,
  getAllUsers,
  addUser,
  updateUser,
} from "../../../redux/user/actions";

import {
  getAllowedActions,
  checkPermissionsAndRedirect,
  exportToExcel,
} from "../../../helper/commonHelper";

import { getAllRoles } from "../../../redux/role/actions";
import { Dialog, Transition } from "@headlessui/react";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import { singleUploadFiles } from "../../../helper/multiUploadHelper";
import { FileUploader } from "react-drag-drop-files";
import PhoneInputWithCountry, {
  isValidPhoneNumber,
  parsePhoneNumber,
  getCountryCallingCode,
} from "react-phone-number-input";
import { FaSpinner } from "react-icons/fa";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import { userEditSchema, userSchema } from "../../../schemas";
import queryString from "query-string";

const fileTypes = ["JPEG", "PNG", "GIF"];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function UserList() {
  const pages = [{ title: "User List", href: "/user-list", module_id: 2 }];
  const animatedComponents = makeAnimated();
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  // const [signProgress, setSignProgress] = useState();
  const [signFileName, setSignFileName] = useState(false);
  // const [signFileNamePath, setSignFilePath] = useState('');
  const [user_id, setUserid] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [userMobileNumber, setUserMobileNumber] = useState("");
  const [mobileNumberValid, setMobileNumberValid] = useState(true);
  const [mobileCountryCode, setMobileCountryCode] = useState("");
  const [initialRender, setInitialRender] = useState(true);
  const [banner, setBanner] = useState(null);
  const [tempBanner, setTempBanner] = useState(null);
  const [base64banner, setBase64banner] = useState(null);
  const [enabled, setEnabled] = useState(false);
  const [enabledSourceProvider, setEnabledSourceProvider] = useState(false);
  const [hidePassInput, setHidePassInput] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [password, setPassword] = useState('');
  const [displayPassword, setDisplayPassword] = useState('');
  const [localUserData, setLocalUserData] = useState('');


  const [selectedUserRoleId, setSelectedUserRoleId] = useState({
    value: "",
    label: "",
  });
  let User_ACtive_Tab = 0;
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    { name: "All Users", href: "#", current: activeTab === 0 },
    { name: "HR Professional", href: "#", current: activeTab === 1 },
    { name: "HR Service Provider", href: "#", current: activeTab === 2 },
    { name: "Frontend", href: "#", current: activeTab === 3 },
  ];

  const userTypes = [
    { value: "all_users", label: "All Users", index: 0 },
    { value: "HR Professional", label: "HR Professional", index: 1 },
    { value: "HR Service Provider", label: "HR Service Provider", index: 2 },
    { value: "frontend", label: "Frontend", index: 3 },
  ];

  const [selectedUserType, setselectedUserType] = useState(null);
  const [filteredData, setfilteredData] = useState(null);
  console.log(filteredData);

  const [filters, setFilters] = useState({
    role: null,
    startDate: null,
    endDate: null,
  });
  //console.log(filters);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    user_id: null,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    user: { loading: userLoading, list: userList },
    role: { list: roleList },
  } = useSelector((state) => state);
  //console.log(userList);

  const [pathname, setPathname] = useState("");
  const [allowedActions, setAllowedActions] = useState([]);
  const [tabCurrent, setTabCurrent] = useState(true);
  useEffect(() => {
    setPathname(window.location.pathname);
  }, []);

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData) {
      console.log("userData=>",userData)
      setLocalUserData(userData);
    }
    //
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(
        window.location.pathname,
        permissions,
        navigate
      );
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);

  //Mobile number validation in patient
  useEffect(() => {
    if (!initialRender) {
      //
      if (mobileNumber && isValidPhoneNumber(mobileNumber.toString())) {
        const phoneNumber = parsePhoneNumber(mobileNumber);

        if (phoneNumber) {
          setMobileCountryCode(getCountryCallingCode(phoneNumber.country));
          setUserMobileNumber(phoneNumber.nationalNumber);
        }
        setMobileNumberValid(true);
      } else {
        setMobileNumberValid(false);
      }
    } else {
      setInitialRender(false);
    }
  }, [mobileNumber]);

  const onMobileNumberChange = (value) => {
    if (value) {
      setMobileNumber(value);
    }
  };

  useEffect(() => {
    document.title = "Octopus | Users";
    setIsLoading(true);
    let payload = {
      user_is_hr_profesional: User_ACtive_Tab === 1 ? 1 : 2,
      user_is_hr_source_provider: User_ACtive_Tab === 2 ? 1 : 2,
      is_all_user: User_ACtive_Tab === 0 ? 1 : 2,
      user_is_cms: User_ACtive_Tab === 3 ? 1 : 2,
      user_is_active: 3,
    };
    dispatch(getAllUsers(payload));
    dispatch(getAllRoles(1));
    setIsLoading(false);
  }, []);

  // const userSchema = Yup.object({
  //   user_username: Yup.string()
  //     .min(3)
  //     .max(25)
  //     .required("Please Enter the Username"),
  //   user_first_name: Yup.string()
  //     .min(3)
  //     .max(25)
  //     .required("Please Enter the First Name"),
  //   user_last_name: Yup.string()
  //     .min(2)
  //     .max(25)
  //     .required("Please Enter the Last Name"),
  //   user_email: Yup.string().email().required("Please Enter the Email address"),
  //   user_password: user_id
  //     ? Yup.string()
  //     : Yup.string().min(6).required("Please Enter the Password"),
  //   user_role: Yup.string().required("Please Enter the Role"),
  //   user_company_name: Yup.string().required("Please Enter the Company name"),
  //   user_mobile: Yup.number().required("Please Enter the Mobile number"),
  //   mc_id: Yup.string().when("user_role", {
  //     is: "13",
  //     then: Yup.string().required("Please Select Clinic"),
  //     otherwise: Yup.string().nullable(),
  //   }),
  //   user_profile_image: Yup.mixed().test(
  //     "user_profile_image",
  //     "Please Choose a image",
  //     function (value) {
  //       const image = this.resolve(Yup.ref("user_profile_image")); // Replace with actual path field name
  //       if (!image && !value) {
  //         return this.createError({
  //           message: "profile image is required",
  //           path: "user_profile_image",
  //         });
  //       }

  //       return true;
  //     }
  //   ),
  // });

  let initialValues = {
    user_id: "",
    user_first_name: "",
    user_last_name: "",
    user_email: "",
    user_mobile: "",
    user_role: "",
    user_password: "",
    user_company_name: "",
    user_profile_image: "",
    user_status: true,
    user_is_hr_profesional: false,
    user_is_hr_source_provider: false,
    user_access_is_pro: 0,
  };

  const [formUser, setFormUser] = useState(initialValues);

  const handleDrawer = (type, id, obj) => {
    setSelectedUserRoleId("");
    setBase64banner(null);
    setBanner(null);
    setTempBanner(null);
    values.user_role = "";
    setMobileNumberValid(true);

    if ((type === "edit", id)) {
      setUserid(id);
      setHidePassInput(true);
      setImageUrl(obj?.user_profile_image_baseurl);
      setTempBanner(obj?.user_profile_image);
      let mergedPhone = `+${obj.user_dial_code}${obj.user_mobile}`;

      if (
        obj.user_mobile &&
        obj.user_dial_code &&
        isValidPhoneNumber(`+${obj.user_dial_code}${obj.user_mobile}`)
      ) {
        const phoneNumber = parsePhoneNumber(
          `+${obj.user_dial_code}${obj.user_mobile}`
        );

        if (phoneNumber) {
          setMobileCountryCode(getCountryCallingCode(phoneNumber.country));
          setMobileNumber(phoneNumber.number);
        }
      }
      const initialValues = {
        user_id: obj?.user_id,
        user_first_name: obj?.user_first_name,
        user_last_name: obj?.user_last_name,
        user_email: obj?.user_email,
        user_company_name: obj?.user_company_name,
        user_mobile: obj?.user_mobile,
        user_role: obj?.fk_role_id,
        user_status: obj?.user_is_active,
        user_profile_image: obj?.user_profile_image,
        user_is_hr_profesional: obj?.user_is_hr_profesional,
        user_is_hr_source_provider: obj?.user_is_hr_source_provider,
        user_access_is_pro: obj?.user_access_is_pro,
      };

      if (Array.isArray(roleList) && roleList.length > 0) {
        let role = roleList.filter(
          (v) => v.role_id == initialValues?.user_role
        );
        if (role.length > 0) {
          setSelectedUserRoleId({
            value: role[0].role_id,
            label: role[0].role_name,
          });
        }
      }

      setFormUser(initialValues);
    } else {
      setMobileNumber("");
      setHidePassInput(false);

      setUserid("");
      setFormUser(initialValues);
      setBase64banner(null);
      setBanner(null);
      setTempBanner(null);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };

  const onDeleteOpen = (user_id, user_is_active) => {
    setConfirmationModal({ user_id, user_is_active, status: true });
  };

  // const handleFileChange = async (field, file) => {
  // 	const response = await singleUploadFiles(file, 'users');
  // 	if (field == 'sign') {
  // 		setSignFilePath(response.data);
  // 		setSignProgress(response.progress);
  // 	}
  // 	// setFile(e.target.files[0]);
  // };

  const setSelectedRole = async (events) => {
    let cid = events.value ? events.value : null;
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formUser,
      validationSchema: user_id ? userEditSchema : userSchema,
      onSubmit: async (values, action) => {

        let body = {
          user_id: user_id,
          user_first_name: values.user_first_name,
          user_last_name: values.user_last_name,
          user_email: values.user_email,
          user_mobile: values.user_mobile,
          dial_code: 91,
          user_role: values.user_role,
          user_password: values.user_password,
          user_company_name: values.user_company_name,
          user_profile_image: base64banner ? base64banner : null,
          user_status: true,
          user_is_hr_profesional: enabled,
          user_is_hr_source_provider: enabledSourceProvider,
          user_access_is_pro: values.user_access_is_pro,
        };

        //return;

        if (
          body.user_first_name != undefined ||
          body.user_first_name != null ||
          body.user_first_name != ""
        ) {
          // setIsLoading(true)
          //console.log(body, "body")
          // return 
          setTimeout(() => {
            setDisableSubmitButton(false);
          }, 15000);
          setDisableSubmitButton(true);


          let { data, status } = await MasterServices.saveMUser(body);

          if (status === 200) {
            if (data.status) {
              action.resetForm();
              setMobileNumber("");
              setMobileCountryCode("");
              let payload = {
                user_is_hr_profesional: User_ACtive_Tab === 1 ? 1 : 2,
                user_is_hr_source_provider: User_ACtive_Tab === 2 ? 1 : 2,
                is_all_user: User_ACtive_Tab === 0 ? 1 : 2,
                user_is_cms: User_ACtive_Tab === 3 ? 1 : 2,
                user_is_active: 3,
              };
              dispatch(getAllUsers(payload));
              setIsLoading(false);
              setmodalOpenFlage(false);
              setDisableSubmitButton(false);
              setBanner(null);
              setTempBanner(null);
              setBase64banner(null);
              setActiveTab(0);
            } else {
              setErrorMessage(data.message);
              setTimeout(() => {
                setErrorMessage("");
              }, 5000);
              setIsLoading(false);
              setDisableSubmitButton(false);
            }
          } else {
            setDisableSubmitButton(false);
          }
        } else {
          setDisableSubmitButton(false);

          action.resetForm();
          if (modalOpenFlage === true) {
            let payload = {
              user_is_hr_profesional: User_ACtive_Tab === 1 ? 1 : 2,
              user_is_hr_source_provider: User_ACtive_Tab === 2 ? 1 : 2,
              is_all_user: User_ACtive_Tab === 0 ? 1 : 2,
              user_is_cms: User_ACtive_Tab === 3 ? 1 : 2,
              user_is_active: 3,
            };
            dispatch(getAllUsers(payload));
            setmodalOpenFlage(false);
          }
          setDisableSubmitButton(false);
        }
      },
    });

  const onDeleteUser = (user_id) => {
    dispatch(deleteUser(user_id));
    let payload = {
      user_is_hr_profesional: User_ACtive_Tab === 1 ? 1 : 2,
      user_is_hr_source_provider: User_ACtive_Tab === 2 ? 1 : 2,
      is_all_user: User_ACtive_Tab === 0 ? 1 : 2,
      user_is_cms: User_ACtive_Tab === 3 ? 1 : 2,
      user_is_active: 3,
    };
    dispatch(getAllUsers(payload));
    setConfirmationModal({ user_id: null, status: false });
  };

  const handleTabClick = (index) => {
    User_ACtive_Tab = index;
    setActiveTab(index);
    let payload = {
      user_is_hr_profesional: User_ACtive_Tab === 1 ? 1 : 2,
      user_is_hr_source_provider: User_ACtive_Tab === 2 ? 1 : 2,
      is_all_user: User_ACtive_Tab === 0 ? 1 : 2,
      user_is_cms: User_ACtive_Tab === 3 ? 1 : 2,
      user_is_active: 3,
    };
    dispatch(getAllUsers(payload));
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    // const fileName = file.name;
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setBanner(file);
        setTempBanner(e.target.result);
        setBase64banner(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setTempBanner(null)
    }


  };

  let mutableFilteredUsersData = userList;
  //console.log(mutableFilteredUsersData);

  useEffect(() => {

    if (filters.role) {
      //console.log(filters.role);
      if (filters.role === "all_users") {
        //console.log("inloop");

        mutableFilteredUsersData = [...userList];
      } else if (filters.role === "frontend") {
        //console.log("inloop");
        mutableFilteredUsersData = mutableFilteredUsersData.filter(
          (user) =>
            user.m_role_relation &&
            (user.m_role_relation.role_id === 5 ||
              user.m_role_relation.role_id === 6)
        );
      } else {
        mutableFilteredUsersData = mutableFilteredUsersData.filter((user) => {
          return user.m_role_relation &&
            user.m_role_relation.role_name === filters.role;
        });
      }
    }

    if (filters.startDate || filters.endDate) {
      const startDate = filters.startDate
        ? new Date(filters.startDate)
        : new Date("1000-01-01");
      const endDate = filters.endDate ? new Date(filters.endDate) : new Date();

      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);

      mutableFilteredUsersData = mutableFilteredUsersData.filter((user) => {
        const userCreatedDate = new Date(user.user_created_at);
        return userCreatedDate >= startDate && userCreatedDate <= endDate;
      });
    }

    setfilteredData(mutableFilteredUsersData);
  }, [filters, userList]);



  const clearForm = () => {
    setEndDate("");
    setStartDate("");
    setselectedUserType(null);
    setFilters({
      role: null,
      startDate: null,
      endDate: null,
    });
    dispatch(getAllUsers(0));
  };



  const downloadUserDataExcel = () => {
    const dataToExport = filteredData;

    // Utility functions for data formatting
    const capitalise = (str) => {
      return (str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase()) ?? '';
    };

    const formatDate = (dateString) => {
      if (!dateString) {
        return "----";
      }
      return new Date(dateString).toLocaleString("en-US", {
        timeZone: "Asia/Kolkata",
      });
    };

    const columns = [
      {
        Header: "ID",
        accessor: "user_id",
      },
      {
        Header: "Name",
        accessor: (d) => capitalise(d.user_first_name || "") + " " + capitalise(d?.user_last_name || ""),
      },
      {
        Header: "Email",
        accessor: "user_email",
      },
      {
        Header: "Mobile Number",
        accessor: "user_mobile",
      },
      {
        Header: "Role Type",
        accessor: (d) => capitalise(d?.m_role_relation?.role_name || ''),
      },
      {
        Header: "User Designation",
        accessor: "user_designation",
      },
      {
        Header: "User Company",
        accessor: "user_company_name",
      },
      {
        Header: "User OrganisationSize",
        accessor: "user_organisation_size",
      },
      {
        Header: "Created At",
        accessor: (d) => formatDate(d.user_updated_at),
      },
      // {
      //   Header: "Status",
      //   accessor: (d) => d.user_is_active ? "Active" : "Inactive",
      // },
    ];

    // Prepare data for Excel export
    const headings = [columns.map(col => col.Header)];
    const transformedData = dataToExport.map(item => {
      const row = {};
      columns.forEach(col => {
        const value = typeof col.accessor === "function" ? col.accessor(item) : item[col.accessor];
        row[col.Header] = value || '';
      });
      return row;
    });

    // Assuming you have an `exportToExcel` function similar to before
    exportToExcel(transformedData, headings, "UsersExport.xlsx", "Users");
  };


  return isLoading ? (
    <FallingLinesLoader />
  ) : (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        title={
          "Are you sure you want to " +
          (confirmationModal.user_is_active ? "Disable " : "Activate") +
          " this User?"
        }
        confirmationButtonText="Yes"
        open={confirmationModal.status}
        onDelete={() =>
          onDeleteUser(
            confirmationModal.user_id,
            confirmationModal.user_is_active
          )
        }
        setOpen={setConfirmationModal}
      />
      <Breadcrumb pages={pages} />
      <div className=" flex items-center justify-between my-2">
        <div>
          <h1 className="text-xl font-semibold text-gray-900">User List</h1>
          <p className="mt-2 text-sm text-gray-700">A list of all the Users.</p>
        </div>
        <Link
          onClick={() => handleDrawer("add", "", {})}
          type="button"
          className=" h-9 inline-flex items-center justify-center rounded-md border border-transparent  bg-gradient-to-b from-[#fd6e1d] to-[#ef6122] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
        >
          Add User
        </Link>
      </div>
      <div className="pt-4 w-full shadow-sm rounded-md h-auto border-2 p-4">
        <div className=" w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              User Type
            </label>
            <div>
              <Select
                classNamePrefix="select"
                defaultValue={userTypes[0]}
                placeholder="Select"
                value={selectedUserType ? selectedUserType : userTypes[0]}
                // // onBlur={handleBlur}
                name="userStatus"
                className="basic-single sm:text-sm"
                options={userTypes}
                onChange={(selectedOption) => {
                  setselectedUserType(selectedOption);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    role: selectedOption.value,
                  }));
                  // handleTabClick(selectedOption.index);
                }}
              />{" "}
              {/* {errors.enterprise && touched.enterprise ? (
                    <p className="text-red-600 text-sm">{errors.enterprise}</p>
                  ) : null} */}
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Start Date
            </label>
            <div className="flex">
              <input
                type="date"
                name="date"
                value={startDate}
                onChange={(e) => {
                  const startDate = e.target.value;
                  setStartDate(startDate);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    startDate: startDate,
                  }));
                }}
                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              End Date
            </label>
            <div className="flex">
              <input
                type="date"
                name="date"
                value={endDate}
                onChange={(e) => {
                  const endDate = e.target.value;
                  setEndDate(endDate);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    endDate: endDate,
                  }));
                }}
                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </div>
        <div className=" pt-4 ">
          <button
            onClick={clearForm}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#fd6e1d] to-[#ef6122] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
          >
            Clear
          </button>
        </div>
      </div>
      <div className=" w-full mt-4 flex justify-end">
        <button
          type="button"
          onClick={downloadUserDataExcel}
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#fd6e1d] to-[#ef6122] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
        >
          Download Excel
        </button>
      </div>
      <div className="mt-4 flex gap-x-2 items-center ">
        {/* {allowedActions.filter((data) => data.permission_id == 2)?.length >
        0 ? (
          <Link
            onClick={() => handleDrawer("add", "", {})}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#DB7D0B] to-[#872341] px-4 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
          >
            Add User
          </Link>
        ) : null} */}
        <div className="w-[600px]">
          {/* <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            <select
              id="tabs"
              name="tabs"
              className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
              defaultValue={tabs.find((tab) => tab.current).name}
            >
              {tabs.map((tab) => (
                <option key={tab.name}>{tab.name}</option>
              ))}
            </select>
          </div> */}
          {/* <div className="hidden sm:block ">
            <nav
              className="isolate flex divide-x divide-gray-200 rounded-lg shadow whitespace-nowrap"
              aria-label="Tabs"
            >
              {tabs.map((tab, tabIdx) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  onClick={() => handleTabClick(tabIdx)}
                  className={classNames(
                    tab.current
                      ? " bg-gradient-to-b from-[#DB7D0B] to-[#872341] text-white"
                      : "text-gray-500 hover:text-gray-700",
                    tabIdx === 0 ? "rounded-l-lg" : "",
                    tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                    "group relative min-w-0 flex-1 overflow bg-white py-2.5 px-2 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
                  )}
                >
                  <span>{tab.name}</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      tab.current ? "bg-indigo-500" : "bg-transparent",
                      "absolute inset-x-0 bottom-0 h-0.5"
                    )}
                  />
                </a>
              ))}
            </nav>
          </div> */}
        </div>
      </div>

      {userLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={user_columns({ onDeleteOpen, handleDrawer, allowedActions })}
          data={filteredData ? filteredData : userList}

        />
      )}

      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit(e);
                        }}
                        className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                      >
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-gradient-to-b from-[#DB7D0B] to-[#872341] py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {user_id ? "Update" : "Add"} User
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md  text-cyan-200 hover:text-white"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-1 flex-col justify-between">
                            <div className="p-4 sm:p-6">
                              {roleList && roleList.length > 0 ? (
                                <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                  <div>
                                    <label className="block text-sm font-medium text-gray-900 mt-2 mb-1">
                                      Select Role
                                      <span className="text-red-600">*</span>
                                    </label>
                                  </div>
                                  <Select
                                    isDisabled={(formUser?.user_id==localUserData?.user_id && localUserData?.role_id==1)?true:false}
                                    name="role_name"
                                    id="role_id"
                                    placeholder={
                                      <span>
                                        Select Role
                                        <span className="text-red-600">*</span>
                                      </span>
                                    }
                                    value={selectedUserRoleId}
                                    onChange={(e) => {
                                      values.user_role = e.value;
                                      setSelectedUserRoleId(e);
                                      setSelectedRole(e);
                                    }}
                                    // onBlur={handleBlur}
                                    components={animatedComponents}
                                    options={
                                      roleList
                                        ? roleList.map((v) => ({
                                          value: v.role_id,
                                          label: v.role_name,
                                        }))
                                        : []
                                    }
                                    classNamePrefix="select"
                                    className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />

                                  {errors.user_role && touched.user_role ? (
                                    <div className="text-sm text-red-600">
                                      {errors.user_role}
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label className="block text-sm font-medium text-gray-900 mb-1">
                                    First Name
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.user_first_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="First Name"
                                    name="user_first_name"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.user_first_name &&
                                    touched.user_first_name ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.user_first_name}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label className="block text-sm font-medium text-gray-900 mt-2 mb-1">
                                    Last Name
                                    {/* <span className="text-red-600">*</span> */}
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.user_last_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Last Name"
                                    name="user_last_name"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.user_last_name &&
                                    touched.user_last_name ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.user_last_name}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label className="block text-sm font-medium text-gray-900 mt-2 mb-1">
                                    User Email
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.user_email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    // type="email"
                                    placeholder="User Email"
                                    name="user_email"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                    disabled={user_id ? true : false}
                                  />
                                  {errors.user_email && touched.user_email ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.user_email}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div
                                className={
                                  hidePassInput
                                    ? "hidden"
                                    : "mt-2 mb-2 sm:col-span-2 sm:mt-0"
                                }
                              >
                                <div>
                                  <label className="block text-sm font-medium text-gray-900 mt-2 mb-1">
                                    Password
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={displayPassword}
                                    onChange={(e) => {
                                      if (e.target.value == '') {
                                        //console.log("body", 'is empty');
                                        setPassword('');
                                        setDisplayPassword('');
                                        handleChange(e);
                                        return
                                      }
                                      if (e.target.value.length < displayPassword.length) {
                                        const value = e.target.value;
                                        e.target.value = password?.slice(0, -1);
                                        setPassword(password?.slice(0, -1));
                                        setDisplayPassword('*'.repeat(value.length));

                                        handleChange(e);
                                        return
                                      }
                                      const value = e.target.value;
                                      e.target.value = (password + value?.replace(/\*/g, ''));
                                      setPassword((password + value?.replace(/\*/g, '')));
                                      setDisplayPassword('*'.repeat(value.length));

                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    // type="password"
                                    placeholder="Enter Password"
                                    name="user_password"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.user_password &&
                                    touched.user_password ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.user_password}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label className="block text-sm font-medium text-gray-900 mb-1">
                                    Company
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.user_company_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Enter Company"
                                    name="user_company_name"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.user_company_name &&
                                    touched.user_company_name ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.user_company_name}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label className="block text-sm font-medium text-gray-900 mb-1">
                                    Mobile Number
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.user_mobile}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="number"
                                    placeholder="Enter Mobile Number"
                                    name="user_mobile"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.user_mobile && touched.user_mobile ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.user_mobile}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              {selectedUserRoleId.value == 6 ? (
                                <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                  <div>
                                    <label className="block text-sm font-medium text-gray-900 mb-1">
                                      Access Level
                                      <span className="text-red-600"></span>
                                    </label>
                                  </div>
                                  <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                    <select
                                      value={values.user_access_is_pro}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      name="user_access_is_pro"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                    >
                                      <option value="">
                                        Select Access Level
                                      </option>
                                      <option value="1">Basic</option>
                                      <option value="2">Pro</option>
                                    </select>
                                    {errors.user_access_is_pro &&
                                      touched.user_access_is_pro ? (
                                      <p className="text-red-600 text-sm">
                                        {errors.user_access_is_pro}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label className="block text-sm font-medium text-gray-900 mb-1">
                                    Upload Profile
                                    {/* <span className="text-red-600">*</span> */}
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    onChange={(e) => {
                                      if (e) {
                                        handleChange(e);
                                        onFileChange(e);
                                      } else {
                                        setTempBanner(null)
                                      }

                                    }}
                                    onBlur={handleBlur}
                                    type="file"
                                    name="user_profile_image"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.user_profile_image &&
                                    touched.user_profile_image ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.user_profile_image}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="col-span-full mb-5">
                                {tempBanner ? (
                                  <img
                                    className="shadow-sm mt-4 w-40"
                                    src={
                                      user_id
                                        ? imageUrl + tempBanner
                                        : tempBanner
                                    }
                                    alt="preview-banner"
                                  />
                                ) : null}
                              </div>
                              {/* <div className="my-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="magazine_is_active"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    HR_Source_Professional
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <Switch
                                    checked={enabled}
                                    onChange={setEnabled}
                                    className={classNames(
                                      enabled ? "bg-[#872341]" : "bg-gray-200",
                                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                                    )}
                                  >
                                    <span className="sr-only">Use setting</span>
                                    <span
                                      aria-hidden="true"
                                      className={classNames(
                                        enabled
                                          ? "translate-x-5"
                                          : "translate-x-0",
                                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                      )}
                                    />
                                  </Switch>
                                </div>
                              </div>
                              <div className="my-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="magazine_is_active"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    HR_Source_Provider
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <Switch
                                    checked={enabledSourceProvider}
                                    onChange={setEnabledSourceProvider}
                                    className={classNames(
                                      enabledSourceProvider
                                        ? "bg-[#872341]"
                                        : "bg-gray-200",
                                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                                    )}
                                  >
                                    <span className="sr-only">Use setting</span>
                                    <span
                                      aria-hidden="true"
                                      className={classNames(
                                        enabledSourceProvider
                                          ? "translate-x-5"
                                          : "translate-x-0",
                                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                      )}
                                    />
                                  </Switch>
                                </div>
                              </div> */}

                              {/* <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
																	<div className="basis-1/2">
																		<label htmlFor="hos_long" className="block text-sm font-medium text-gray-700">
																			Signature
																		</label>
																		<FileUploader
																			multiple={false}
																			types={fileTypes}
																			handleChange={file => handleFileChange('sign', file)}
																			name="file"
																		/>
																		{signFileNamePath ? (<img width={150} height={100} src={signFileNamePath} alt={values.user_first_name} />) : (<></>)}


																		{signProgress ? (<><div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 mt-2">
																			<div className="bg-cyan-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full mt-1"> {`${signProgress}%`}</div>
																		</div></>) : (<></>)}
																	</div>
																</div> */}
                              <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                <div className="basis-1/2 text-red-500 text-sm">
                                  {errorMessage !== "" &&
                                    "Error: " + errorMessage}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            disabled={disableSubmitButton}
                            type="submit"
                            className={`ml-4 inline-flex justify-center rounded-md border border-transparent bg-gradient-to-b from-[#DB7D0B] to-[#872341] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ${disableSubmitButton
                                ? "bg-gray-700 hover:bg-gray-700"
                                : "bg-cyan-700"
                              }`}
                          >
                            {user_id ? (
                              disableSubmitButton ? (
                                <FaSpinner color="gold" />
                              ) : (
                                "Update"
                              )
                            ) : disableSubmitButton ? (
                              <FaSpinner color="gold" />
                            ) : (
                              "Add"
                            )}
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
}
export default UserList;
