import React, { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useFormik } from "formik";
import Table from "../../../../components/tables/table";
import {
  FallingLinesLoader,
  OvalLoader,
} from "../../../../components/spinners/Spinner";
import Select from "react-select";
import { XMarkIcon } from "@heroicons/react/20/solid";
import {
  searcherProductStepOneSchema,
  searcherProductStepThreeSchema,
} from "../../../../schemas";
import SearcherMasterServices from "../../../../ApiServicesSearcher/MasterServices";
import MasterServices from "../../../../ApiServices/MasterServices";
import { toast } from "react-toast";
const Step5 = ({
  tabId,
  setTabId,
  setAllStepsData,
  allStepsData,
  editProductData,
}) => {
  const navigate = useNavigate();
  const { product_id: product_id } = useParams();
  const [companyOptions, setCompanyOptions] = useState(null);
  const [filterCompany, setFilterCompany] = useState(null);
  const [filterPricing, setFilterPricing] = useState(null);
  const [submitLoader, setSubmitLoader] = useState(false);

  const stepperOptions = [
    {
      id: 0,
      title: "Product Overview",
    },
    {
      id: 1,
      title: "Images & Media",
    },
    {
      id: 2,
      title: "Product Demo",
    },
    {
      id: 3,
      title: "Makers & Team",
    },
    {
      id: 4,
      title: "Review & Submit",
    },
  ];

  const getAllCompanies = async () => {
    await SearcherMasterServices.getAllCompanies()
      .then((res) => {
        const { data, status, meta } = res;
        console.log(data);
        if (Array.isArray(data) && data.length > 0) {
          setCompanyOptions(
            data.map((v) => ({ label: v.attributes.name, value: v.id }))
          );
        }
      })
      .catch((err) => {
        toast.error(err.data?.message);
        console.log(err);
      });
  };

  useEffect(() => {
    getAllCompanies();
  }, []);
  const addProduct = async (obj) => {
    // setLoading(true);
    let body = {
      data: { ...obj },
    };
    setSubmitLoader(true);
    await SearcherMasterServices.addProduct({ body })
      .then((res) => {
        const { data, status, error } = res;
        setSubmitLoader(false);
        console.log(data);
        if (data?.id) {
          setTimeout(() => {
            updateProductUser(data?.id, 'Add');
          }, 7000);
          navigate("/product");
        } else {
          toast.error(error?.message);
        }
        // setLoading(false);
      })
      .catch((err) => {
        // toast.error(err.data?.message);
        console.log(err);
        setSubmitLoader(false);
        toast.error(err.message);
        // setLoading(false);
      });
  };

  const updateProduct = async (obj) => {
    // setLoading(true);
    let body = {
      data: { ...obj },
    };
    await SearcherMasterServices.updateProduct({ id: product_id, body })
      .then((res) => {
        const { data, status } = res;
        console.log(data);
        if (data.id) {
          setTimeout(() => {
            updateProductUser(data?.id, 'Edit');
          }, 7000);
          navigate("/product");
        } else {
          toast.error("something went wrong!");
        }
        // setLoading(false);
      })
      .catch((err) => {
        // toast.error(err.data?.message);
        console.log(err);
        // setLoading(false);
      });
  };

  const handleSubmit = () => {
    console.log("allStepsData", allStepsData);
    console.log("allStepsData.is_weightage", allStepsData.is_weightage);
    let reqBody = {
      Name: allStepsData.product_name,
      Short_description: allStepsData.product_tagline,
      publishedAt: editProductData?.publishedAt,
      Headline: allStepsData.product_headline,
      description: allStepsData.product_description,
      logo: allStepsData.product_logo_uploaded,
      launch_date: allStepsData.product_launch_date,
      highlights: Array.isArray(allStepsData.addHightlightItem)
        ? allStepsData.addHightlightItem.map((v, index) => ({
          name: v.highlight_header,
          description: v.highlight_description,
          sequence: index,
        }))
        : [],
      brochure: Array.isArray(allStepsData.addBrouchers)
        ? allStepsData.addBrouchers.map((v, index) => ({
          name: v.broucher_name,
          file_url: v.broucher_file_uploaded,
          sequence: index,
          published: v.broucher_date,
          thumbnail: v.broucher_image_uploaded,
          description: v.broucher_description,
        }))
        : [],
      brochure_new: Array.isArray(allStepsData.addNewBrouchers)
        ? allStepsData.addNewBrouchers.map((v, index) => ({
          name: v.new_broucher_name,
          sequence: index,
          published: v.new_broucher_date,
          thumbnail: v.new_broucher_image_uploaded,
          description: v.new_broucher_description,
          file_url: v.broucher_file_uploaded,
        }))
        : [],
      features: Array.isArray(allStepsData.addFeatureItem)
        ? allStepsData.addFeatureItem.map((v, index) => ({
          description: v.feature_name,
          sequence: index,
        }))
        : [],
      team: {
        teams: allStepsData.addProductTeam.map((v, index) => ({
          name: v.product_team_name,
          imageurl: v.product_team_url_uploaded,
          sequence: index,
          description: v.product_team_about,
          designation: v.product_team_designation,
        })),
        headline: `Minds behind ${allStepsData?.product_name}`,
        description: allStepsData.product_mission_statement,
      },
      demo_details: {
        title: "",
        videos: allStepsData.addProductDemo.map((v, index) => ({
          sequence: index,
          videourl: v.product_video_url,
          header: v.product_video_header,
          description: v.product_video_description,
          times: v.addVideoTimes,
        })),
      },
      is_global_enterprise:
        allStepsData?.enterprise.filter(v => v.value == "mid_enterprise").length > 0 ? true : false,
      is_large_enterprise:
        allStepsData?.enterprise.filter(v => v.value == "large_enterprise").length > 0 ? true : false,
      is_small_enterprise:
        allStepsData?.enterprise.filter(v => v.value == "small_enterprise").length > 0 ? true : false,
      cross_sell_id: null,
      price: allStepsData?.addProductplans,
      is_sponsored: false,
      is_normal: true,
      is_claimed: true,
      is_saved: false,
      thumbnail_images: allStepsData?.addScreenshot.map((v, i) => ({
        sequence: i,
        thumbnail_url: v.screenshot_img_uploaded,
      })),
      thumbnail_videos: allStepsData?.product_youtube_link
        ? [{ sequence: 1, thumbnail_url: allStepsData?.product_youtube_link }]
        : null,
      headquarter: `${allStepsData?.product_headquarter}`,
      contact: allStepsData?.addProductContacts,
      is_approved: null,
      is_main_home: null,
      company: [allStepsData?.product_company_type],
      categories: Array.isArray(allStepsData.product_categories)
        ? allStepsData.product_categories.map((v) => v.value)
        : [],
      clients: Array.isArray(allStepsData?.addTrustedClient)
        ? allStepsData?.addTrustedClient.map((v) => v.value).filter((v) => v)
        : [],
      hcm_integrations: Array.isArray(allStepsData?.addHCM)
        ? allStepsData?.addHCM.map((v) => v.value).filter((v) => v)
        : [],
      tags: Array.isArray(allStepsData.product_tags)
        ? allStepsData.product_tags.map((v) => v.value)
        : [],
      business_objectives: Array.isArray(allStepsData.product_problem_statement)
        ? allStepsData.product_problem_statement.map((v) => v.value)
        : [],
      sub_categories: Array.isArray(allStepsData.product_sub_categories)
        ? allStepsData.product_sub_categories.map((v) => v.value)
        : [],
      cross_sells: {
        disconnect: [],
        connect: [],
      },
      products: {
        disconnect: [],
        connect: [],
      },
      // is_spotlight: allStepsData.product_is_spotlight,
      is_weightage: allStepsData.is_weightage == true ? true : allStepsData.is_weightage == false ? false : allStepsData.is_weightage == ['on'] ? true : false,
      is_spotlight: allStepsData.product_is_spotlight == true ? true : allStepsData.product_is_spotlight == false ? false : allStepsData.product_is_spotlight == ['on'] ? true : false,
    };
    console.log(reqBody);
    if (product_id) {
      updateProduct(reqBody);
    } else {
      addProduct(reqBody);
    }
  };

  const updateProductUser = async (item, type) => {
    const body = {
      ser_id: item || '',
      insert_type: type,
    }
    await MasterServices.saveProductUser(body)
      .then(async (response) => {
        const { data, status } = response;
        if (status == 200 && data.status) {
        } else {
          console.log("Error in updateProductUser", data.message);
          toast.error(data.message);
        }
      })
      .catch((error) => {
        console.log("updateProductUser error", error);
        toast.error(error.message);
      });
  }

  const handlePrevButton = () => {
    setTabId((prev) => {
      const maxIndex = stepperOptions.length - 1;
      return prev <= maxIndex ? prev - 1 : prev;
    });
  };

  const pricingPlan = [
    {
      label: "pricing plan1",
      value: 1,
    },
    {
      label: "pricing plan2",
      value: 2,
    },
  ];

  useEffect(() => {
    if (allStepsData && companyOptions) {
      const filteredOptions = companyOptions.filter(
        (ele) => ele.value === allStepsData.product_company_type
      );
      setFilterCompany(filteredOptions);
    }
  }, [allStepsData, companyOptions]);

  useEffect(() => {
    if (allStepsData && pricingPlan) {
      const filter = pricingPlan.filter(
        (ele, index) => ele.value === allStepsData.product_pricing_plan
      );
      setFilterPricing(filter);
    }
  }, []);

  console.log("allStepsData", allStepsData);

  return (
    <>
      <div className={`flex ${tabId === 4 ? "flex-col  gay-y-5" : "hidden"}`}>
        {/* {product Overview} */}
        <div className="flex flex-col gap-y-4">
          <h1 className="sm:text-lg font-medium">Product Overview</h1>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-5">
            <div className=" w-full flex">
              <label className="flex w-1/3 text-sm font-medium leading-6 text-gray-900">
                Product Name :
              </label>
              <div className=" w-2/3">
                {/* <input
                  value={allStepsData.product_name}
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"

                  readOnly
                /> */}
                <span className=" text-sm">{allStepsData.product_name}</span>
              </div>
            </div>
            <div className="  w-full flex">
              <label className="block w-1/3 text-sm font-medium leading-6 text-gray-900">
                Company Type :
              </label>
              <div className=" w-2/3">
                {/* <input
                  value={
                    filterCompany &&
                    filterCompany.map((ele, index) => {
                      return `${ele.label}`;
                    })
                  }
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Company Type"
                  readOnly
                /> */}
                <span className=" w-2/3 text-sm">
                  {filterCompany &&
                    filterCompany.map((ele, index) => {
                      return `${ele.label}`;
                    })}
                </span>
              </div>
            </div>
            <div className=" w-full flex">
              <label className="block w-1/3 text-sm font-medium leading-6 text-gray-900">
                Segment Created To :
              </label>
              <div className=" w-2/3">
                {/* <input
                value={allStepsData.enterprise}
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder=" Segment Created To"
                  readOnly
                /> */}
                <span className=" text-sm">{(Array.isArray(allStepsData?.enterprise)) ? allStepsData?.enterprise.map(v => v.label).join(', ') : ''}</span>
              </div>
            </div>
            <div className=" w-full flex">
              <label className="flex w-1/3 text-sm font-medium leading-6 text-gray-900">
                Product Tagline :
              </label>
              <div className=" w-2/3">
                {/* <input
                  value={allStepsData?.product_tagline}
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Product Tagline"
                /> */}
                <span className=" text-sm">
                  {allStepsData?.product_tagline}
                </span>
              </div>
            </div>
            {/* <div>
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Parent Company
              </label>
              <div className="">
                <input
                  value={allStepsData?.product_parent_company}
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                 
                />
              </div>
            </div> */}

            {/* <div>
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Country
              </label>
              <div className="">
                <input
                  value={allStepsData?.product_country}
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                 
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium leading-6 text-gray-900">
                State
              </label>
              <div className="">
                <input
                  value={allStepsData?.product_state}
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
               
                />
              </div>
            </div> */}
            <div className=" w-full flex ">
              <label className="flex w-1/3 text-sm font-medium leading-6 text-gray-900">
                Headquarter :
              </label>
              <div className=" w-2/3">
                {/* <input
                  value={allStepsData?.product_headquarter}
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                /> */}
                <span className=" text-sm">
                  {allStepsData?.product_headquarter}
                </span>
              </div>
            </div>
            <div className=" w-full flex ">
              <label className="block text-sm w-1/3 font-medium leading-6 text-gray-900">
                Product Launch Date :
              </label>
              <div className=" w-2/3">
                {/* <input
                  value={allStepsData?.product_launch_date}
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                /> */}
                <span className=" text-sm">
                  {allStepsData?.product_launch_date}
                </span>
              </div>
            </div>
            <div className=" w-full flex">
              <label className="block w-1/3 text-sm font-medium leading-6 text-gray-900">
                Product Youtube Link :
              </label>
              <div className=" w-2/3">
                {/* <input
                  value={allStepsData?.product_youtube_link}
                  type="text"
                  n
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                /> */}
                <span className=" text-sm">
                  {allStepsData?.product_youtube_link}
                </span>
              </div>
            </div>
            <div className=" w-full flex">
              <label className="block w-1/3 text-sm font-medium leading-6 text-gray-900">
                Tags :
              </label>
              <div className=" w-2/3">
                {/* <input
                  value={allStepsData?.product_tags?.map((ele) => ele.label)}
                  type="text"
                  n
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                /> */}
                <span className=" text-sm">
                  {/* {allStepsData?.product_tags?.map((ele) => ele.label)} */}
                </span>
              </div>
            </div>
            <div className=" w-full flex">
              <label className="flex w-1/3 text-sm font-medium leading-6 text-gray-900">
                Product HeadLine :
              </label>
              <div className=" w-2/3">
                {/* <input
                  value={allStepsData?.product_headline}
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                /> */}
                <span className=" w-2/3">{allStepsData?.product_headline}</span>
              </div>
            </div>
            <div className=" w-full flex">
              <label className="flex w-1/3 text-sm font-medium leading-6 text-gray-900">
                Product Description :
              </label>
              <div className=" w-2/3">
                {/* <input
                  value={allStepsData?.product_description}
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                /> */}
                <span className=" text-sm">
                  {allStepsData?.product_description}
                </span>
              </div>
            </div>
            <div className=" w-full flex">
              <label className="block w-1/3 text-sm font-medium leading-6 text-gray-900">
                Product Category :
              </label>
              <div className=" w-2/3">
                {/* <textarea
                  value={
                    Array.isArray(allStepsData?.product_categories) &&
                    allStepsData?.product_categories?.map((ele, index) => {
                      return ` ${ele?.label}`;
                    })
                  }
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                /> */}
                <span className=" text-sm">
                  {Array.isArray(allStepsData?.product_categories) &&
                    allStepsData?.product_categories?.map((ele, index) => {
                      return ` ${ele?.label}`;
                    })}
                </span>
              </div>
            </div>
            <div className=" w-full flex">
              <label className="block w-1/3 text-sm font-medium leading-6 text-gray-900">
                Product Subcategory :
              </label>
              <div className=" w-2/3">
                {/* <textarea
                  value={
                    Array.isArray(allStepsData?.product_sub_categories) &&
                    allStepsData?.product_sub_categories?.map((ele, index) => {
                      return ` ${ele?.label}`;
                    })
                  }
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                /> */}
                <span className=" text-sm">
                  {Array.isArray(allStepsData?.product_sub_categories) &&
                    allStepsData?.product_sub_categories?.map((ele, index) => {
                      return ` ${ele?.label}`;
                    })}
                </span>
              </div>
            </div>
            <div className=" w-full flex">
              <label className="block w-1/3 text-sm font-medium leading-6 text-gray-900">
                Product Problem Statement :
              </label>
              <div className=" w-2/3">
                {/* <textarea
                  value={
                    Array.isArray(allStepsData?.product_problem_statement) &&
                    allStepsData?.product_problem_statement.length > 0 &&
                    allStepsData?.product_problem_statement?.map(
                      (ele, index) => {
                        return ` ${ele?.label}`;
                      }
                    )
                  }
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                /> */}
                <span className=" text-sm">
                  {Array.isArray(allStepsData?.product_problem_statement) &&
                    allStepsData?.product_problem_statement.length > 0 &&
                    allStepsData?.product_problem_statement?.map(
                      (ele, index) => {
                        return ` ${ele?.label}`;
                      }
                    )}
                </span>
              </div>
            </div>
            <div className=" w-full flex">
              <label className="block w-1/3 text-sm font-medium leading-6 text-gray-900">
                Product Key Features :
              </label>
              <div className=" w-2/3">
                {/* <textarea
                  value={
                    Array.isArray(allStepsData?.addFeatureItem) &&
                    allStepsData?.addFeatureItem?.map((ele, index) => {
                      return ` ${ele?.feature_name}`;
                    })
                  }
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                /> */}
                <span className=" text-sm">
                  {Array.isArray(allStepsData?.addFeatureItem) &&
                    allStepsData?.addFeatureItem?.map((ele, index) => {
                      return ` ${ele?.feature_name}`;
                    })}
                </span>
              </div>
            </div>
            {Array.isArray(allStepsData?.addHightlightItem) &&
              allStepsData?.addHightlightItem?.map((ele, index) => {
                return (
                  <>
                    <div className=" w-full flex">
                      <label className="block w-1/3 text-sm font-medium leading-6 text-gray-900">
                        HighLight Header {index + 1} : 
                      </label>
                      <div className=" w-2/3">
                        {/* <textarea
                          value={ele?.highlight_header}
                          type="text"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        /> */}
                        <span className=" text-sm">
                          {ele?.highlight_header}
                        </span>
                      </div>
                    </div>
                    <div className=" w-full flex">
                      <label className="block w-1/3 text-sm font-medium leading-6 text-gray-900">
                        HighLight Description {index + 1} : 
                      </label>
                      <div className=" w-2/3">
                        {/* <textarea
                          value={ele?.highlight_description}
                          type="text"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        /> */}
                        <span className=" text-sm">
                          {ele?.highlight_description}
                        </span>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
        <hr class="h-px my-3 bg-gray-300 border-0 dark:bg-gray-700"></hr>
        {/* {images and media} */}
        <div className="flex flex-col gap-y-4">
          <h1 className="sm:text-lg font-medium">Images & Media</h1>
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Product Logo
            </label>
            <div className="">
              <img
                src={`${process.env.REACT_APP_IMG_URL+allStepsData?.product_logo_uploaded}`}
                className="w-1/4 h-[200px]"
              />
            </div>
          </div>
          <div className="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-x-5 gap-y-5">
            {Array.isArray(allStepsData?.addScreenshot) &&
              allStepsData?.addScreenshot.map((ele, index) => {
                return (
                  <>
                    <div className=" rounded-md border-2 p-2">
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        Product ScreenShot {index + 1}
                      </label>
                      <div className="">
                        <img
                          src={`${process.env.REACT_APP_IMG_URL+ele?.screenshot_img_uploaded}`}
                          className="w-full h-[200px] rounded-md"
                        />
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
          <hr class=" h-[1px] my-3 bg-gray-300 border-0 dark:bg-gray-700"></hr>

          <h1 className="sm:text-lg font-medium">Clients:- </h1>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5 gap-y-5">
            {Array.isArray(allStepsData?.addTrustedClient) &&
              allStepsData?.addTrustedClient.map((ele, index) => {
                return (
                  <>
                    <div>
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        Client {index + 1} : {ele?.label}
                      </label>
                      <div className="">
                        <img
                          src={`${process.env.REACT_APP_IMG_URL+ele?.logo}`}
                          className="w-[200px] h-[200px]  rounded-md"
                        />
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
          <hr class=" h-[1px] my-3 bg-gray-300 border-0 dark:bg-gray-700"></hr>

          <h1 className="sm:text-lg font-medium">HCM :- </h1>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5 gap-y-5">
            {Array.isArray(allStepsData?.addHCM) &&
              allStepsData?.addHCM.map((ele, index) => {
                return (
                  <>
                    <div>
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        HCM {index + 1} : {ele?.label}
                      </label>
                      <div className="">
                        <img
                          src={`${process.env.REACT_APP_IMG_URL+ele?.logo}`}
                          className="w-[200px] h-[200px]  rounded-md"
                        />
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
          <hr class=" h-[1px] my-3 bg-gray-300 border-0 dark:bg-gray-700"></hr>
          <h1 className="sm:text-lg font-medium">Broucher :- </h1>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-x-5 gap-y-5">
            {Array.isArray(allStepsData?.addNewBrouchers) &&
              allStepsData?.addNewBrouchers?.map((ele, index) => {
                return (
                  <>
                    <div className=" flex flex-col gap-2 border-2 p-2 rounded-md py-4">
                      <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Image {index + 1}
                        </label>
                        <div className="">
                          <img
                            src={`${process.env.REACT_APP_IMG_URL+ele?.new_broucher_image_uploaded}`}
                            className="w-full h-[200px] rounded-md"
                          />
                        </div>
                      </div>
                      <div className=" flex w-full">
                        <label className="flex w-2/5 text-sm font-medium leading-6 text-gray-900">
                          Name {index + 1}:
                        </label>
                        <div className=" w-3/5">
                          <span
                            value={ele?.new_broucher_name}
                            type="text"
                            className=" text-sm border-0"
                          />
                        </div>
                      </div>
                      <div className=" flex w-full">
                        <label className="block w-1/3 text-sm font-medium leading-6 text-gray-900">
                          Date {index + 1} :
                        </label>
                        <div className=" w-2/3">
                          <span
                            value={ele?.new_broucher_date}
                            type="text"
                            className=" text-sm border-0"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
          <hr class=" h-[1px] my-3 bg-gray-300 border-0 dark:bg-gray-700"></hr>
          <h1 className="sm:text-lg font-medium">Content Gallery :- </h1>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5 gap-y-5">
            {Array.isArray(allStepsData?.addBrouchers) &&
              allStepsData?.addBrouchers?.map((ele, index) => {
                return (
                  <>
                    <div className="flex flex-col gap-2 border-2 p-2 rounded-md py-4">
                      <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Image {index + 1} :
                        </label>
                        <div className="">
                          <img
                            src={`${process.env.REACT_APP_IMG_URL+ele?.broucher_image_uploaded}`}
                            className="w-full h-[200px] rounded-md"
                          />
                        </div>
                      </div>
                      <div>
                        <div className=" flex w-full">
                          <label className="flex w-1/2 text-sm font-medium leading-6 text-gray-900">
                            Name {index + 1} :{" "}
                          </label>
                          <div className=" w-1/2">
                            <span
                              value={ele?.broucher_name}
                              type="text"
                              className="text-sm border-0"
                            />
                          </div>
                        </div>
                        <div className=" flex w-full">
                          <label className="block w-1/2 text-sm font-medium leading-6 text-gray-900">
                            Date {index + 1} :
                          </label>
                          <div className=" w-1/2">
                            <span
                              value={ele?.broucher_date}
                              type="text"
                              className=" text-sm border-0"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
        <hr class="h-px my-3 bg-gray-300 border-0 dark:bg-gray-700"></hr>
        <div className="flex flex-col gap-y-4">
          <h1 className="sm:text-lg font-medium">Product Demo</h1>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5 gap-y-5">
            {Array.isArray(allStepsData?.addProductDemo) &&
              allStepsData?.addProductDemo?.map((ele, index) => {
                return (
                  <>
                    <div>
                      <label className="flex text-sm font-medium leading-6 text-gray-900">
                        Product Video Header {index + 1}{" "}
                        <div className="ml-auto">
                          <span>{ele?.product_video_header?.length}</span>/100
                        </div>
                      </label>
                      <div className="">
                        <input
                          value={ele?.product_video_header}
                          type="text"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div>
                      <label className="flex text-sm font-medium leading-6 text-gray-900">
                        Product Video Url {index + 1}{" "}
                        <div className="ml-auto">
                          <span>{ele?.product_video_url?.length}</span>/100
                        </div>
                      </label>
                      <div className="">
                        <input
                          value={ele?.product_video_url}
                          type="text"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </>
                );
              })}
            <div>
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Product Pricing
              </label>
              <div className="flex gap-x-5">
                {allStepsData.addProductplans &&
                  allStepsData.addProductplans.map((ele, index) => {
                    return (
                      <input
                        value={ele.price}
                        type="text"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <hr class="h-px my-3 bg-gray-300 border-0 dark:bg-gray-700"></hr>
        <div className="flex flex-col gap-y-4">
          <h1 className="sm:text-lg font-medium">Makers & Teams</h1>
          <div className="">
            <div className=" w-full sm:w-1/2 flex">
              <label className="flex w-1/3 text-sm font-medium leading-6 text-gray-900">
                Mission Statement :
              </label>
              <div className=" w-2/3">
                {/* <input
                  value={allStepsData?.product_mission_statement}
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                /> */}
                <span className=" text-sm">
                  {allStepsData?.product_mission_statement}
                </span>
              </div>
            </div>
            <div className=" grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5 gap-y-5">

              {Array.isArray(allStepsData?.addProductTeam) &&
                allStepsData?.addProductTeam?.map((ele, index) => {
                  return (
                    <>
                      <div className=" flex flex-col gap-2 rounded-md border-2 p-2 mt-2">
                        <div className="">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Image {index + 1}
                          </label>
                          <div className="">
                            <img
                              src={`${process.env.REACT_APP_IMG_URL+ele?.product_team_url_uploaded}`}
                              className="w-full h-[200px] rounded-md"
                            />
                          </div>
                        </div>
                        <div className=" w-full flex ">
                          <label className="flex w-1/3 text-sm font-medium leading-6 text-gray-900">
                            Name {index + 1}:
                          </label>
                          <div className="  w-2/3 ">
                            {/* <input
                          value={ele?.product_team_name}
                          type="text"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        /> */}
                            <span className=" text-sm"> {ele?.product_team_name}</span>
                          </div>
                        </div>
                        <div className=" w-full flex">
                          <label className="flex w-1/3 text-sm font-medium leading-6 text-gray-900">
                            Designation {index + 1}:
                          </label>
                          <div className=" w-2/3">
                            {/* <input
                          value={ele?.product_team_designation}
                          type="text"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        /> */}
                            <span className=" text-sm">
                              {ele?.product_team_designation}
                            </span>
                          </div>
                        </div>
                        <div className=" w-full flex">
                          <label className="flex w-1/3 text-sm font-medium leading-6 text-gray-900">
                            About {index + 1} :
                          </label>
                          <div className=" w-2/3">
                            {/* <input
                          value={ele?.product_team_about}
                          type="text"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        /> */}
                            <span className=" text-sm">{ele?.product_team_about}</span>
                          </div>

                        </div>

                      </div>
                    </>
                  );
                })}
            </div>
          </div>
          <hr class="h-px my-3 bg-gray-300 border-0 dark:bg-gray-700"></hr>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-5">
            {Array.isArray(allStepsData?.addProductContacts) &&
              allStepsData?.addProductContacts?.map((ele, index) => (
                <>
                  <div className=" w-full flex">
                    <label className="flex w-1/3 text-sm font-medium leading-6 text-gray-900">
                      {index + 1}. Contact Email :
                    </label>
                    <div className=" w-2/3">
                      {/* <input
                        value={ele?.email}
                        type="text"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      /> */}
                      <span className=" text-sm">
                        {ele?.email}
                      </span>
                    </div>
                  </div>
                  <div className=" w-full flex">
                    <label className="flex w-1/3 text-sm font-medium leading-6 text-gray-900">
                      {index + 1}. Contact Phone :
                    </label>
                    <div className=" w-2/3">
                      {/* <input
                        value={ele?.phone}
                        type="text"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      /> */}
                      <span className=" text-sm">{ele?.phone}</span>
                    </div>
                  </div>
                  <div className=" w-full flex ">
                    <label className="flex w-1/3 text-sm font-medium leading-6 text-gray-900">
                      {index + 1}. Contact Website Link :

                    </label>
                    <div className=" w-2/3">
                      {/* <input
                        value={ele?.website_link}
                        type="text"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      /> */}
                      <span className=" text-sm">
                        {ele?.website_link}
                      </span>
                    </div>
                  </div>
                </>
              ))}
          </div>
        </div>
        <hr class="h-px my-3 bg-gray-300 border-0 dark:bg-gray-700"></hr>
        {/* {submit button} */}
        <div className="flex justify-end mt-5">
          <>
            {/* <code>{JSON.stringify(allStepsData, null, )}</code> */}
            <div className="flex gap-x-2">
              <button
                type="button"
                onClick={handlePrevButton}
                className="rounded-md bg-gradient-to-b from-[#DB7D0B] to-[#872341] px-5 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
              >
                Prev
              </button>
              <button
                disabled={submitLoader}
                type="button"
                onClick={handleSubmit}
                className="rounded-md bg-gradient-to-b from-[#DB7D0B] to-[#872341] px-5 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
              >
                {submitLoader ? <OvalLoader /> : "Submit"}
              </button>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default Step5;
