import * as Yup from "yup";

export const countrySchema = Yup.object({
  country_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Country Name"),
  country_code: Yup.string().required("please Enter 3 character Country code"),
  country_isd_code: Yup.number()
    .min(1)
    .max(999)
    .required("please Enter the Country Calling code"),
});
export const userInfoSchema = Yup.object({
  user_first_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the First Name"),
  user_last_name: Yup.string()
    .min(2)
    .max(100)
    .required("please Enter the Last Name"),
  user_email: Yup.string().min(2).max(100).required("please Enter the email"),
});
export const genderSchema = Yup.object({
  gender_name_en: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Engllish gender"),
  gender_name_ar: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Arabic gender"),
});
export const stateSchema = Yup.object({
  state_name_en: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter English State Name"),
  state_name_ar: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter Arabic State Name"),
  fk_country_id: Yup.string().required("Please select country"),
});
export const citySchema = Yup.object({
  city_name_en: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter English City Name"),
  city_name_ar: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter Arabic City Name"),
  fk_state_id: Yup.string().required("Please select state"),
});

export const roleSchema = Yup.object({
  role_name: Yup.string().min(2).max(50).required("Please Enter the Role Name"),
});

export const tagSchema = Yup.object({
  name: Yup.string().min(2).max(50).required("Please Enter the tag Name"),
});

export const buisnessObjectiveSchema = Yup.object({
  name: Yup.string().min(2).max(50).required("Please Enter the Objective Name"),
  // business_objective_id: Yup.string()
  //   .min(2)
  //   .max(50)
  //   .required("Please Enter the Objective ID"),
});

export const categorySchema = Yup.object({
  name: Yup.string().min(2).max(50).required("Please Enter the category Name"),
});

// export const subCategorySchema = Yup.object({
//   name: Yup.string().min(2).max(50).required("Please Enter the Role Name"),
//   top_products: Yup.array().min(4, 'please select upto 4 products').max(4, 'please select upto 4 products').required('please select upto 4 products'),
// });

// export const subCategorySchema = Yup.object({
//   name: Yup.string().min(2).max(50).required("Please enter the Sub Category Name"),
//   top_products: Yup.array().when("top_products", {
//     is: products => products && products.length > 0,
//     then: Yup.array().min(4, 'Please select exactly 4 products').max(4, 'Please select exactly 4 products').required('Please select exactly 4 products'),
//     otherwise: Yup.array().notRequired(),
//   }),
// });
export const subCategorySchema = Yup.object({
  name: Yup.string().min(2).max(50).required("Please enter the Sub Category Name"),
  top_products: Yup.array()
    .of(Yup.object().shape({ 
      value: Yup.number().required(),
      label: Yup.string().required(),
    }))
    .test(
      'num-products',
      'Please select exactly 4 products',
      array => !array || array.length === 0 || array.length === 4 
    )
});

export const clientSchema = Yup.object({
  name: Yup.string().min(2).max(50).required("Please Enter the client Name"),
});

export const hcmSchema = Yup.object({
  name: Yup.string().min(2).max(50).required("Please Enter the HCM Name"),
})

export const companySchema = Yup.object({
  name: Yup.string().min(2).max(50).required("Please Enter the company Name"),
});

export const searcherProductSchema = Yup.object({
  name: Yup.string().min(2).max(50).required("Please Enter the Name"),
});

export const userSchema = Yup.object({
  user_first_name: Yup.string()
    .min(3)
    .max(25)
    .required("Please Enter the First Name"),
  // user_last_name: Yup.string()
  //   .min(2)
  //   .max(25)
  //   .required("Please Enter the Last Name"),
  user_email: Yup.string().email().required("Please Enter the Email address"),
  user_password: Yup.string().min(6).required("Please Enter the Password"),
  user_role: Yup.string().required("Please Enter the Role"),
  user_company_name: Yup.string().required("Please Enter the Company name"),
  // user_mobile: Yup.number().required("Please Enter the Mobile number"),
  user_mobile: Yup.string().test('len', 'Mobile number must be between 8 and 14 digits long', val => val && val.length > 7 && val.length < 15)
  // user_profile_image: Yup.mixed().test(
  //   "user_profile_image",
  //   "Please Choose a image",
  //   function (value) {
  //     const image = this.resolve(Yup.ref("user_profile_image")); // Replace with actual path field name
  //     if (!image && !value) {
  //       return this.createError({
  //         message: "profile image is required",
  //         path: "user_profile_image",
  //       });
  //     }

  //     return true;
  //   }
  // ),
});

export const userEditSchema = Yup.object({
  user_first_name: Yup.string()
    .min(3)
    .max(25)
    .required("Please Enter the First Name"),
  user_last_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Last Name"),
  user_email: Yup.string().email().required("Please Enter the Email address"),
  user_role: Yup.string().required("Please Enter the Role"),
  // user_company_name: Yup.string().required("Please Enter the Company name"),
  // user_mobile: Yup.number().required("Please Enter the Mobile number"),

  // user_profile_image: Yup.mixed().test(
  //   "user_profile_image",
  //   "Please Choose a image",
  //   function (value) {
  //     const image = this.resolve(Yup.ref("user_profile_image")); // Replace with actual path field name
  //     if (!image && !value) {
  //       return this.createError({
  //         message: "profile image is required",
  //         path: "user_profile_image",
  //       });
  //     }

  //     return true;
  //   }
  // ),
});

export const magazineSchema = Yup.object({
  magazine_name: Yup.string()
    .min(3)
    .max(25)
    .required("Please Enter the Magazine Name"),
  // magazine_gap: Yup.number().required("Please Enter the Interval"),

  magazine_banner: Yup.mixed().test(
    "magazine_banner",
    "Please Choose a Magazine Banner",
    function (value) {
      const bannerPath = this.resolve(Yup.ref("magazine_banner")); // Replace with actual path field name
      if (!bannerPath && !value) {
        return this.createError({
          message: "Magazine Banner is required",
          path: "magazine_banner",
        });
      }

      return true;
    }
  ),
});
export const magazineIssueSchema = Yup.object({
  issue_name: Yup.string().min(3).max(25).required("Please Enter the Name"),
  issue_month: Yup.date().nullable().required("Month is required"),
  issue_date: Yup.date().nullable().required("Date is required"),
  issue_price: Yup.number().required("Please Enter the Price"),
  issue_cover: Yup.mixed().test(
    "picture",
    "Please Choose a picture",
    function (value) {
      const bannerPath = this.resolve(Yup.ref("issue_cover")); // Replace with actual path field name
      if (!bannerPath && !value) {
        return this.createError({
          message: " picture is required",
          path: "issue_cover",
        });
      }
      return true;
    }
  ),
});

export const skuSchema = Yup.object({
  sku_code: Yup.string().min(3).max(25).required("Please Enter the Name"),
  sku_type: Yup.array().of(
    Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
  ),
  sku_total_price: Yup.number().required("Please Enter the price"),
  sku_description: Yup.string().min(3).required("Please Enter the description"),
  sku_picture: Yup.mixed().test(
    "picture",
    "Please Choose a picture",
    function (value) {
      const bannerPath = this.resolve(Yup.ref("sku_picture")); // Replace with actual path field name
      if (!bannerPath && !value) {
        return this.createError({
          message: " picture is required",
          path: "sku_picture",
        });
      }
      return true;
    }
  ),
});

export const productSchema = Yup.object({
  product_name: Yup.string().min(3).max(25).required("Please Enter the Name"),
  // product_type: Yup.array().of(
  //   Yup.object().shape({
  //     label: Yup.string().required(),
  //     value: Yup.string().required(),
  //   })
  // ),
  product_price: Yup.number().required("Please Enter the price"),
  product_discount: Yup.number().required("Please Enter the discount"),
  product_no_of_issue: Yup.number().required("Please Enter the issue no"),
  product_image: Yup.mixed().test(
    "picture",
    "Please Choose a picture",
    function (value) {
      const bannerPath = this.resolve(Yup.ref("product_poster")); // Replace with actual path field name
      if (!bannerPath && !value) {
        return this.createError({
          message: " picture is required",
          path: "product_poster",
        });
      }
      return true;
    }
  ),
});


export const searcherProductStepOneSchema = Yup.object({
  product_name: Yup.string().min(1).max(50, 'name must be at most 50 characters').nullable().required('Please enter the name'),
  product_company_type: Yup.string().min(1).nullable().required('Please select company type'),
  product_categories: Yup.array().min(1, 'please select categories').required('please select categories'),
  product_sub_categories: Yup.array().min(1, 'please select sub categories').required('please select sub categories'),
  product_headline: Yup.string().min(1).max(100, 'headline must be at most 50 characters').required('Please enter the headline'),
  product_headquarter: Yup.string().min(1).nullable().max(50, 'headquarter must be at most 50 characters').required('Please enter the headquarter'),
  product_description: Yup.string().min(1).nullable().max(500, 'description must be at most 100 characters').required('Please enter the description'),
  product_launch_date: Yup.string().min(1).nullable().required('please select date'),
  // product_country: Yup.string().min(1).required('Please select country'),
  // product_state: Yup.string().min(1).required('Please select state'),
  addFeatureItem: Yup.array()
  .of(
    Yup.object().shape({
      feature_name: Yup.string().min(1).max(100, 'name must be at most 50 characters').required('Feature name is required'),
    })
  )
  .required('You must have at least one feature'),

  product_tagline: Yup.string().min(1).max(100, 'tagline must be at most 100 characters').nullable().required('please enter the tagline'),
  // // product_parent_company: Yup.string().min(1).required('please enter the parent company'),

  
  // product_youtube_link: Yup.string().min(1).required('please enter the youtube link'),
  // product_problem_statement: Yup.array().min(1).required('please select problem statement'),

  
  // addHightlightItem: Yup.array()
  // .of(
  //   Yup.object().shape({
  //     highlight_header: Yup.string().required('header is required'),
  //     highlight_description: Yup.string().required('description is required'),
  //   })
  // )
  // .required('You must have at least one highlight'),
});


export const searcherProductStepTwoSchema = Yup.object({
  product_logo: Yup.mixed()
  .test(
    "file",
    "please select logo",
    (value) => {
      return value
    }
  )
  // .test(
  //   "fileSize",
  //   "File size is too large",
  //   (value) => {
  //     return !value || (value && value.size <= 1024 * 1024)
  //   }
  // )
  // .test(
  //     "fileType",
  //     "Unsupported File Format",
  //     (value) => !value || (value && ['image/jpeg', 'image/png'].includes(value.type))
  // ),
  ,
  // product_screenshot: Yup.mixed()
  // .test(
  //   "file",
  //   "please select screenshot",
  //   (value) => {
  //     return value
  //   }
  // )
  // ,
  // addTrustedClient: Yup.array()
  // .of(
  //   Yup.object().shape({
  //     value: Yup.string().required('Please select client'),
  //     label: Yup.string().required('Please select Client')
  //   })
  // )
  // .required('You must have at least one client'),
  // addBrouchers: Yup.array()
  // .of(
  //   Yup.object().shape({
  //     broucher_name: Yup.string().required('name is required'),
  //     broucher_date: Yup.string().required('date is required'),
  //     // broucher_image: Yup.mixed()
  //     // .test(
  //     //   "file",
  //     //   "please select file",
  //     //   (value) => {
  //     //     return value
  //     //   }
  //     // )
  //   })
  // )
  // .required('You must have at least one broucher.'),
})

export const searcherProductStepThreeSchema = Yup.object({
  // product_logo: Yup.mixed()
  addProductDemo: Yup.array()
  .of(
    Yup.object().shape({
      product_video_header: Yup.string().required('header is required'),
      product_video_url: Yup.string().required('url is required'),

    })
  )
  .required('You must have at least one demo video.'),
  product_pricing_plan: Yup.string().min(1).nullable().required('Please select pricing plan')
})

export const searcherProductStepFourSchema = Yup.object({
 // product_mission_statement: Yup.string().min(1).required('Please enter mission statement.'),
  addProductTeam: Yup.array().min(1)
  .of(
    Yup.object().shape({
      product_team_name: Yup.string().min(1).max(50, 'name must be at most 50 characters').required('name is required'),
      product_team_designation: Yup.string().min(1).max(50, 'designation must be at most 50 characters').required('designation is required'),
      product_team_about: Yup.string().min(1).max(100, 'about must be at most 100 characters').required('about is required'),
      product_team_url_uploaded: Yup.string().min(1),
      product_team_url: Yup.mixed()
      .when('product_team_url_uploaded', {
        is: (val) => !val,
        then: Yup.mixed()
          .test(
            "file",
            "please select image file",
            (value) => {
              return value !== undefined && value !== null; // Validate if the value exists
            }
          )
      })
    })
  )
  .required('You must have at least one demo video.'),
})

export const searcherProductStepFiveSchema = Yup.object({
  // product_logo: Yup.mixed()
  // .required('Please upload a file')
  // .test('fileType', 'Invalid file type. Only PNG is allowed', (value) => {
  //   console.log("hello worldl", value)
  //   return value && value.type === 'image/png';
  // })
  // .test('fileSize', 'File size is too large. Max size is 2MB', (value) => {
  //   return value && value.size <= 2097152; // 2MB in bytes
  // }),
})