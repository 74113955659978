/* eslint-disable */
import Api from "./Api";
export default {
    async getCurrentAuth() {
        if (localStorage.getItem('token')) {
            const response = await Api().post(`api/dashboard/masters/role/getAuth`, {});
            console.log("response=>", response?.data?.data?.user_id);
            if (response?.data?.data?.user_id && response?.data?.data?.is_role_changed) {
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                localStorage.removeItem("favourites");
                localStorage.removeItem("module");
                localStorage.removeItem("role_id");
                localStorage.removeItem("user_id");
                localStorage.removeItem("user_permissions");
                localStorage.clear();
                window.location.href = "/";
            }
            return response;
        } else {
            return null;
        }
    },
}

