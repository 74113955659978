import { FallingLines,Oval,Vortex } from "react-loader-spinner";

const FallingLinesLoader = () => {
  return (
    <div className="flex justify-center items-center">
      {/* <FallingLines color="#167491" width="100" visible={true} /> */}
      <Vortex
        visible={true}
        width="200"
        color="#00d4f6"
        ariaLabel="infinity-spin-loading"
      />
    </div>
  );
};

const OvalLoader = () => {
  return (
    <div className="flex justify-center items-center">
      <Oval
        visible={true}
        height="20"
        width="20"
        color="#4fa94d"
        ariaLabel="oval-loading"
        
      />
    </div>
  );
};

export { FallingLinesLoader ,OvalLoader};
