import momentTz from 'moment-timezone';
import { read, utils, writeFile } from 'xlsx';
import { timeZone } from './constant';
import moment from 'moment';
export const exportToExcel = (data, headings, fileName, sheetName) => {
  // Create a new workbook
  const workbook = utils.book_new();
  const worksheet = utils.json_to_sheet([]);
  utils.sheet_add_aoa(worksheet, headings);
  utils.sheet_add_json(worksheet, data, { origin: 'A2', skipHeader: true });
  utils.book_append_sheet(workbook, worksheet, 'Report');
  writeFile(workbook, fileName);
};

export const calculateAge = (dateOfBirth) => {
  // 
  const dob = new Date(dateOfBirth);
  const now = new Date();

  const diff = now.getTime() - dob.getTime();
  // 
  const age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));

  return age;
}

// Function to remove HTML tags using regex
export const removeTagsFromString = (htmlString) => {
  const regex = /(<([^>]+)>)/ig;
  return htmlString.replace(regex, '');
};

export const convertToAnotherTimezone = (date) => {
  var fmt = "MM/DD/YYYY h:mm:ss A";  // must match the input
  // 
  var zone = timeZone;
  var m = momentTz.tz(date, zone).format(fmt);
  // 
  return m
}


export const convertUTStoIST = (date) => {
  var fmt = "MM/DD/YYYY h:mm:ss A";  // must match the input
  // 
  const parsedDate = moment(date);
  // 
  var local = date;
  if (parsedDate.isValid()) {
    const utcMoment = momentTz.utc(parsedDate);
    // Convert UTC to IST (Indian Standard Time)
    local = utcMoment.add(5, 'hours').add(30, 'minutes');
  }
  return local
}

// Helper function to get allowed actions based on pathname
export const getAllowedActions = (allModules, pathname) => {
  //const currentModule = allModules.find((module) => module.sys_module_relation.module_key === pathname) || {};
  let permissionArr = [];
  if (allModules?.length > 0) {
    allModules.map((module) => {
      if (module.sys_module_relation.module_key === pathname) {
        permissionArr.push(module);
      }
    })
  }
  // const permissions = currentModule.permissions || [];
  // return permissions.filter((permission) => permission.permission_is_active);
  
  return permissionArr;
};

export const checkPermissionsAndRedirect = (pathname, allModules, navigate) => {

  let moduleArr = [];
  if (allModules?.length > 0) {
    allModules.map((module) => {
      if (module.sys_module_relation.module_key === pathname) {
        moduleArr.push(module.sys_module_relation.module_key);
      }
    })
  }
  
  // If moduleArr is empty, redirect to '/'
  if (moduleArr.length == 0) {
    navigate('/user-list');
    // window.location.redirect="/";
    return;
  }
};
export const replaceURLs = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
}

if (Array.isArray(obj)) {
    return obj.map((item) => replaceURLs(item));
}

return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    if (typeof value === 'string') {
        // acc[key] = value.replace(/https:\/\/searcher-octopus-dashboard\.s3\.ap-south-1\.amazonaws\.com\/uploads/g, ''); https://uat-searchr.s3.ap-south-1.amazonaws.com
        acc[key] = value.replace(/https:\/\/uat-searchr\.s3\.ap-south-1\.amazonaws\.com/g, '');
    } else {
        acc[key] = replaceURLs(value);
    }
    return acc;
}, {});
}
export const replaceURLsBak = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
      return obj;
  }

  if (Array.isArray(obj)) {
      return obj.map((item) => replaceURLs(item));
  }

  return Object.keys(obj).reduce((acc, key) => {
      const value = obj[key];
      if (typeof value === 'string') {
          acc[key] = value.replace(/https:\/\/searcher-octopus-dashboard\.s3\.ap-south-1\.amazonaws\.com/g, 'https://strapi-octopus-dashboard.s3.ap-south-1.amazonaws.com');
      } else {
          acc[key] = replaceURLs(value);
      }
      return acc;
  }, {});
};
export const searcherApiHelper = async (url, body) => {
  const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}${url}`, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
    }
  });
  return response;
}
