import React, { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import { Link, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import Table from "../../../../components/tables/table";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Select from "react-select";
import { XMarkIcon } from "@heroicons/react/20/solid";
import {
  searcherProductStepOneSchema,
  searcherProductStepThreeSchema,
} from "../../../../schemas";
import {
  uploadDemoVideo,
  uploadPdfFiles,
  uploadVideoFiles,
} from "../../../../helper/multiUploadHelper";
const Step3 = ({
  tabId,
  setTabId,
  setAllStepsData,
  allStepsData,
  editProductData,
}) => {
  const [initialValues, setInitialValues] = useState({
    addProductDemo: [
      {
        product_video_header: "",
        product_video_description: "",
        product_video_url: "",
        product_video_file: "",
        product_video_file_uploaded: "",
        addVideoTimes: [
          {
            minute: "",
            second: "",
            time: "",
            description: "",
          },
        ],
      },
    ],
    addProductplans: [
      {
        name: "",
        type: "",
        price: "",
        description: "",
        Plan_sequence: "",
      },
    ],
  });
  const [stepperIndex, setStepperIndex] = useState(0);
  const [productPricingPlan, setProductPricingPlan] = useState(null);
  const [minute, setMinute] = useState("00");
  const [second, setSecond] = useState("00");

  // Function to update minute
  const handleMinuteChange = (e) => {
    setMinute(e.target.value);
  };

  // Function to update second
  const handleSecondChange = (e) => {
    setSecond(e.target.value);
  };

  useEffect(() => {
    if (editProductData) {
      setProductPricingPlan({
        label: "pricing plan1",
        value: 1,
      });
      setInitialValues({
        addProductDemo:
          Array.isArray(editProductData?.demo_details?.videos) &&
          editProductData?.demo_details?.videos
            ? editProductData?.demo_details?.videos.map((v) => ({
                product_video_description: (v?.description)?(v?.description):(v?.chapter)?(v?.chapter):'',
                product_video_header: v.header,
                product_video_url: v.videourl,
                addVideoTimes: editProductData?.demo_details?.videos.map((v1) => ({
                  minute: v1?.minute,
                  second: v1?.second,
                  time: v1?.time,
                  description: (v1?.chapter)?(v1?.chapter):v1?.description,
                })),
                // addVideoTimes: v?.times ? v.times : [],
              }))
            : [],
        addProductplans:
          Array.isArray(editProductData?.price) && editProductData?.price
            ? editProductData?.price
            : [],
      });
    }
  }, [editProductData]);

  const [addFeatureItem, setFeatureItem] = useState([
    {
      id: 1,
      feature: "",
    },
    {
      id: 2,
      feature: "",
    },
    {
      id: 3,
      feature: "",
    },
  ]);

  const [addHightlight, setAddHightlight] = useState([
    {
      id: 1,
      header: "",
      description: "",
    },
  ]);
  const [addTrustedClient, setTrustedClient] = useState([
    {
      id: 1,
      company_name: "",
    },
  ]);
  const [addBroucher, setAddBroucher] = useState([
    { id: 1, brocuher_image: "", brocuher_name: "", broucher_date: "" },
  ]);

  // const [addProductDemo, setAddProductDemo] = useState([
  //     {
  //         id: 1,
  //         demo_about: "",
  //         demo_url: "",
  //     },
  // ]);

  const [addteamInfo, setAddTeamInfo] = useState([
    {
      id: 1,
      team_image: "",
      team_name: "",
      team_designation: "",
      team_about: "",
    },
  ]);

  const colourOptions = [
    {
      label: "IT",
      value: "it",
    },
    {
      label: "VECHILE",
      value: "it",
    },
  ];
  const countryOptions = [
    {
      label: "India",
      value: "it",
    },
    {
      label: "Us",
      value: "it",
    },
  ];
  const stateOptions = [
    {
      label: "Maharashtra",
      value: "it",
    },
    {
      label: "Delhi",
      value: "it",
    },
  ];
  const categoryOptions = [
    {
      label: "category 1",
      value: "category 2",
    },
    {
      label: "category 2",
      value: "category 2",
    },
  ];

  const subCategoryOptions = [
    {
      label: "subcategory 1",
      value: "subcategory 2",
    },
    {
      label: "subcategory 2",
      value: "subcategory 2",
    },
  ];

  const problemStatement = [
    {
      label: "problemStatement 1",
      value: "problemStatement 2",
    },
    {
      label: "problemStatement 2",
      value: "problemStatement 2",
    },
  ];

  const pricingPlan = [
    {
      label: "pricing plan1",
      value: 1,
    },
    {
      label: "pricing plan2",
      value: 2,
    },
  ];
  const stepperOptions = [
    {
      id: 0,
      title: "Product Overview",
    },
    {
      id: 1,
      title: "Images & Media",
    },
    {
      id: 2,
      title: "Product Demo",
    },
    {
      id: 3,
      title: "Makers & Team",
    },
    {
      id: 4,
      title: "Review & Submit",
    },
  ];

  const handleNextButton = () => {
    setTabId((prev) => {
      const maxIndex = stepperOptions.length - 1;
      return prev < maxIndex ? prev + 1 : prev;
    });
  };

  const handlePrevButton = () => {
    setTabId((prev) => {
      const maxIndex = stepperOptions.length - 1;
      return prev <= maxIndex ? prev - 1 : prev;
    });
  };

  const addFeatureBtn = () => {
    setFeatureItem([
      ...addFeatureItem,
      {
        id: addFeatureItem.length + 1,
        feature: "",
      },
    ]);
  };
  const removeFeatureBtn = (item) => {
    setFeatureItem((prev) => prev.filter((items) => items.id !== item.id));
  };

  const addHightlightBtn = () => {
    setAddHightlight([
      ...addHightlight,
      {
        id: addHightlight.length + 1,
        header: "",
        description: "",
      },
    ]);
  };

  const removeHightlightBtn = (item) => {
    setAddHightlight((prev) => prev.filter((items) => items.id !== item.id));
  };

  const addTrustedClientBtn = () => {
    setTrustedClient([
      ...addTrustedClient,
      {
        id: addTrustedClient.length + 1,
        company_name: "",
      },
    ]);
  };
  const removeTrustedClientBtn = (item) => {
    setTrustedClient((prev) => prev.filter((items) => items.id !== item.id));
  };

  const addBroucherBtn = () => {
    setAddBroucher([
      ...addBroucher,
      {
        id: addBroucher.length + 1,
        brocuher_image: "",
        brocuher_name: "",
        broucher_date: "",
      },
    ]);
  };
  // const removeBroucherBtn = (item) => {
  //     setAddBroucher((prev) => prev.filter((items) => items.id !== item.id));
  // };

  // const addProductDemoBtn = () => {
  //     setAddProductDemo([
  //         ...addProductDemo,
  //         {
  //             id: addProductDemo.length + 1,
  //             demo_about: "",
  //             demo_url: "",
  //         },
  //     ]);
  // };
  // const removeProductDemoBtn = (item) => {
  //     setAddProductDemo((prev) => prev.filter((items) => items.id !== item.id));
  // };

  const addTeamInfoBtn = () => {
    setAddTeamInfo([
      ...addteamInfo,
      {
        id: addteamInfo.length + 1,
        team_image: "",
        team_name: "",
        team_designation: "",
        team_about: "",
      },
    ]);
  };
  const removeTeamInfoBtn = (item) => {
    setAddTeamInfo((prev) => prev.filter((items) => items.id !== item.id));
  };

  const handleVideoFileChangeDemo = (event, field) => {
    const file = event.currentTarget.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async (e) => {
        const response = await uploadVideoFiles(e.target.result, "product");

        // console.log("url",field, response.data.path , response.data.fileName);
        setFieldValue(field, response.data.fileName);
      };
    }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldError,
    getFieldProps,
  } = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    //  validationSchema: searcherProductStepThreeSchema,
    onSubmit: async (values, action) => {
      console.log("Submit Clicked", values);
      setAllStepsData({ ...allStepsData, ...values });
      handleNextButton();
    },
  });
  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          console.log(e);
          handleSubmit(e);
        }}
        className=""
      >
        <div className={`flex ${tabId === 2 ? "flex-col  gay-y-5" : "hidden"}`}>
          <div className=" flex flex-col gap-y-3 ">
            <div className="flex justify-between">
            <div className="flex justify-between">
              <h1 className="sm:text-lg font-medium">Product Demo Video</h1>
            </div>
              
            </div>
            <div className="w-full flex flex-col sm:flex-row gap-4  justify-between">
              <div className=" w-full sm:w-[90%] ">
                {Array.isArray(values.addProductDemo) &&
                  values.addProductDemo.map((item, index) => (
                    <div key={index} className="flex flex-col border-2 my-4 p-2 rounded-md gap-x-5 gap-y-5">
                      <div className="flex justify-end items-end ">
                        <button
                          type="button"
                          className="rounded-md px-2  text-sm font-semibold text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                          onClick={() =>
                            setFieldValue(
                              "addProductDemo",
                              values.addProductDemo.filter(
                                (_, i) => i !== index
                              )
                            )
                          }
                        >
                          <XMarkIcon className="w-5 h-5" />
                        </button>
                      </div>
                      <div className=" md:flex  justify-between gap-x-5 gap-y-5">
                        <div className=" w-full md:w-1/3">
                          <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Header{" "}
                            <div className="ml-auto ">
                              <span
                                className={
                                  Array.isArray(errors?.addProductDemo) &&
                                  errors?.addProductDemo[index]
                                    ?.product_video_header
                                    ? "text-red-500"
                                    : ""
                                }
                              >
                                {
                                  values?.addProductDemo[index]
                                    ?.product_video_header?.length
                                }
                              </span>
                              /100
                            </div>
                          </label>
                          <div>
                            <input
                              type="text"
                              name={`addProductDemo[${index}].product_video_header`}
                              {...getFieldProps(
                                `addProductDemo[${index}].product_video_header`
                              )}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              placeholder={`Header ${index + 1}`}  
                            />
                            {/* {errors.addProductDemo &&
                          touched.addProductDemo &&
                          errors.addProductDemo[index]?.product_video_header && (
                            <div className="text-red-600 text-sm">
                              {errors.addProductDemo[index].product_video_header}
                            </div>
                          )} */}
                          </div>
                        </div>
                        <div className=" w-full md:w-1/3">
                          <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Description{" "}
                            <div className="ml-auto ">
                              <span
                                className={
                                  Array.isArray(errors?.addProductDemo) &&
                                  errors?.addProductDemo[index]
                                    ?.product_video_description
                                    ? "text-red-500"
                                    : ""
                                }
                              >
                                {
                                  values?.addProductDemo[index]
                                    ?.product_video_description?.length
                                }
                              </span>
                              /100
                            </div>
                          </label>
                          <div>
                            <input
                              name={`addProductDemo[${index}].product_video_description`}
                              {...getFieldProps(
                                `addProductDemo[${index}].product_video_description`
                              )}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              placeholder={`Description ${index + 1}`}
                            />
                            {/* {errors.addProductDemo &&
                          touched.addProductDemo &&
                          errors.addProductDemo[index]?.product_video_description && (
                            <div className="text-red-600 text-sm">
                              {errors.addProductDemo[index].product_video_description}
                            </div>
                          )} */}
                          </div>
                        </div>
                        <div className=" w-full md:w-1/3">
                          <label className="flex block text-sm font-medium leading-6 text-gray-900">
                            Video URL{" "}
                            <div className="ml-auto ">
                              <span
                                className={
                                  Array.isArray(errors?.addProductDemo) &&
                                  errors?.addProductDemo[index]
                                    ?.product_video_url
                                    ? "text-red-500"
                                    : ""
                                }
                              >
                                {
                                  values?.addProductDemo[index]
                                    ?.product_video_url?.length
                                }
                              </span>
                              /100
                            </div>
                          </label>
                          <div>
                            <input
                              type="text"
                              name={`addProductDemo[${index}].product_video_url`}
                              {...getFieldProps(
                                `addProductDemo[${index}].product_video_url`
                              )}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              placeholder={`Video URL ${index + 1}`}
                            />
                            {/* {errors.addProductDemo &&
                          touched.addProductDemo &&
                          errors.addProductDemo[index]?.product_video_url && (
                            <div className="text-red-600 text-sm">
                              {errors.addProductDemo[index].product_video_url}
                            </div>
                          )} */}
                          </div>
                        </div>
                      </div>
                      {/* <div className="w-full">
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        Select File
                      </label>
                      <div className="flex justify-start  ">
                        <input
                          type="file"
                          id={`addProductDemo[${index}].product_video_file`}
                          // className="sr-only"
                          placeholder={`feature ${index + 1}`}
                          name={`addProductDemo[${index}].product_video_file`}
                          {...getFieldProps(
                            `addProductDemo[${index}].product_video_file`
                          )}
                          onChange={(event) => {
                            handleVideoFileChangeDemo(
                              event,
                              `addProductDemo[${index}].product_video_file_uploaded`
                            );
                            handleChange(event);
                          }}
                          accept="video/*"
                        />
                        <div className=" text-blue-300">
                          {values.addProductDemo[index]
                            .product_video_file_uploaded ? (
                            <>
                              <a
                                href={`${process.env.REACT_APP_IMG_URL+values.addProductDemo[index].product_video_file_uploaded}`}
                                target="__blank"
                                className="color-red-500"
                              >
                                view
                              </a>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      {errors.addProductDemo &&
                        touched.addProductDemo &&
                        errors.addProductDemo[index]?.product_video_file && (
                          <div className="text-red-600 text-sm">
                            {errors.addProductDemo[index].product_video_file}
                          </div>
                        )}
                    </div> */}

                      <div className=" w-4/6 space-y-2  border-2 p-3 rounded-md">
                        <div className="flex justify-between items-start">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Add Times And Description
                          </label>
                        </div>
                        <div className=" flex  gap-2 justify-between"  >
                        <div className=" w-full flex flex-col gap-y-3 ">
                          {Array.isArray(
                            values.addProductDemo[index].addVideoTimes
                          ) &&
                            values.addProductDemo[index].addVideoTimes.map(
                              (item, ind) => (
                                <div
                                  key={ind}
                                  className=" border-2 p-2 rounded-md"
                                  // className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-6 gap-x-5 gap-y-5  items-end w-full"
                                >
                                  <div className="flex justify-end items-start">
                                    <button
                                      type="button"
                                      className="rounded-md  px-2 py-1 text-sm font-semibold text-black  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                                      onClick={() =>
                                        setFieldValue(
                                          `addProductDemo[${index}].addVideoTimes`,
                                          values.addProductDemo[
                                            index
                                          ].addVideoTimes.filter(
                                            (_, i) => i !== ind
                                          )
                                        )
                                      }
                                    >
                                      <XMarkIcon className="w-5 h-5" />
                                    </button>
                                  </div>
                                  <div>
                                    <div className=" flex gap-5">
                                      <div className=" w-24">
                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                          Minute:
                                        </label>
                                        <select
                                          name={`addProductDemo[${index}].addVideoTimes[${ind}].minute`}
                                          {...getFieldProps(
                                            `addProductDemo[${index}].addVideoTimes[${ind}].minute`
                                          )}
                                          className="block w-full px-2  border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                        >
                                          {/* Generate options for minute */}
                                          {[...Array(60).keys()].map(
                                            (minute) => (
                                              <option
                                                key={minute}
                                                value={
                                                  minute < 10
                                                    ? `0${minute}`
                                                    : `${minute}`
                                                }
                                              >
                                                {minute < 10
                                                  ? `0${minute}`
                                                  : `${minute}`}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                      <div className="w-24 ">
                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                          Second:
                                        </label>
                                        <select
                                          name={`addProductDemo[${index}].addVideoTimes[${ind}].second`}
                                          {...getFieldProps(
                                            `addProductDemo[${index}].addVideoTimes[${ind}].second`
                                          )}
                                          className=" block w-full px-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 "
                                        >
                                          {/* Generate options for second */}
                                          {[...Array(60).keys()].map(
                                            (second) => (
                                              <option
                                                key={second}
                                                value={
                                                  second < 10
                                                    ? `0${second}`
                                                    : `${second}`
                                                }
                                              >
                                                {second < 10
                                                  ? `0${second}`
                                                  : `${second}`}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                      <div className=" sm:w-80 xl:col-span-3">
                                        <label className="flex block text-sm font-medium leading-6 text-gray-900">
                                          Chapters{" "}
                                          {/* {JSON.stringify(values?.addProductDemo)} */}
                                          <div className="ml-auto ">
                                            <span
                                              className={
                                                errors?.addProductDemo &&
                                                Array.isArray(
                                                  errors?.addProductDemo[index]
                                                    ?.addVideoTimes
                                                ) &&
                                                errors?.addProductDemo[index]
                                                  ?.addVideoTimes[ind]
                                                  ?.description
                                                  ? "text-red-500"
                                                  : ""
                                              }
                                            >
                                              {
                                                values?.addProductDemo[index]
                                                  ?.addVideoTimes[ind]
                                                  .description?.length
                                              }
                                            </span>
                                            /100
                                          </div>
                                        </label>
                                        <div>
                                          <input
                                            name={`addProductDemo[${index}].addVideoTimes[${ind}].description`}
                                            {...getFieldProps(
                                              `addProductDemo[${index}].addVideoTimes[${ind}].description`
                                            )}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 h-[41px]"
                                            // placeholder={`feature ${index + 1}`}
                                            placeholder="Chapters"
                                          />
                                          {/* {errors.addProductplans &&
                                      touched.addProductplans &&
                                      errors.addProductplans[index]?.description && (
                                        <div className="text-red-600 text-sm">
                                          {errors.addProductplans[index].description}
                                        </div>
                                      )} */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}

                        </div>
                        
                        <button
                            type="button"
                            className="  px-5 py-1 text-sm font-semibold text-black  border-t-2 sm:border-t-0 pt-4 sm:pt-0 sm:border-l-2 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 whitespace-nowrap"
                            onClick={() => {
                              setFieldValue(
                                `addProductDemo[${index}].addVideoTimes`,
                                [
                                  ...values.addProductDemo[index].addVideoTimes,
                                  {
                                    time: "",
                                    description: "",
                                    minute: "",
                                    second: "",
                                  },
                                ]
                              );
                            }}
                          >
                            {/* + Time */}
                            <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="w-full  sm:w-[10%] flex items-center justify-center border-t-2 sm:border-t-0 pt-4 sm:pt-0 sm:border-l-2 ">
                <button
                  type="button"
                  className="rounded-md px-5 py-2 text-sm font-semibold text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                  onClick={() => {
                    setFieldValue("addProductDemo", [
                      ...values.addProductDemo,
                      {
                        product_video_description: "",
                        product_video_header: "",
                        product_video_url: "",
                        product_video_file: "",
                        product_video_file_uploaded: "",
                        addVideoTimes: [],
                      },
                    ]);
                  }}
                >
                  {/* Add */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className=" flex flex-col gap-y-3 mt-5 ">
            <div className="flex justify-between">
              <h1 className="sm:text-lg font-medium">Product Prices</h1>
            </div>
            <div className="w-full flex flex-col sm:flex-row gap-4  justify-between">
              <div className="w-full sm:w-[90%]">
                {Array.isArray(values.addProductplans) &&
                  values.addProductplans.map((item, index) => (
                    <div
                      key={index}
                      // className="grid sm:grid-cols-1 md:grid-cols-4 gap-x-5 gap-y-5 items-center"
                      className=" py-2"
                    >
                      <div className=" border-2 p-2 rounded-md">
                      <div className="flex justify-end items-end ">
                        <button
                          type="button"
                          className="rounded-md  px-2 py-1 text-sm font-semibold text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                          onClick={() =>
                            setFieldValue(
                              "addProductplans",
                              values.addProductplans.filter(
                                (_, i) => i !== index
                              )
                            )
                          }
                        >
                          <XMarkIcon className="w-5 h-5" />
                        </button>
                      </div>
                      <div className=" sm:flex gap-4">
                        <div className="w-full">
                          <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Name{" "}
                            <div className="ml-auto ">
                              <span
                                className={
                                  Array.isArray(errors?.addProductplans) &&
                                  errors?.addProductplans[index]?.name
                                    ? "text-red-500"
                                    : ""
                                }
                              >
                                {values?.addProductplans[index]?.name?.length}
                              </span>
                              /100
                            </div>
                          </label>
                          <div>
                            <input
                              type="text"
                              name={`addProductplans[${index}].name`}
                              {...getFieldProps(
                                `addProductplans[${index}].name`
                              )}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              // placeholder={`feature ${index + 1}`}
                              placeholder="Name"
                            />
                            {/* {errors.addProductplans &&
                        touched.addProductplans &&
                        errors.addProductplans[index]?.name && (
                          <div className="text-red-600 text-sm">
                            {errors.addProductplans[index].name}
                          </div>
                        )} */}
                          </div>
                        </div>
                        <div className="w-full">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Type
                          </label>
                          <div>
                            <select
                              name={`addProductplans[${index}].type`}
                              {...getFieldProps(
                                `addProductplans[${index}].type`
                              )}
                              defaultValue="Select"
                        placeholder="Select"
                              class="block w-full p-1.5 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            >
                            <option value="">Select</option>
                              <option>Monthly</option>
                              <option>Yearly</option>
                            </select>
                            {/* <input
                        type="text"
                        name={`addProductplans[${index}].type`}
                        {...getFieldProps(
                          `addProductplans[${index}].type`
                        )}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        // placeholder={`feature ${index + 1}`}
                      /> */}
                            {/* {errors.addProductplans &&
                        touched.addProductplans &&
                        errors.addProductplans[index]?.type && (
                          <div className="text-red-600 text-sm">
                            {errors.addProductplans[index].type}
                          </div>
                        )} */}
                          </div>
                        </div>
                        <div className="w-full">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Price
                          </label>
                          <div>
                            <input
                              type="number"
                              name={`addProductplans[${index}].price`}
                              {...getFieldProps(
                                `addProductplans[${index}].price`
                              )}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              // placeholder={`${index + 1}`}
                           placeholder="Price"
                            />
                            {/* {errors.addProductplans &&
                        touched.addProductplans &&
                        errors.addProductplans[index]?.price && (
                          <div className="text-red-600 text-sm">
                            {errors.addProductplans[index].price}
                          </div>
                        )} */}
                          </div>
                        </div>
                        <div className="w-full">
                          <label className="flex block text-sm font-medium leading-6 text-gray-900">
                            Description{" "}
                            <div className="ml-auto ">
                              <span
                                className={
                                  Array.isArray(errors?.addProductplans) &&
                                  errors?.addProductplans[index]?.description
                                    ? "text-red-500"
                                    : ""
                                }
                              >
                                {
                                  values?.addProductplans[index]?.description
                                    ?.length
                                }
                              </span>
                              /100
                            </div>
                          </label>
                          <div>
                            {/* <textarea
                        name={`addProductplans[${index}].description`}
                        {...getFieldProps(
                          `addProductplans[${index}].description`
                        )}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        // placeholder={`feature ${index + 1}`}
                      /> */}
                            <input
                              name={`addProductplans[${index}].description`}
                              {...getFieldProps(
                                `addProductplans[${index}].description`
                              )}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              placeholder="Description"
                              // placeholder={`${index + 1}`}
                            />
                            {/* {errors.addProductplans &&
                        touched.addProductplans &&
                        errors.addProductplans[index]?.description && (
                          <div className="text-red-600 text-sm">
                            {errors.addProductplans[index].description}
                          </div>
                        )} */}
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className=" w-full  sm:w-[10%] flex items-center justify-center border-t-2 sm:border-t-0 pt-4 sm:pt-0 sm:border-l-2">
                <button
                  type="button"
                  className="rounded-md px-5 py-2 text-sm font-semibold text-black  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                  onClick={() => {
                    setFieldValue("addProductplans", [
                      ...values.addProductplans,
                      { name: "", type: "", description: "", price: "" },
                    ]);
                  }}
                >
                  {/* Add */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <hr class="h-px my-6 bg-gray-300 border-0 dark:bg-gray-700"></hr>
          <div className="flex justify-end mt-5">
            <>
              <div className="flex gap-x-2">
                <button
                  type="button"
                  onClick={handlePrevButton}
                  className="rounded-md bg-gradient-to-b from-[#DB7D0B] to-[#872341] px-5 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                >
                  Prev
                </button>
                <button
                  type="submit"
                  className="rounded-md bg-gradient-to-b from-[#DB7D0B] to-[#872341] px-5 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                >
                  Next
                </button>
              </div>
            </>
          </div>
        </div>
      </form>
    </>
  );
};

export default Step3;
