import React, { useEffect, useState, Fragment } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import { Link, useSearchParams } from "react-router-dom";
import { enquiry_columns } from "../../../components/tables/tableheader";
import Table from "../../../components/tables/table";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import MasterServices from "../../../ApiServices/MasterServices";
import { Dialog, Transition } from "@headlessui/react";
import moment from "moment";
import Select from "react-select";
import { exportToExcel } from "../../../helper/commonHelper";

const Enquiry = () => {
  const pages = [{ title: "Enquiry", href: "/enquires" }];
  const [loading, setLoading] = useState(false);
  const [modalOpenFlage, setmodalOpenFlage] = useState({
    isOpen: false,
    data: undefined,
  });

  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });
  const [enquiryData, setEnquiryData] = useState([]);
  console.log(enquiryData, "enquiryData");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [activePositionTab, setActivePositionTab] = useState(0);
  //console.log(activePositionTab);

  let SPNM_ACtive_Position_Tab = 0;

  const getAllProducts = async (payload, page, pageSize) => {
    setLoading(true);

    setAllProduct([]);
    if (pageSize) {
      setPageSizeState(pageSize);
    }
    let myPayload = {
      prod_is_sponsored: SPNM_ACtive_Tab === 2 ? 1 : 2,
      prod_is_normal: SPNM_ACtive_Tab === 1 ? 1 : 2,
      prod_is_all: SPNM_ACtive_Tab === 0 ? 1 : 2,
      prod_is_active: payload,
      page: page ? page : 1,
      pageLimit: !pageSize ? pageSizeState : pageSize,
      prod_position_filter: SPNM_ACtive_Position_Tab,
    };

    const response = await MasterServices.getAllProduct(myPayload);
    //console.log("response", response);
    if (response.data.status == true) {
      if (response.data.data.data.length > 0) {
        setAllProduct(response.data.data.data);
        setAllTempProduct(response.data.data.data);
        setImageBaseURL(response.data.data.image_base_url);
        setProductModal(false);
      }
      if (response?.data?.data?.pagination) {
        setPagination(response.data.data.pagination);
      }

      setLoading(false);
    }
  };

  const statusOptions = [
    { label: "All", value: "All", index: 0 },
    { label: "Assigned", value: "Assigned", index: 1 },
    { label: "Unassigned", value: "Unassigned", index: 2 },
  ];

  const typeOptions = [
    { label: "All", value: "All", index: 0 },
    { label: "Demo Video", value: "demovideo", index: 1 },
    { label: "View Brochure", value: "viewBrochure", index: 2 },
    { label: "Enquire", value: "enquire", index: 3 },
    { label: "Content Gallery", value: "content gallery", index: 4 },
    { label: "Contact", value: "contact", index: 5 },
    { label: "Advertise", value: "Advertise", index: 6 },
  ];

  const filterOptions = [
    { label: "Category", value: "Category" },
    { label: "SubCategory", value: "SubCategory" },
    { label: "Problem", value: "Problem" },
    { label: "Tags", value: "Tags" },
    { label: "Segment", value: "Segment" },
  ];

  const publishmentStatus = [
    { label: "Published", value: "Published", index: 0 },
    { label: "Unpublished", value: "Unpublished", index: 1 },
  ];

  const segmentTypes = [
    { label: "Small Enterprise", value: "small_enterprise", index: 0 },
    { label: "Mid Enterprise", value: "mid_enterprise", index: 1 },
    { label: "Large Enterprise", value: "large_enterprise", index: 2 },
  ];

  const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);
  const [selectedType, setSelectedType] = useState(typeOptions[0]);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedPublishmentStatus, setSelectedPublishmentStatus] =
    useState(null);
  const [productCategory, setProductCategory] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState(null);
  const [subCategoryOptions, setSubCategoryOptions] = useState(null);
  const [problemStatement, setProblemStatement] = useState(null);
  const [productProblemStatement, setProductProblemStatement] = useState(null);
  const [tagValue, setTagValue] = useState(null);
  const [tagData, setTagData] = useState([]);
  const [productSubCategory, setProductSubCategory] = useState(null);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [allproduct, setAllProduct] = useState([]);
  const [pageSizeState, setPageSizeState] = useState(10);
  const [allTempProduct, setAllTempProduct] = useState([]);
  const [imageBaseURL, setImageBaseURL] = useState(null);
  const [pagination, setPagination] = useState([]);
  const [productModal, setProductModal] = useState(false);
  const [filteredEnquiries, setFilteredEnquiries] = useState(null);

  const [filters, setFilters] = useState({
    enq_type: null,
    startDate: null,
    endDate: null,
  });

  //console.log(filteredEnquiries);

  let SPNM_ACtive_Tab = 0;

  const filteredenq = (selectedTypes) => {
    //console.log("Selected Types:", selectedTypes);

    if (selectedTypes.some((type) => type.value === "All")) {
      // Show all values in the table
      setFilteredEnquiries(null); // or setFilteredEnquiries([]);
    } else {
      // Filter data based on selected types
      const filteredEnq = enquiryData.filter((enq) => {
        return selectedTypes.some((type) => enq.enq_type === type.value);
      });

      //console.log("Filtered Enquiries:", filteredEnq);

      setFilteredEnquiries(filteredEnq);
    }
  };
  

  const downloadEnquireDataExcel = () => {
    // const dataToExport = enquiryData;
    console.log("enquiryData",enquiryData)
    const dataToExport = filteredEnquiries || enquiryData;

    const columns = [
      { 
        Header: "Name", 
        accessor: (d)=> d.enq_cu_relation?.user_first_name || "-",
      },
      
     
      {
        Header: "Email",
        accessor: "enq_business_email",
      },
      { 
        Header: "Designation", 
        accessor: (d)=> d.enq_cu_relation?.user_designation || "-", 
      },
      { 
        Header: "Company", 
        accessor: (d)=>d?.m_user_enq_relation?.user_company_name,
          // {
          //   console.log("Companydd",d?.m_user_enq_relation?.user_company_name)
            
          // } 
      },
      { 
        Header: "Role", 
        accessor: (d)=> d.m_user_enq_relation?.m_role_relation?.role_name || "-",
      },
      {
        Header: "Product",
        accessor: (d) => d.m_products_relation?.ser_prod_name || "-", 
      },

      {
        Header: "Source",
        accessor: "enq_type",
      },
      {
        Header: "Date",
        accessor: (d) =>
          moment(d?.enq_created_at).format("DD/MM/YYYY hh:mm:ss A"),
      },
      {
        Header: "Organization",
        accessor: "enq_organisation",
      },
      {
        Header: "Mobile Number",
        accessor: "enq_mobile_no",
      },
      {
        Header: "Size",
        accessor: "enq_organisation_size",
      },
      {
        Header: "Designation",
        accessor: "enq_designation",
      },
    ];

    const headings = [columns.map((col) => col.Header)];
    const transformedData = dataToExport.map((item) => {
      const row = {};
      columns.forEach((col) => {
        const value =
          typeof col.accessor === "function"
            ? col.accessor(item)
            : item[col.accessor];
        row[col.Header] = value || "";
      });
      return row;
    });
    exportToExcel(transformedData, headings, "Enquire.xlsx", "Enquire");
  };

  const getAllEnquiryData = async (payload) => {
    const response = await MasterServices.getAllEnquiry(payload);
    if (
      Array.isArray(response.data?.data?.data) &&
      response.data?.data?.data?.length > 0
    ) {
      setEnquiryData(response?.data?.data?.data);
      setLoading(false);
    } else {
      setEnquiryData([]);
      setLoading(false);
    }
  };

  const handlePositionTabClick = (index) => {
    SPNM_ACtive_Position_Tab = index;
    setActivePositionTab(index);
    getAllProducts(3);
  };

  const capitalise = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const clearForm = () => {
    setFilters({
      enq_type: null,
      startDate: null,
      endDate: null,
    });

    setStartDate("");
    setEndDate("");
    setSelectedType(typeOptions[0]);

    // getAllEnquiryData(),
  };

  const onModalOpen = (data) => {
    setmodalOpenFlage({
      isOpen: true,
      data: data,
    });
  };

  useEffect(() => {
    getAllEnquiryData();
  }, []);

  useEffect(() => {
    let filteredData = enquiryData;

    if (filters.startDate || filters.endDate) {
      // Apply date range filter
      const startDateValue = filters.startDate
        ? new Date(filters.startDate + "T00:00:00")
        : new Date("1000-01-01T00:00:00");
      const endDateValue = filters.endDate
        ? new Date(filters.endDate + "T23:59:59")
        : new Date();

      filteredData = filteredData.filter((item) => {
        const itemDate = new Date(item.enq_created_at);
        return itemDate >= startDateValue && itemDate <= endDateValue;
      });
    }

    if (filters.enq_type && filters.enq_type.length > 0) {
      //console.log("Selected Types:", filters.enq_type);

      if (filters.enq_type.some((type) => type.value === "All")) {
        // Show all values in the table
        setFilteredEnquiries(filteredData);
      } else {
        // Filter data based on selected types
        const filteredEnqByType = filteredData.filter((enq) =>
          filters.enq_type.some((type) => enq.enq_type === type.value)
        );

        //console.log("Filtered Enquiries by Type:", filteredEnqByType);
        setFilteredEnquiries(filteredEnqByType);
      }
    } else {
      // No enquiry type selected, show filtered data based on date range
      setFilteredEnquiries(filteredData);
    }
  }, [filters, enquiryData]);

  const handleDrawer = () => {};
  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        {/* <ConfirmationModal
          open={confirmationModal.status}
          setOpen={setConfirmationModal}
          onDelete={() => onDeleteRole(confirmationModal.role_id)}
        /> */}
        <Breadcrumb pages={pages} />
        <div className="">
          <h1 className="text-xl font-semibold text-gray-900">Enquiry</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the Enquires.
          </p>
        </div>

        <div className=" w-full shadow-sm rounded-md h-auto border-2 p-4">
          <div className=" w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div>
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Source
              </label>
              <div>
                <Select
                  isMulti={true}
                  className="basic-single sm:text-sm"
                  classNamePrefix="select"
                  defaultValue={typeOptions[0]}
                  placeholder="Select"
                  name="type"
                  options={typeOptions}
                  value={selectedType}
                  onChange={(selectedOption) => {
                    //console.log("Selected Type:", selectedOption); // Add this line for debugging
                    setSelectedType(typeOptions[selectedOption]);
                    setActivePositionTab(selectedOption.index);
                    filteredenq(selectedOption);
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      enq_type: selectedOption ? selectedOption : null,
                    }));
                  }}
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Start Date
              </label>
              <div className="flex">
                <input
                  type="date"
                  name="date"
                  value={startDate}
                  // onChange={(e) => setStartDate(e.target.value)}

                  onChange={(e) => {
                    const startDate = e.target.value;
                    setStartDate(startDate);
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      startDate: startDate,
                    }));
                  }}
                  className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium leading-6 text-gray-900">
                End Date
              </label>
              <div className="flex">
                <input
                  type="date"
                  name="date"
                  value={endDate}
                  // onChange={(e) => setEndDate(e.target.value)}

                  onChange={(e) => {
                    const endDate = e.target.value;
                    setEndDate(endDate);
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      endDate: endDate,
                    }));
                  }}
                  className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
          <div className=" pt-4 ">
            <button
              onClick={clearForm}
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#fd6e1d] to-[#ef6122] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
            >
              Clear
            </button>
          </div>
        </div>
        <div className=" w-full mt-4 flex justify-end">
          <button
            type="button"
            onClick={downloadEnquireDataExcel}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#fd6e1d] to-[#ef6122] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
          >
            Download Excel
          </button>
        </div>

        {loading ? (
          <FallingLinesLoader />
        ) : (
          <Table
            columns={enquiry_columns({ onModalOpen })}
            data={filteredEnquiries ? filteredEnquiries : enquiryData}
          />
        )}

        <div>
          <Transition.Root show={modalOpenFlage.isOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              onClose={() => {
                setmodalOpenFlage({
                  isOpen: false,
                  data: undefined,
                });
              }}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-[700px]">
                      <div className="flex h-full flex-col divide-gray-200 bg-white shadow-xl pt-12">
                        <div className="h-0 flex-1 overflow-y-auto pl-[12.5rem] text-left">
                          Name:{" "}
                          {(modalOpenFlage?.data?.m_user_enq_relation
                            ?.user_first_name || "") +
                            " " +
                            (modalOpenFlage?.data?.m_user_enq_relation
                              ?.user_last_name || "") || "N/A"}
                          {
                            (modalOpenFlage?.data?.enq_name || "")
                          }
                        </div>

                        <div className="h-0 flex-1 overflow-y-auto pl-[12.5rem] text-left">
                          Email: {modalOpenFlage?.data?.enq_business_email}
                        </div>
                        <div className="h-0 flex-1 overflow-y-auto pl-[12.5rem] text-left">
                          Mobile Number: {modalOpenFlage?.data?.enq_mobile_no}
                        </div>

                        <div className="h-0 flex-1 overflow-y-auto pl-[12.5rem] text-left">
                          Designation: {modalOpenFlage.data?.enq_designation}
                        </div>

                        <div className="h-0 flex-1 overflow-y-auto pl-[12.5rem] text-left">
                          Organisation: {modalOpenFlage.data?.enq_organisation}
                        </div>
                        { modalOpenFlage.data?.enq_organisation_size && (
                            <div className="h-0 flex-1 overflow-y-auto pl-[12.5rem] text-left">
                              Organisation Size:{" "}
                              {modalOpenFlage.data?.enq_organisation_size}
                            </div>
                          )
                        }
                        { modalOpenFlage?.data?.m_products_relation
                              ?.ser_prod_name && (

                        <div className="h-0 flex-1 overflow-y-auto pl-[12.5rem] text-left">
                          Product:{" "}
                          {
                            modalOpenFlage?.data?.m_products_relation
                              ?.ser_prod_name
                          }
                        </div>
                        )}
                        <div className="h-0 flex-1 overflow-y-auto pl-[12.5rem] text-left">
                          Source:{" "}
                          {modalOpenFlage.data?.enq_type
                            ? capitalise(modalOpenFlage.data.enq_type)
                            : ""}
                        </div>

                        <div className="h-0 flex-1 overflow-y-auto pl-[12.5rem] text-left">
                          Date:{" "}
                          {moment(modalOpenFlage.data?.enq_created_at).format(
                            "DD/MM/YYYY hh:mm:ss A"
                          )}
                        </div>

                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage({
                                isOpen: false,
                                data: undefined,
                              });
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      </div>
    </>
  );
};

export default Enquiry;
