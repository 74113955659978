/* eslint-disable */
import { toast } from "react-toast";
import AuthServices from "../../ApiServices/AuthServices";
import {
  LOGIN_BEGIN,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT_BEGIN,
  LOGOUT_SUCCESS,
  GET_ALL_USERS_BEGIN,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_ERROR,
  ADD_USER_BEGIN,
  ADD_USER_SUCCESS,
  ADD_USER_ERROR,
  GET_USER_BEGIN,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  UPDATE_USER_BEGIN,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
} from "./constants";


export const getHeaders = () => (dispatch, getState) => {
  const {
    auth: { user },
  } = getState();
  return {
    //Authorization: `Bearer ${user.token}`,
    Authorization: `Bearer `
  };
};

// ** Handle User Login
export const handleLogin = (body) => async (dispatch) => {
  try {
    dispatch({
      type: LOGIN_BEGIN,
    });
    
    const { data } = await AuthServices.Login(body);
    localStorage.setItem("user", JSON.stringify(data.data.user));
    localStorage.setItem("token", data.data.token);
    
    
    

    if(!data.status){
      toast.error(data.message);
    }else{
      dispatch({
        type: LOGIN_SUCCESS,
        data: data.data,
      });
    }

  } catch (error) {
    const msg = "Failed to login cathc error" //error.response.data.message;
    toast.error(msg);
    dispatch({
      type: LOGIN_ERROR,
      error: msg,
    });
  }
};

// ** Handle User Logout
export const handleLogout = () => async (dispatch) => {
  
  try {
    dispatch({
      type: LOGOUT_BEGIN,
    });
    
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("favourites");
    localStorage.removeItem("module");
    localStorage.removeItem("role_id");
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_permissions");
    // localStorage.clear();
    dispatch({
      type: LOGOUT_SUCCESS,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: LOGOUT_ERROR,
      error: msg,
    });
  }
};

/* export const getAllUsers = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_USERS_BEGIN,
    });
    const headers = getHeaders();
    const { data } = await Api().get(`/api/auth`, { headers });
    

    dispatch({
      type: GET_ALL_USERS_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: GET_ALL_USERS_ERROR,
      error: msg,
    });
  }
};

export const addUser = (body) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_USER_BEGIN,
    });
    const headers = getHeaders();
    const { data } = await Api().post(`/api/auth/register`, body, {
      headers,
    });
    
    toast.success("User added successfully!");
    dispatch({
      type: ADD_USER_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: ADD_USER_ERROR,
      error: msg,
    });
  }
};
 */
export const getUserWithId = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_BEGIN,
    });
    const headers = getHeaders();
    const { data } = await Api().get(`/api/auth/${id}`, { headers });
    
    dispatch({
      type: GET_USER_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: GET_USER_ERROR,
      error: msg,
    });
  }
};

export const updateUser = (id, body) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_USER_BEGIN,
    });
    const headers = getHeaders();
    const { data } = await Api().put(`/api/auth/${id}`, body, {
      headers,
    });
    
    toast.success("User updated successfully!");
    dispatch({
      type: UPDATE_USER_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: UPDATE_USER_ERROR,
      error: msg,
    });
  }
};
